import { BookingOptionsStyle } from "./style";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { primaryColor2 } from "../GlobalStyle";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import {
  authorizationToken,
  change_booking_status_url,
} from "../../utils/api_urls";
import { toast } from "react-toastify";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useBookingContext } from "../../context/BookingContext";
import LoadingOverlay from "../Modal/LoadingOverlay";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import { useGlobalContext } from "../../context/context";

type bookingOptionsProps = {
  setShowBookingModal: Dispatch<SetStateAction<boolean>>;
};
const ApproveOptions: React.FC<bookingOptionsProps> = ({
  setShowBookingModal,
}) => {
  const { bookingId } = useBookingContext();
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDeletedModal, setShowDeletedModal] = useState(false);
  const navigate = useNavigate();
  const { setCounter } = useGlobalContext();

  // delete post promise
  const handleCancelBooking = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        change_booking_status_url,
        {
          bookingId,
          requestStatusId: 6,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setShowDeletedModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setCounter((prev) => prev + 1);
        setIsModalVisible(false);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  return (
    <BookingOptionsStyle>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Cancel Booking"
          description="Booking Cancelled Successfully"
          doTask={() => {
            setCounter((prev) => prev + 1);
            setIsModalVisible(false);
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={showDeletedModal}
        setIsModalVisible={setShowDeletedModal}
        showCloseBtn={false}
      >
        {isLoading && <LoadingOverlay message="Deleting.." />}
        <EnnvisionWarningModal
          setIsModalVisible={setShowDeletedModal}
          isLoading={isLoading}
          title="Cancel Booking"
          description="Are You Sure To Cancel Your Booking?"
          doTask={handleCancelBooking}
        />
      </CustomModal>
      <ul className="mb-0 ps-0">
        <li
          className="title"
          style={{ color: primaryColor2, fontWeight: "500" }}
          onClick={() => {
            setShowBookingModal(false);
            navigate("/booking-reports");
          }}
        >
          Cancel
        </li>
      </ul>
    </BookingOptionsStyle>
  );
};

export default ApproveOptions;
