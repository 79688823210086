import { HeroSectionStyle } from "./style";
import Navbar from "../../components/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import search_icon from "../../assets/icons/ic_search-1.svg";
import search_icon2 from "../../assets/icons/ic_search.svg";
import FormikController from "../../components/FormControl";
import { Formik } from "formik";
import { Dropdown, Form } from "antd";
import { useSearchContext } from "../../context/SearchContext";
import PropertyTypeFilters from "../../components/Filters/PropertyTypeFilters";
import AutoCompleteCity from "../../maps/AutoCompleteCity";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavbarContext } from "../../context/NavbarContext";
import introVideo from "../../assets/intro.mp4";
import AuthNavbar from "../../components/Navbar/AuthNavbar";
import banner from "../../assets/images/ic_image_1.png";
import { debounce } from "lodash";
import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
} from "react";
import { FindHomeFilter } from "../FindHome/style";
import dropdown_icon from "../../assets/icons/ic_drop_down.svg";
import useScreenTranslation from "../../hooks/useScreenTranslation";
// initial values
type initialValuesTypes = {
  keyWord: string;
};

// proeprty type item
export interface NavItemEventKey {
  id: number;
  keyName: string;
}

const HeroSection = () => {
  const { loading } = useNavbarContext();
  const {
    showPropertyTypes,
    setShowPropertyTypes,
    propertyTypeNumber,
    selectedSubTypes,
    setFilterCriteria,
    propertyType,
    purpose,
    setPurpose,
    address,
    setAddress,
    getFilterPropertiesPromise,
    propertiesPage,
    setPropertiesPage,
    setSearchCriteria,
    searchCriteria,
  } = useSearchContext();
  // ***********Adding debounce***********
  const [value, setValue] = useState("");

  const sendBackendRequest = useCallback((value: string) => {
    // setAddress(value);
    if (pathname === "/") {
      navigate("/find-home");
    }
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);
  // **********************
  const navigate = useNavigate();
  const propertyData = useAppSelector(
    (state) => state.appData.data.propertyAppData[propertyTypeNumber]
  );
  const { pathname } = useLocation();
  const propertySubTypes = propertyData?.propertySubTypesDTOS;
  const purposeHandler = () => {
    setPurpose(purpose === "Rent" ? "Buy" : "Rent");
  };

  const initialValues: initialValuesTypes = {
    keyWord: "",
  };
  useLayoutEffect(() => {
    setPropertiesPage(0);
    setSearchCriteria([]);
  }, []);
  const {getLabelByKey}=useScreenTranslation('home')
  const parts = getLabelByKey('bannerTitle').split(',');
  console.log("parts",parts[1],getLabelByKey('bannerTitle'))
  return (
    <>
      {/* <Container>
        <AuthNavbar />
      </Container> */}
      <HeroSectionStyle>
        {/* <video muted autoPlay src={"introVideo"} loop /> */}
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {(formik) => {
            console.log(formik.values, "formik values");
            return (
              <Form
                name="basic"
                autoComplete="off"
                onFinish={formik.handleSubmit}
              >
                <div
                  className="inner-container d-flex align-items-center flex-column justify-content-center"
                  style={{
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {loading ? (
                    <Skeleton height={20} count={2} width="300px" />
                  ) : (
                    <h4 className="inner-container-main-heading">
                       
                      <span> {parts[0]}</span>
                      <span className="lang d-block text-center">
                        {/* <span className="lang">Only with{"  "}</span> */}
                        <span className="nestify-up lang">{parts[1]} </span>
                      </span>
                    </h4>
                  )}

                  <div className="purchase-btns d-flex justify-content-between gap-3  w-100">
                    <button
                      className={`purchase-btns-buy w-50 ${
                        purpose === "Rent" ? "" : "active"
                      }`}
                      type="button"
                      onClick={purposeHandler}
                    >
                      BUY
                    </button>
                    <button
                      className={`purchase-btns-rent w-50 ${
                        purpose === "Rent" ? "active" : ""
                      }`}
                      type="button"
                      onClick={purposeHandler}
                    >
                      RENT
                    </button>
                  </div>
                  <div className="position-relative w-100 mx-2 mt-3">
                    <Row className="location-select-section w-100 mx-auto p-3 align-items-center">
                      <Col sm={6} className="location text-left text-sm-start">
                        <h5 className="title">Property Type</h5>
                        {/* <AutoCompleteCity
                          placeholder="City Area etc"
                          name="location"
                          onCityChange={(city) => setAddress(city)}
                        /> */}
                        <Dropdown
                          open={showPropertyTypes}
                          className="me-sm-2"
                          onOpenChange={(open) => setShowPropertyTypes(open)}
                          dropdownRender={() => <PropertyTypeFilters />}
                          trigger={["click"]}
                        >
                          <FindHomeFilter>
                            {propertyType}
                            <span>
                              <img src={dropdown_icon} alt="dropdown" />
                            </span>
                          </FindHomeFilter>
                        </Dropdown>
                      </Col>
                      <Col
                        sm={6}
                        className="property-type d-flex justify-content-sm-between justify-content-center align-items-center mt-2 mt-sm-0"
                      >
                        <div className="left-section ps-md-4 w-100 mt-3 mt-sm-0">
                          <h5 className="title">Location</h5>
                          {/* <Dropdown
                            open={showPropertyTypes}
                            onOpenChange={(open) => setShowPropertyTypes(open)}
                            dropdownRender={() => <PropertyTypeFilters />}
                            trigger={["click"]}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <FormikController
                                control="inputByFocus"
                                type="text"
                                name="professionalTypeId"
                                border="1px solid #EFEFF4"
                                placeholder="Profession"
                                value={propertyType}
                                className="profession"
                                showErrorMessage={false}
                              />
                            </a>
                          </Dropdown> */}
                          <div
                            style={{
                              border: "1px solid rgb(217, 217, 217)",
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                              padding: "5px",
                            }}
                          >
                            <img
                              style={{
                                marginRight: "10px",
                                width: "20px",
                                display:
                                  window.innerWidth >= 575 ? "none" : "inline",
                              }}
                              src={search_icon2}
                              alt="search"
                            />
                            <input
                              type="text"
                              name="location"
                              onChange={(e) => {
                                const city = e.target.value;
                                // setValue(city);
                                setAddress(city);
                                // debouncedSendRequest(value);
                              }}
                              placeholder="City Area etc"
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="search-box ms-2 d-sm-flex justify-content-center align-items-center d-none"
                          onClick={() => {
                            // setting purpose
                            setFilterCriteria("purpose", purpose);
                            // setting address
                            setFilterCriteria("address", address);
                            // setting property type
                            const selectedIds = selectedSubTypes
                              .map(({ id }) => id)
                              .join(",");
                            // when no subtype is selected by user
                            const zeroSelectedIds = propertySubTypes
                              .map(({ id }) => id)
                              .join(",");
                            setFilterCriteria(
                              "propertyType",
                              propertyTypeNumber + 1
                            );
                            // setting subtypes search filter
                            setFilterCriteria(
                              "propertySubType",
                              selectedSubTypes.length < 1
                                ? zeroSelectedIds
                                : selectedIds
                            );
                            setShowPropertyTypes(false);
                            debouncedSendRequest(value);
                          }}
                        >
                          <img src={search_icon} alt="search" />
                        </button>
                      </Col>
                      <Col className="property-type d-flex justify-content-sm-between justify-content-center align-items-center mt-sm-0 d-sm-none">
                        {" "}
                        <button
                          type="submit"
                          className="search-box ms-sm-2 d-flex justify-content-center align-items-center w-100"
                          onClick={() => {
                            // setting purpose
                            setFilterCriteria("purpose", purpose);
                            // setting address
                            setFilterCriteria("address", address);
                            // setting property type
                            const selectedIds = selectedSubTypes
                              .map(({ id }) => id)
                              .join(",");
                            // when no subtype is selected by user
                            const zeroSelectedIds = propertySubTypes
                              .map(({ id }) => id)
                              .join(",");
                            setFilterCriteria(
                              "propertyType",
                              propertyTypeNumber + 1
                            );
                            // setting subtypes search filter
                            setFilterCriteria(
                              "propertySubType",
                              selectedSubTypes.length < 1
                                ? zeroSelectedIds
                                : selectedIds
                            );
                            setShowPropertyTypes(false);
                            debouncedSendRequest(value);
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginRight: 5,
                            }}
                          >
                            Search
                          </span>
                          <img src={search_icon} alt="search" width={"20px"} />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </HeroSectionStyle>
    </>
  );
};

export default HeroSection;
