import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import placeholder from "../../assets/icons/ic_use_placeholder.svg";
import { Dropdown, Select } from "antd";
import { useAppSelector } from "../../app/hooks";
import store,{ RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { media_base_url } from "../../utils/api_urls";
import createIcon from "../../assets/icons/ic_create.svg";
import CreateOptions from "../PostOptions/CreateOptions";
import UpgradeAccountMenu from "../DropDownMenu/UpgradeAccountMenu";
import BecomeProfesionalMenu from "../DropDownMenu/BecomeProfessionalMenu";
import { getUseDetails } from "../../redux/features/user/userDetailsSlice";
import { mediaDeviceMax, primaryColor2 } from "../GlobalStyle";
import CustomButton from "../CustomButton/CustomButton";
import { useGlobalContext } from "../../context/context";
import ViewProfileTourGuide from "../TourGuide/ViewProfile";
import user from "../../assets/icons/userIcon.png";
import spainflag from "../../assets/icons/icons8-spain-flag-48.png";
import ukflag from "../../assets/icons/icons8-uk-flag-48 (1).png";
import useScreenTranslation from "../../hooks/useScreenTranslation";
import { useDispatch } from "react-redux";
import { setLanguage } from '../../redux/features/user/selectedLanguageSlice'
import { CountryCode } from 'libphonenumber-js';
type NavMenuProps = {
  changeNavColor: boolean;
};


const data = [
  { id:'1',value: "en", label: `English`,flag:ukflag },
  {id:'2', value: "es", label: "Spanish",flag:spainflag },]

 

  // Define the list of country codes you want to include
  const countries: CountryCode[] = ['GB', 'ES'];
const NavMenu: React.FC<NavMenuProps> = ({ changeNavColor }) => {
  const navigate = useNavigate();
  const {
    setHomeUnAuthAddProperty,
    selectedTourGuide,
    userTourGuideData,
    professionalTourGuideData,
  } = useGlobalContext();
  const loginUser = useAppSelector((state: RootState) => state.loginData.data);
  const isProfessional = loginUser?.ProfessionalDetails;
  const [isShowProOptions, setIsShowProOptions] = useState(false);
  const [isShowUpgradeOptions, setIsShowUpgradeOptions] = useState(false);
  const [showCreateOptions, setShowCreateOptions] = useState(false);
  const [value, setValue] = useState();
  const { getLabelByKey } = useScreenTranslation("navMenu");
  const dispatch=useDispatch()
  const [selectedLanguage, setSelectedLanguage] = useState(data[0]);

  const handleChange=(e:any)=>{
    const selectedValue = e.target.value;
    const selectedOption = data.find((option) => option.value === selectedValue);
    selectedOption && setSelectedLanguage(selectedOption);
    dispatch(setLanguage(selectedOption?.value))
  }
  console.log("media ic",loginUser)

  useEffect(()=>{
 loginUser && store.dispatch(getUseDetails(loginUser.userDetails.id))
  },[])
  const  userDetails  = useAppSelector((state: RootState) => state.userDetails.result.profilePicture);
  console.log("pic",userDetails)
  return (
    // show menu profile if user is logged in or not
    <NavMenuStyle>
      {loginUser ? (
        <div className="d-flex align-items-center position-relative">
          {loginUser.ProfessionalDetails ? (
            <Dropdown
              open={
                // isProfessional &&
                // professionalTourGuideData[selectedTourGuide].isOpen &&
                // selectedTourGuide > 0
                //   ? true
                //   :
                showCreateOptions
              }
              className="me-2"
              onOpenChange={(open) => setShowCreateOptions(open)}
              dropdownRender={() => (
                <CreateOptions setShowCreateOptions={setShowCreateOptions} />
              )}
              trigger={["click"]}
            >
              <div className="mb-4 me-5">
                <img
                  src={createIcon}
                  className="position-absolute cursor-pointer mb-4 me-5 plus-icon"
                  alt="plus"
                />
              </div>
            </Dropdown>
          ) : (
            <div className="me-4 position-relative">
              <CustomButton
                bgcolor={primaryColor2}
                color="white"
                padding="4px"
                border="none"
                width="100%"
                textTransform="Capitalize"
                type="submit"
                title="Add Property"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="11px"
                clicked={() => {
                  navigate("/create-property");
                }}
              />
              <div className="position-absolute">
                {!isProfessional && selectedTourGuide === 2 && (
                  <ViewProfileTourGuide
                    title={userTourGuideData[selectedTourGuide].title}
                    sub_title={userTourGuideData[selectedTourGuide].description}
                  />
                )}
              </div>
            </div>
          )}
          <Link
            to="/user-profile"
            className="text-decoration-none d-none d-sm-block"
          >
            <h6
              className={`cursor-pointer me-2 mb-0 user-name ${
                changeNavColor ? "text-dark" : "text-light"
              }`}
            >
              {loginUser?.userDetails?.username}
            </h6>
          </Link>
          <div className="position-relative">
            <Dropdown
              open={
                // loginUser?.ProfessionalDetails
                //   ? selectedTourGuide < 1
                //     ? true
                //     : isShowUpgradeOptions
                //   : !isProfessional && selectedTourGuide < 1
                //   ? true
                //   :
                loginUser?.ProfessionalDetails
                  ? isShowUpgradeOptions
                  : isShowProOptions
              }
              onOpenChange={(open) => {
                // loginUser.ProfessionalDetails
                //   ? setIsShowUpgradeOptions(open)
                //   :
                loginUser.ProfessionalDetails
                  ? setIsShowUpgradeOptions(open)
                  : setIsShowProOptions(open);
              }}
              dropdownRender={() =>
                loginUser?.ProfessionalDetails ? (
                  <UpgradeAccountMenu
                    setIsShowUpgradeOptions={setIsShowUpgradeOptions}
                  />
                ) : (
                  <BecomeProfesionalMenu
                    setIsShowProOptions={setIsShowProOptions}
                  />
                )
              }
              trigger={["click"]}
              className="position-relative"
            >
               
              {loginUser.userDetails.profileImageURL || userDetails ? (
                <div>
                  <img
                    className="cursor-pointer profile-icon rounded-circle"
                    src={ userDetails ?  media_base_url + userDetails : media_base_url + loginUser.userDetails.profileImageURL }
                    alt="profile"
                  />
                </div>
              ) : (
                <img
                  className="cursor-pointer profile-icon"
                  src={placeholder}
                  alt="profile"
                />
              )}
            </Dropdown>
          </div>
        </div>
      ) : (
        <div className="d-flex auth-text align-items-center">
          <div className="image auth-text">
          <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="4px"
            border="none"
            width=""
            textTransform="Capitalize"
            type="submit"
            title="Add Property"
            margin="auto 10px auto auto"
            fontFamily="EnnVisionsMedium"
            fontSize="11px"
            clicked={() => {
              setHomeUnAuthAddProperty(true);
              navigate("/login");
            }}
          />
          </div>
        
          <img  className="image" src={user} alt="user"  height={20} width={20} onClick={()=>navigate('/login')}/>
          <Link to="/login" className="text-decoration-none">
            <p
              className={`auth-text ${
                changeNavColor ? "text-dark" : "text-dark"
              }`}
              style={{ marginRight: "20px" }}
              
            >
              {getLabelByKey("loginText")}
              Log in
            </p>
          </Link>
          <img className="image" src={selectedLanguage.flag} style={{height:"25px"}} alt={selectedLanguage.label} />
          <select
      
            className={`select-box auth-text  ${
              changeNavColor ? "text-dark" : "text-dark"
            }`}
            // style={{ marginLeft: "-5px" }}
            onChange={handleChange}
           value={selectedLanguage.value}
            
          >
            
            {data.map((option) => (
              <>
              <option  key={option.id} value={option.value}

               >
               <div className="lang">{option.label}</div> 
             
              </option>
              </>
              
            ))}
          
          </select>
        </div>
      )}
    </NavMenuStyle>
  );
};

export default NavMenu;

const NavMenuStyle = styled.div`

.custom-select {
  //padding: 16px 4px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  background-color: #fff; /* Background color */
  color: #333; /* Text color */
  cursor: pointer; /* Cursor style */
}
.PhoneInputInput {
  display: none;

}

@media screen and (max-width: 768px) {
  .lang {
    display: none; /* Hide the select on small screens */
    margin-right: 5px;
  }
  .image{
    margin-right: 5px;
  }
  .nav{
    flex-wrap: nowrap;
  }
}
  @media ${mediaDeviceMax.mobileL} {
    
    .lang{
      display: none;
    
    }
    .image{
      margin-right: 5px;
    }
    .nav{
      flex-wrap: nowrap;
    }
  }
  @media ${mediaDeviceMax.mobileBS} {
    
    .lang{
      display: none;
     
    }}
  @media ${mediaDeviceMax.mobileS} {
    .auth-text {
      font-size: 11px;
    }
    .lang{
      display: none;
     
    }
    .image{
      margin-right: 5px;
    }
    .nav{
      flex-wrap: nowrap;
    }
  }
  @media ${mediaDeviceMax.mobileXL} {
    .auth-text {
      font-size: 11px;
    }
    .lang{
      display: none;
     
    }
    .image{
      margin-right: 5px;
    }  .nav{
      flex-wrap: nowrap;
    }
  }
    @media ${mediaDeviceMax.mobileM} {
      .auth-text {
        font-size: 11px;
      }
      .lang{
        display: none;
       
      }
      .image{
        margin-right: 5px;
        
      }
      .nav{
        flex-wrap: nowrap;
      }
  }
  @media (max-width: 768px) {
    .select-box {
      width: 30px; /* Adjust the width as needed */
      background: url('src\assets\images\down-arrow.png') no-repeat right center;
      text-indent: -9999px; /* Hide the text */
      margin-left:-15px;
      // -moz-appearance: none;
      // -webkit-appearance: none;
      // appearance: none;
    }
  
    .select-box option {
      display: block; /* Show the options */
      text-indent: 0; /* Ensure the options text is visible */
    }
  }
`;
