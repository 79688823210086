import { Link } from "react-router-dom";
import { FooterHomeStyle } from "./style";
import useScreenTranslation from "../../hooks/useScreenTranslation";

function FooterHome() {
  const year = new Date().getFullYear();
  const {getLabelByKey}=useScreenTranslation('homeFooter')
  return (
    <FooterHomeStyle className="footer w-100">
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-sm-center">
        <p className="mb-0 text-center text-md-start">
          {getLabelByKey('copyRight')}
         
        </p>
        <div
          className={`d-flex justify-content-center justify-content-md-end
          } align-items-center`}
        >
          <Link to="/terms" target="_blank">
          {getLabelByKey('TermsAndConditions')}
         
          </Link>
          <Link to="/privacy" target="_blank" className="ms-4">
          {getLabelByKey('privacyPolicy')}
          
          </Link>
        </div>
      </div>
    </FooterHomeStyle>
  );
}

export default FooterHome;
