import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  primaryColor2,
  tertiaryGrey21,
  whiteColor,
} from "../../components/GlobalStyle";

const FindHomeStyle = styled.div`

  // padding-top: 20px;
  // padding-bottom: 50px;
  // margin-top:10px;
  // margin-bottom:50px;
  .ant-select-selector {
    height: 36px !important;
    width: 96% !important;
    font-family: ${fontFamilyRegular};
  }
  .multiple-marker-parent {
    // height: 440px;
    z-index:1;
    height: 84vh;
    .property-drawer {
      right: 0;
      top: 0;
      height: 400px;
      
    }
  }
  .properties {
    margin-top: 20px;
  }
  > .container:first-child{
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    top: 80px;
    z-index: 9;
    background-color: #fff;
    padding: 10px 0px;
    margin: auto;
    max-width: 1600px;
    :before{
      content: "";
      height: 37px;
      display: block;
      position: fixed;
      left: 0px;
      right: 0px;
      width: 100%;
      top: 81px;
      background-color: red;
      padding: 28px 0;
    }
  }
  > .container{
    margin-top: 40px;
    @media screen and (max-width: 485px){
      margin-top: 100px;
    }

  }
  .alice-carousel__stage-item{
    // width:12.5% !important;
  }
`;

export default FindHomeStyle;

export const PropertyDrawerStyle = styled.div`
  .ant-drawer-body {
    padding: 0px !important;
  }
  .ant-drawer-mask {
    background: transparent;
  }
  .ant-drawer-title {
    font-family: ${fontFamilyMedium};
  }
  .ant-drawer-content-wrapper {
    width: 370px !important;
  }

  .ant-drawer-content-wrapper {
    width: 370px !important;
  }
  .ant-drawer-content {
    background-color: transparent;
  }

  .ant-drawer-body {
    margin-top: 6px;
  }
`;

export const FindHomeFilter = styled.div`
  border: 1px solid ${tertiaryGrey21};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  .title {
    font-family: ${fontFamilyMedium};
  }
  img {
    height: 8px;
  }
  
  

  .filter-container{
    &-card {
      margin-top:100px
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 6px;
      width: 100%;
      margin: auto;
      padding: 20px 12px;
      &-form {
        width: 96%;
      }
  }
  
`;

export const SearchCityStyle = styled.div`
/* Define the default styles for the filter items */
.filter-item {
  margin-bottom: 10px;
  width: 100%;
}

/* Make sure the button takes the full width and is centered */
.search-button {
  display: block;
  margin: 10px auto;
  width: 100%;
}

/* Layout for large screens */
.home-filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

/* Media query for mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .home-filter-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

  input {
    border: 1px solid rgb(217, 217, 217);
    padding: 9px;
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
  }
  // @media screen and (max-width: 320px) {
  //   width: 100%;
  // }
`;

export const PriceRangeStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  input {
    border: 1px solid ${tertiaryGrey21};
    border-radius: 4px;
    padding: 4px;
    @media screen and (max-width: 991px){
      width: 90%;
    }
  }
  .to-text {
    font-family: ${fontFamilyMedium};
  }
`;
export const AreaFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  input {
    border: 1px solid ${tertiaryGrey21};
    border-radius: 4px;
    padding: 4px;
    @media screen and (max-width: 991px){
      width: 90%;
    }
  }
  .to-text {
    font-family: ${fontFamilyMedium};
  }
`;

export const BathroomFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  button {
    border-radius: 10px;
    border: 1px solid ${primaryColor2};
    padding: 4px;
    font-family: ${fontFamilyMedium};
  }
`;

export const BedroomFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  button {
    border-radius: 10px;
    padding: 4px;
    font-family: ${fontFamilyMedium};
  }
`;
// auto complete address

export const AutoCompleteSuggestionsStyle = styled.div`
  label {
    font-family: ${fontFamilyMedium};
  }
  input {
    padding: 9px;
    border: 1px solid #d9d9d9;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
  }
  .autocomplete-suggestions {
    background-color: red; // Set the background color of the suggestions dropdown
    border-radius: 4px; // Set the border radius of the suggestions dropdown
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); // Set the box shadow of the suggestions dropdown
    font-size: 14px; // Set the font size of the suggestions dropdown
    width: 100%; // Set the width
    font-family: ${fontFamilyMedium};
  }
  .autocomplete-item {
    /* Apply your desired styling here */
    background-color: red;
    padding: 8px;
    cursor: pointer;
    /* Add any additional CSS styles to customize the appearance of autocomplete suggestions */
  }
`;



export const FilterWrapper = styled.div`
//position: fixed !important;
left: 0;
right: 0;
padding: 10px 0px;

//z-index: 9;
 top: 90px;
  // background-color: red;
// margin-top: 30px;
// width:90%;
margin-top:20px;
    margin-bottom: -80px;
    // margin-left: 75px;
    padding: 50px 0px;
    // margin-right: 75px;
  div {
    ul {
      @media screen and (max-width: 991px){
        .hide-on-small{
display:none;
        }
        margin-bottom: 20px;
        display: inline-block;
        width: 100%;
      }
      
li{
  width:12.50% !important;
  float: left;

  /* Hide the button on large screens */
@media (min-width: 768px) {
  .hide-on-large {
    display: none;
  }
  .hide-on-small {
    display: flex; /* Or flex, inline-block, etc., depending on your layout needs */
    
  }
}

/* Optionally, you can add styles to ensure the button is visible on smaller screens */
@media (max-width: 995px) {
  .hide-on-large {
    display: flex; /* Or any other display property you prefer */
  }
  .hide-on-small {
    display: none;
  }
}

  @media screen and (max-width: 991px){
    width:50% !important;
    margin-bottom:
    .hide-on-small{
      display :none;
    }
  }
  @media screen and (max-width: 600px) {
    .carousel-wrapper{
      max-width: 100%;
    }
    .hide-on-small{
      display :none;
    }
  }
    }
  }
`;