import styled from "styled-components";
import { fontFamilyMedium, primaryColor2, pureDark } from "../GlobalStyle";

const PropertyTypeFiltersStyle = styled.div`
  max-width: 720px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  background: white;
  z-index: 20;
  .nav-link {
    color: ${pureDark};
    font-size: 16px;
  }

  .types {
    padding: 16px;

    .nav-link {
      color: #717272;
      font-size: 15px;
      border: 1px solid #909090;
      border-radius: 4px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 19px;
    }
    .nav-pills .nav-link.active {
      color: ${primaryColor2};
      background: #e2f8ef73;
      border: 1px solid ${primaryColor2};
      border-radius: 4px;
      font-family: ${fontFamilyMedium};
    }
    .nav-pills {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 9px;
      row-gap: 10px;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

export default PropertyTypeFiltersStyle;
