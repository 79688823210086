import styled from "styled-components";
import { tertiaryGrey8 } from "../../components/GlobalStyle";

const HomepageStyle = styled.div`
  .inner-container {
    padding-top: 50px;
    background-color: ${tertiaryGrey8};
  }
`;

export default HomepageStyle;
