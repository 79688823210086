import logo from "../../assets/icons/ic_logo.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Input, Rate } from "antd";
import { primaryColor2 } from "../../components/GlobalStyle";
import usePostEvent from "../../hooks/usePostEvent";
import { useState } from "react";
import { feedback_url } from "../../utils/api_urls";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReportAndFeedbackStyle from "./style";

const { TextArea } = Input;

const OnlineSupport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  const { loading, data, error, apiDataPromise } = usePostEvent();
  // handle submit
  const handleSubmit = async () => {
    apiDataPromise(feedback_url, {
      message,
      rating,
    })
      .then(() => {
        setIsModalVisible(true);
        setTimeout(() => {
          setIsModalVisible(true);
          navigate("/explore-nearby");
        }, 2000);
      })
      .catch(() => toast(error));
  };

  // do task when user deleted
  const doTask = () => {
    setIsModalVisible(false);
    navigate("/explore-nearby");
  };
  return (
    <ReportAndFeedbackStyle>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Feedback & Ratting"
          description="Our support team assist you with your questions"
          doTask={doTask}
        />
      </CustomModal>
      <img className="logo" src={logo} alt="logo" />
      <div className="online-support-section mt-3">
        <h6 className="title text-center">Feedback & Ratting</h6>
        <p className="description text-center">
          Our support team is available 24/7 to assist you with any questions or
          issues. Please feel free to contact us anytime for prompt assistance.
        </p>
        <label htmlFor="messages" className="online-support-section-label">
          * Comments
        </label>
        <TextArea
          disabled={loading}
          className="mt-2"
          id="messages"
          rows={4}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="d-flex justify-content-center">
          <Rate
            value={rating}
            style={{ color: primaryColor2, height: "60px", fontSize: "40px" }}
            defaultValue={0}
            onChange={(value) => setRating(value)}
          />
        </div>
        <div className="d-flex justify-content-center my-2">
          <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="8px 8px"
            width="260px"
            type="submit"
            disabled={!message || !rating}
            loading={loading}
            textTransform="Captilize"
            title="Submit"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={handleSubmit}
          />
        </div>
        {error && <p className="text-danger">{error}</p>}
      </div>
    </ReportAndFeedbackStyle>
  );
};

export default OnlineSupport;
