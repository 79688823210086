import Wrapper from "./style";
import React, { useState } from "react";
import { primaryRed } from "../GlobalStyle";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import { useAppSelector } from "../../app/hooks";
import CustomModal from "../Modal/CustomModal";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import axios from "axios";
import { authorizationToken, property_delete_url } from "../../utils/api_urls";
import EnnvisionModal from "../CustomModals/EnnvisionModal";

const PropertyOptions = () => {
  const navigate = useNavigate();
  const { selectedPropertyId } = useGlobalContext();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const { setShowPropertyOptions, setCounter } = useGlobalContext();
  const [error, setError] = useState("");

  // delete property promise
  const deletePropertyPromise = async () => {
    try {
      setIsDeleting(true);
      const { data } = await axios.delete(property_delete_url, {
        data: {
          propertyId: selectedPropertyId,
        },
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsDeleting(false);
      setIsDeleteModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setCounter((prev) => prev + 1);
        setIsModalVisible(false);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsDeleting(false);
      setIsDeleteModal(true);
      alert(error.response.data.responseMessage);
    }
  };

  const PropertyOptions = [
    {
      title: "View",
      doTask: () => {
        navigate(`/properties/${selectedPropertyId}`, {
          state: { isEdit: true },
        });
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Edit",
      doTask: () => {
        navigate(`/edit-property/${selectedPropertyId}`);
        setShowPropertyOptions(false);
      },
    },
  ];

  // do task when property deletes
  const doTask = () => {
    setCounter((prev) => prev + 1);
    setIsModalVisible(false);
  };

  return (
    <>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isDeleting}
          title="Delete Property"
          description="Are You Sure You Want To Delete Your Property? This Action Is Irreversible And All Your Data And Information Associated With The Property Will Be Permanently Deleted."
          doTask={() => {
            setShowPropertyOptions(false);
            deletePropertyPromise();
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Property Deleted"
          description="Property Delete Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Wrapper>
        <ul>
          {PropertyOptions.map(({ title, doTask }, index) => {
            return (
              <React.Fragment key={index}>
                <li onClick={doTask}>{title}</li>
              </React.Fragment>
            );
          })}
          <li
            className="title"
            style={{ color: primaryRed, fontWeight: "500" }}
            onClick={() => {
              setShowPropertyOptions(false);
              setIsDeleteModal(true);
            }}
          >
            Delete
          </li>
        </ul>
      </Wrapper>
    </>
  );
};

export default PropertyOptions;
