import {
  BackgroundImage,
  SecondaryHeading,
  TertiraryHeadingMini,
} from "../../components/GlobalStyle";
import { FindPropertiesStyle } from "./style";
import city_1 from "../../assets/images/vivir-en-Malaga_02.jpeg";
import city_2 from "../../assets/images/cadiz.jpeg";
import city_3 from "../../assets/images/Sevilla.jpeg";
import city_4 from "../../assets/images/Madrid.png";
import city_5 from "../../assets/images/Ibiza.png";
import city_6 from "../../assets/images/Valencia.jpeg";
import { Container } from "react-bootstrap";
import RedBlackUnderline from "../../components/RedBlackUnderline/RedBlackUnderline";
import axios from "axios";
import { search_by_city_url } from "../../utils/api_urls";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "../../context/SearchContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useScreenTranslation from "../../hooks/useScreenTranslation";

type propertyData = {
  img: string;
  imgClass: string;
  title: string;
};
const FindProperties = () => {
  const [selectedCity, setSelectedCity] = useState("");
  const { propertiesData, setPropertiesData } = useSearchContext();
  const navigate = useNavigate();
  const { getLabelByKey } = useScreenTranslation('home')

  // api data promise
  const getCityPropertiesPromise = async () => {
    try {
      const { data } = await axios.post(search_by_city_url, {
        city: selectedCity,
      });
      setPropertiesData({
        ...propertiesData,
        properties: data.results.properties,
      });
      navigate("/find-home");
      console.log({ data });
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      navigate("/find-home");

      console.log(error.response.data, "error in api data");
    }
  };

  const propertyData: propertyData[] = [
    {
      img: city_1,
      imgClass: "img1",
      title: getLabelByKey('malaga'),
    },
    {
      img: city_2,
      imgClass: "img2",
      title:getLabelByKey('cadiz'),
    },
    {
      img: city_3,
      imgClass: "img3",
      title:getLabelByKey('sevilla') ,
    },
    {
      img: city_4,
      imgClass: "img4",
      title:getLabelByKey('madrid')  ,
    },
    {
      img: city_5,
      imgClass: "img5",
      title:getLabelByKey('ibiza') ,
    },
    {
      img: city_6,
      imgClass: "img6",
      title:getLabelByKey('valencia') ,
    },
  ];
  useEffect(() => {
    if (selectedCity) {
      getCityPropertiesPromise();
    }
  }, [selectedCity]);

  return (
    <FindPropertiesStyle>
      <SecondaryHeading className="text-center">
        {getLabelByKey('featuredCities')}
       
      </SecondaryHeading>
      {/* <TertiraryHeadingMini className="text-center">
        For sale by owner real estate listings across Spain
      </TertiraryHeadingMini> */}
      <div className="d-flex justify-content-center">
        <RedBlackUnderline />
      </div>
      <Container>
        <div className="areas">
          {propertyData.map(
            ({ img, imgClass, title }: propertyData, index: number) => (
              <BackgroundImage
                key={index}
                onClick={() => setSelectedCity(title)}
                className={`${imgClass} area-container`}
                repeat="no-repeat"
                url={img}
                size="cover"
                width="100%"
                postion="center"
              >
                <h6 className="text">{title}</h6>
              </BackgroundImage>
            )
          )}
        </div>
      </Container>
    </FindPropertiesStyle>
  );
};

export default FindProperties;
