export const UPDATE_PROFFESIONAL_ROUTE = "update-professional";
export const UPDATE_PROFFESIONAL = "/update-professional";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "register";
export const GENERATE_OTP_ROUTE = "generate-otp";
export const RESET_PASSWORD_ROUTE = "reset-password";
export const CREATE_CREDIT_CARD_ROUTE = "add-credit-card";
export const CREDIT_CARDS_ROUTE = "credit-cards";
export const EDIT_PHONE_ROUTE = "edit-phone";
export const FIND_HOME_ROUTE = "find-home";
export const PROPERTY_DETAILS_ROUTE = "properties";
export const VIEW_ALL_PROPERTIES_ROUTE = "properties-by-status";
export const BECOME_PROFESSIONAL_ROUTE = "become-professional";
export const USER_PROFILE_ROUTE = "property-listing";
export const EDIT_USER_ROUTE = "/edit-user";
export const PROFESSIONAL_PLANS_ROUTE = "professional-plans";
export const EXPLORE_NEARBY_ROUTE = "explore-nearby";
export const USER_ROUTE = "user";
export const HOME_ROUTE = "home";
export const CREATE_POST_ROUTE = "create-post";
export const PROPERTY_INFO = "property-info";
export const RESOURCES = "RESOURCES";
export const BILL_INFO = "RESOURCES";
