import CustomButton from "../CustomButton/CustomButton";
import {
  fontFamilyMedium,
  pureDark2,
  mediaDeviceMax,
  primaryColor2,
  whiteColor,
  pureDark,
} from "../GlobalStyle";
import styled from "styled-components";
import { useGlobalContext } from "../../context/context";
import { useAppSelector } from "../../app/hooks";

type viewProfileTourGuideProps = {
  title: string;
  sub_title: string;
  tips?: string[];
};

const ViewProfileTourGuide: React.FC<viewProfileTourGuideProps> = ({
  title,
  sub_title,
  tips,
}) => {
  const {
    setShowAuthSidebar,
    selectedTourGuide,
    setSelectedTourGuide,
    userTourGuideData,
    professionalTourGuideData,
    setProfessionalTourGuideData,
    setUserTourGuideData,
  } = useGlobalContext();

  const loginData = useAppSelector((state) => state.loginData.data);

  const isProfessional = loginData?.ProfessionalDetails;
  const toursLength = isProfessional
    ? professionalTourGuideData.length
    : userTourGuideData.length;

  console.log({ selectedTourGuide });
  //   to move to the item
  const moveNext = () => {
    const oldtourGuideIds = isProfessional
      ? [
          ...professionalTourGuideData.map((tourGuide) => ({
            ...tourGuide,
            isOpen: false,
          })),
        ]
      : [
          ...userTourGuideData.map((tourGuide) => ({
            ...tourGuide,
            isOpen: false,
          })),
        ];
    const newtourGuideIds = oldtourGuideIds.map((tourGuide, index) => {
      if (index === selectedTourGuide + 1) {
        return { ...tourGuide, isOpen: true };
      } else {
        return { ...tourGuide, isOpen: false };
      }
    });
    isProfessional
      ? setProfessionalTourGuideData(newtourGuideIds)
      : setUserTourGuideData(newtourGuideIds);

    if (selectedTourGuide === 0 || selectedTourGuide === 2) {
      setShowAuthSidebar(true);
    }
    setSelectedTourGuide((prev) => prev + 1);
  };

  // end tour
  const endTour = () => {
    const oldtourGuideIds = isProfessional
      ? [
          ...professionalTourGuideData.map((tourGuide) => ({
            ...tourGuide,
            isOpen: false,
          })),
        ]
      : [
          ...userTourGuideData.map((tourGuide) => ({
            ...tourGuide,
            isOpen: false,
          })),
        ];
    isProfessional
      ? setProfessionalTourGuideData(oldtourGuideIds)
      : setUserTourGuideData(oldtourGuideIds);
    setShowAuthSidebar(false);
  };

  return (
    <section>
      <Wrapper>
        <h3>{title}</h3>
        <h5>{sub_title}</h5>
        {tips && (
          <ul>
            {tips?.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        )}
        <div className="d-flex gap-2 justify-content-between">
          <p>
            {selectedTourGuide + 1} of {toursLength}
          </p>
          {selectedTourGuide < 1 && (
            <CustomButton
              bgcolor={whiteColor}
              color={pureDark}
              padding="4px"
              border="none"
              width="50%"
              textTransform="Capitalize"
              type="button"
              title="Start"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="14px"
              clicked={moveNext}
            />
          )}
          {selectedTourGuide > 0 && selectedTourGuide < toursLength - 1 && (
            <CustomButton
              bgcolor={whiteColor}
              color={pureDark}
              padding="4px"
              border="none"
              width="60%"
              textTransform="Capitalize"
              type="button"
              title="Next"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="14px"
              clicked={moveNext}
            />
          )}

          {selectedTourGuide === toursLength - 1 && (
            <CustomButton
              bgcolor={whiteColor}
              color={pureDark}
              padding="4px"
              border="none"
              width="60%"
              textTransform="Capitalize"
              type="button"
              title="Finish"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="14px"
              clicked={endTour}
            />
          )}
        </div>
      </Wrapper>
    </section>
  );
};

export default ViewProfileTourGuide;

const Wrapper = styled.div`
  color: ${whiteColor};
  background-color: ${pureDark2};
  padding: 15px;
  border-radius: 7px;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin-top: 10px;
  width: 330px;
  @media ${mediaDeviceMax.tablet} {
    transform: translateX(-67%);
  }
  @media ${mediaDeviceMax.tablet} {
    width: 280px;
    transform: translateX(-80%);
  }
  /* Arrow-like styling */
  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 56%;
    transform: translateX(-50%) rotate(180deg); /* Add rotate(180deg) */

    @media ${mediaDeviceMax.desktop} {
      transform: translateX(100%) rotate(180deg);
      right: 42%;
      left: auto;
    }

    @media ${mediaDeviceMax.tablet} {
      transform: translateX(100%) rotate(180deg);
      right: 14%;
      left: auto;
    }
    border-width: 8px;
    border-style: solid;
    border-color: ${pureDark2} transparent transparent transparent;
  }

  /* Adjust the position of the arrow */
  &::before {
    bottom: 100%; /* Adjust this value as needed */
    margin-bottom: -1px; /* Add this line */
  }

  h3 {
    font-size: 16px;
    font-family: ${fontFamilyMedium};
    color: inherit;
  }
  h5,
  li {
    font-size: 14px;
    color: inherit;
  }
  p {
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: 6px;
    color: white;
  }
`;
