import placeholder from "../../../assets/icons/ic_use_placeholder.svg";
import soldPropertyIcon from "../../../assets/icons/ic_professional_post.svg";
import person from "../../../assets/icons/ic_professional_meet_peron.svg";
import { Col, Row } from "react-bootstrap";

import styled from "styled-components";
import {
  lightGrey5,
  lightblue,
  primaryColor2,
  pureDark,
  secondaryDark4,
  tertiaryGrey7,
  tertiaryGrey8,
  whiteColor,
} from "../../../components/GlobalStyle";
import { media_base_url } from "../../../utils/api_urls";
import { professionalDetailTypes } from "../../../redux/features/types";
import { userProfileTypes } from "../types";

type UserProfileBoxProps = {
  userData: userProfileTypes;
  professionalData: professionalDetailTypes;
};

const ProfileDetailsBox: React.FC<UserProfileBoxProps> = ({ userData }) => {
  const {
    firstName,
    lastName,
    userName,
    totalProperties,
    totalSoldProperties,
    countryCode,

    phoneNumber,
    profilePictureURL,
    totalFollowers,
    totalFollowing,
  } = userData;
  return (
    <Wrapper>
      <div className="owner-details">
        <Row>
          <Col md={6} lg={8}>
            <div className="d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
              <label className="label">
                <span>
                  <img
                    src={
                      profilePictureURL
                        ? media_base_url + profilePictureURL
                        : placeholder
                    }
                    alt={firstName}
                    className="profile-img"
                  />
                </span>
              </label>

              <div className="bio ms-3 mt-2 mt-sm-0">
                <h6>
                  {firstName} {lastName}
                </h6>
                <p className="branch mt-2 mb-2">{userName}</p>
                <p className="location mb-0">
                  {countryCode}
                  {phoneNumber}
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4} className="mt-3 mt-md-0">
            <div className="sold-properties">
              <Row>
                <Col md={6} className="mt-md-0 p-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowers}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Followers
                      </p>
                    </div>
                  </div>
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="buildings"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalProperties}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Available
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="p-0 mt-2 mt-md-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowing}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Following
                    </p>
                  </div>

                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="building"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalSoldProperties}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Sold
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default ProfileDetailsBox;

const Wrapper = styled.div`
  border-radius: 6px;
  padding-top: 16px;
  max-width: 1040px;
  margin: 0 auto;

  /* upload profile image */
  label.label input[type="file"] {
    position: absolute;
    top: -1000px;
  }
  .label {
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    display: inline-block;
  }
  /* .label:hover {
    background: #5cbd95;
  }
  .label:active {
    background: #9fa1a0;
  } */
  .label:invalid + span {
    color: #000000;
  }
  .label:valid + span {
    color: #ffffff;
  }

  .owner-details {
    border: 1px solid ${lightGrey5};
    border-radius: 5px;
    padding: 14px;
    background: ${whiteColor};

    .profile-img {
      height: 100px;
      border-radius: 50%;
      width: 100px;
    }

    .bio {
      h6 {
        font-size: 18px;
        font-family: "EnnVisionsMedium";
        color: ${pureDark};
      }
      .branch {
        color: ${tertiaryGrey7};
        font-size: 16px;
      }
      .location {
        color: ${secondaryDark4};
        font-size: 14px;
      }
    }
    .sold-properties {
      border: 1px solid ${tertiaryGrey8};
      border-radius: 5px;

      .properties {
        background: ${tertiaryGrey8};
        border: 1px solid #f5f5f5;
        border-radius: 5px;

        .quantity {
          font-size: 18px;
          font-family: "EnnVisionsMedium";
        }
      }
      padding: 15px;
      .title {
        color: ${tertiaryGrey7};
        font-size: 15px;
      }
      .chat-btn {
        background: ${lightblue};
        border-radius: 5px;
        font-size: 15px;
        padding: 4px;
        color: ${whiteColor};
        width: 90%;
      }
      .call-btn {
        background: ${primaryColor2};
        border-radius: 5px;
        font-size: 15px;
        padding: 4px;
        color: ${whiteColor};
        width: 90%;
      }

      @media screen and (min-width: 576px) {
        margin-top: -10px;
        button {
          width: 100%;
        }
      }
      @media screen and (max-width: 576px) {
        margin-top: 10px;
        button {
          width: 50%;
        }
      }
    }
  }
`;
