import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  fontFamilyMedium,
  tertiaryGrey21,
  whiteColor,
} from "../../components/GlobalStyle";

import { Dropdown } from "antd";
import { FilterWrapper, FindHomeFilter, SearchCityStyle } from "./style";
import dropdown_icon from "../../assets/icons/ic_drop_down.svg";
import AliceCarousel from "react-alice-carousel";
import { Select } from "antd";
import { Container } from "react-bootstrap";
import PropertyTypeFilters from "../../components/Filters/PropertyTypeFilters";
import PriceRangeFilter from "./PriceRange";
import AreaFilters from "./AreaFilter";
import BedroomFilters from "./BedroomFilter";
import BathroomFilters from "./BathroomFilter";
import MoreFilters from "./MoreFilter";
import { useSearchContext } from "../../context/SearchContext";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor2 } from "../../components/GlobalStyle";
import AutoCompleteCity from "./AutoCompleteCity";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

const MoreFilter = () => {
  const {
    showPropertyTypes,
    setShowPropertyTypes,
    showPriceRange,
    setShowPriceRange,
    showBathrooms,
    setShowBathrooms,
    showBedrooms,
    setShowBedrooms,
    showAreas,
    setShowAreas,
    showMoreFilters,
    setShowMoreFilters,
    propertyType,
    setFilterCriteria,
    setSearchCriteria,
    setPurpose,
    purpose,
    address,
    setAddress,
    setSelectedSubTypes,
    setPriceMinRange,
    setPriceMaxRange,
    setAreaMinRange,
    setAreaMaxRange,
    bathroomItems,
    setBedroomItems,
    bedroomItems,
    setBathroomItems,
    setActive,
    getFilterPropertiesPromise,
  } = useSearchContext();

  // ***********Adding debounce***********
  const [value, setValue] = useState("");

  const sendBackendRequest = useCallback((value: string) => {
    setAddress(value);
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);
  // **********************

  const responsive = {
    0: { items: 1 },
    320: { items: 2 },
    468: { items: 3 },
    768: { items: 4 },
    1024: { items: 6 },
    1200: { items: 7 },
    1366: { items: 8 },
  };

  // on city change
  const cityChangeHandler = () => {
    setFilterCriteria("address", address);
  };

  // get properties when city changes
  useEffect(() => {
    const getDataDebouce = setTimeout(() => {
      cityChangeHandler();
    }, 200);

    return () => {
      clearTimeout(getDataDebouce);
    };
  }, [address]);

  const homeFilterItems =
    propertyType === "Plots"
      ? [
        //   <SearchCityStyle className="me-2">
        //     <AutoCompleteCity
        //       placeholder="City Area etc"
        //       name="location"
        //       onCityChange={(city) => {
        //         setAddress(city);
        //         setFilterCriteria("address", city);
        //       }}
        //     />
        //   </SearchCityStyle>,
          <Select
            className="w-100 me-2"
            defaultValue="Sale"
            onSelect={() => setFilterCriteria("purpose", purpose)}
            options={[
              { value: "Sale", label: "Sale" },
              { value: "Rent", label: "Rent" },
            ]}
            onChange={(value) => setPurpose(value)}
            value={purpose}
          />,
          <Dropdown
          open={showPropertyTypes}
          className="me-2"
          onOpenChange={(open) => setShowPropertyTypes(open)}
          dropdownRender={() => <PropertyTypeFilters />}
          trigger={["click"]}
          
        >
          <FindHomeFilter>
            {propertyType}
            <span>
              <img src={dropdown_icon} alt="dropdown" />
            </span>
          </FindHomeFilter>
        </Dropdown>,
          

          <Dropdown
            open={showPropertyTypes}
            className="me-2"
            onOpenChange={(open) => setShowPropertyTypes(open)}
            dropdownRender={() => <PropertyTypeFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              {propertyType}
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showPriceRange}
            className="me-2"
            onOpenChange={(open) => setShowPriceRange(open)}
            dropdownRender={() => <PriceRangeFilter />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Price Range
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showAreas}
            className="me-2"
            onOpenChange={(open) => setShowAreas(open)}
            dropdownRender={() => <AreaFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Area
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <CustomButton
            bgcolor={whiteColor}
            color={primaryColor2}
            padding="8px 8px"
            width="98%"
            type="submit"
            title="Search"
            border={`1px solid ${primaryColor2}`}
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              setSearchCriteria([]);
              getFilterPropertiesPromise();
            }}
          />,
        ]
      : [
        //   <SearchCityStyle className="me-2">
        //     <input
        //       type="text"
        //       name="location"
        //       onChange={(e) => {
        //         const city = e.target.value;
        //         setValue(city);
        //         debouncedSendRequest(value);
        //       }}
        //       placeholder="City Area etc"
        //     />
        //     {/* <AutoCompleteCity
        //       placeholder="City Area etc"
        //       name="location"
        //       onCityChange={(city) => {
        //         setAddress(city);
        //         setFilterCriteria("address", city);
        //       }}
        //     /> */}
        //   </SearchCityStyle>,
          <Select
          style={{width:"98%"}}
            className="me-2"
            defaultValue="Sale"
            onSelect={() =>
              setFilterCriteria("purpose", purpose === "Sale" ? "Rent" : "Sale")
            }
            options={[
              { value: "Sale", label: "Sale" },
              { value: "Rent", label: "Rent" },
            ]}
            onChange={(value) => setPurpose(value)}
            value={purpose}
          />,
          <Dropdown
            open={showPropertyTypes}
            className="me-2"
            onOpenChange={(open) => setShowPropertyTypes(open)}
            dropdownRender={() => <PropertyTypeFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              {propertyType}
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showPriceRange}
            className="me-2"
            onOpenChange={(open) => setShowPriceRange(open)}
            dropdownRender={() => <PriceRangeFilter />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Price Range
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showAreas}
            className="me-2"
            onOpenChange={(open) => setShowAreas(open)}
            dropdownRender={() => <AreaFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Area
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showBedrooms}
            className="me-2"
            onOpenChange={(open) => setShowBedrooms(open)}
            dropdownRender={() => <BedroomFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Bedrooms
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <Dropdown
            open={showBathrooms}
            className="me-2"
            onOpenChange={(open) => setShowBathrooms(open)}
            dropdownRender={() => <BathroomFilters />}
            trigger={["click"]}
          >
            <FindHomeFilter>
              Bathrooms
              <span>
                <img src={dropdown_icon} alt="dropdown" />
              </span>
            </FindHomeFilter>
          </Dropdown>,
          <CustomButton
            bgcolor={whiteColor}
            color={primaryColor2}
            padding="8px 8px"
            width="98%"
            type="submit"
            title="SEARCH"
            border={`1px solid ${primaryColor2}`}
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              setPurpose("Rent");
              setActive(false);
              setSelectedSubTypes([]);
              setPriceMinRange(0);
              setPriceMaxRange(0);
              setAreaMinRange(0);
              setAreaMaxRange(0);
              setBedroomItems(
                bedroomItems.map((item) => ({
                  ...item,
                  isSelect: false,
                }))
              );
              setBathroomItems(
                bathroomItems.map((item) => ({
                  ...item,
                  isSelect: false,
                }))
              );
              setSearchCriteria([]);
              getFilterPropertiesPromise();
            }}
          />,
          // <Dropdown
          //   open={showMoreFilters}
          //   className="me-2"
          //   onOpenChange={(open) => setShowMoreFilters(open)}
          //   dropdownRender={MoreFilters}
          //   trigger={["click"]}
          // >
          //   <FindHomeFilter>
          //     More Filters
          //     <span>
          //       <img src={dropdown_icon} alt="dropdown" />
          //     </span>
          //   </FindHomeFilter>
          // </Dropdown>,
        ];

  return (
    <Wrapper>
       <div className="filter-container">
      {homeFilterItems.map((item, index) => (
        <div key={index} className="filter-item">
          {item}
        </div>
      ))}
    </div>
</Wrapper>
  );
};


export default MoreFilter;

const Wrapper = styled.section`
  padding: 15px;
  border-radius: 10px;
  background-color: ${whiteColor};
  padding: 20px 10px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;
  width: 460px;
  .heading {
    font-family: ${fontFamilyMedium};
    margin-bottom: 0;
  }
  .features {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    .feature {
      border-radius: 10px;
    }
  }

  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;
