import styled from "styled-components";
import {
  fontFamilyMedium,
  lightColor1,
  mediaDeviceMax,
  pureDark,
  tertiaryGrey9,
} from "../../../components/GlobalStyle";

const CreatePropertyStyle = styled.div`
  background-color: ${tertiaryGrey9};
  @media screen and (max-width: 768px) {
    height: 100%;
  }

  .row {
    padding-top: 26px;
   
    .left-side {
      &-inner-section {
        border: 1px solid ${lightColor1};
        border-radius: 6px;
        @media ${mediaDeviceMax.mobileL} {
          padding: 0px;
        }
      }
      .title {
        color: ${pureDark};
      }

      .text-start {
        font-size: 14px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
        margin: 10px 0;
      }
    }
  }
`;

export default CreatePropertyStyle;

export const AutoCompleteMarksStyle = styled.div`
  label {
    font-family: ${fontFamilyMedium};
  }
  input {
    padding: 8px;
    border: 1px solid #efeff4;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
  }
  .autocomplete-suggestions {
    border-radius: 4px; // Set the border radius of the suggestions dropdown
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); // Set the box shadow of the suggestions dropdown
    font-size: 14px; // Set the font size of the suggestions dropdown
    width: 100%; // Set the width
    font-family: ${fontFamilyMedium};
  }
  .autocomplete-item {
    /* Apply your desired styling here */
    background-color: red;
    padding: 8px;
    cursor: pointer;
    /* Add any additional CSS styles to customize the appearance of autocomplete suggestions */
  }
`;
