import styled from "styled-components";
import { fontFamilyMedium, primaryColor2 } from "../components/GlobalStyle";

const ReactImagesCropStyle = styled.div`
  .next-btn-section {
    position: absolute;
    z-index: 20;
    right: 4%;
    margin: 10px 0;
  }
  label {
    cursor: pointer;
    color: white;
    padding: 8px;
    width: 100%;
    font-family: ${fontFamilyMedium};
    background-color: ${primaryColor2};
    border-radius: 7px;
    width: 200px;
    font-size: 18px;
  }

  #upload-photos {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .App {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    height: 100vh;
    position: relative;
  }
  .upload-images-section {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      height: 500px;
      width: 500px;
      max-height: 700px;
      background-color: white;
      border-radius: 7px;
    }
  }

  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .controls {
    position: absolute;
    bottom: 8%;
    background: white;
    padding: 10px;
    border-radius: 7px;
    width: 50%;
    left: 50%;
    transform: translate(-50%);
  }
`;

export default ReactImagesCropStyle;

export const AsceptRatioStyle = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  padding: 6px;
  width: 140px;
  p {
    font-size: 18px;
    font-family: ${fontFamilyMedium};
    text-align: center;
    color: white;
    cursor: pointer;
  }
`;

export const ZoomStyle = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 7px;
  padding: 6px;
  width: 140px;
`;
