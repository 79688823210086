import axios from "axios";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { authorizationToken, delete_user_url } from "../../utils/api_urls";
import CustomButton from "../CustomButton/CustomButton";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import { primaryColor2 } from "../GlobalStyle";
import CustomModal from "../Modal/CustomModal";
import BecomeProfesionalMenuStyle from "./style";
import useLogout from "../../hooks/useLogout";
import { useGlobalContext } from "../../context/context";
// import ViewProfileTourGuide from "../TourGuide/ViewProfile";

type becomeProfesionalMenuProps = {
  setIsShowProOptions: React.Dispatch<React.SetStateAction<boolean>>;
};

const BecomeProfesionalMenu: React.FC<becomeProfesionalMenuProps> = ({
  setIsShowProOptions,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const logout = useLogout();

  const [isDeleting, setIsDeleting] = useState(false);
  const deleteUserToastId = useRef<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { selectedTourGuide, userTourGuideData } = useGlobalContext();
  // delete data
  const loginData = useAppSelector((state) => state.loginData.data);
  const options = [
    {
      title: "Become a Prossfessional",
      link: "/become-professional",
      taskTodo: () => {
        setIsShowProOptions(false);
      },
    },
    {
      title: "Edit Profile",
      link: "/edit-user",
      taskTodo: () => {
        setIsShowProOptions(false);
      },
    },
    {
      title: "Delete",
      link: "",
      taskTodo: () => {
        setIsDeleteModal(true);
        setIsShowProOptions(false);
      },
    },
  ];

  // delete user promise
  const deleteUserPromise = async () => {
    try {
      setIsDeleting(true);
      const { data } = await axios.post(
        delete_user_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsDeleteModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        logout();
        setIsModalVisible(false);
      }, 2000);
      setIsDeleting(false);
      deleteUserToastId.current = toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsDeleting(false);
      deleteUserToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // do task when user deleted
  const doTask = () => {
    logout();
    setIsModalVisible(false);
  };

  return (
    <BecomeProfesionalMenuStyle>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isDeleting}
          title="Delete User"
          description="Are You Sure You Want To Delete Your Account? This Action Is Irreversible And All Your Data And Information Associated With The Account Will Be Permanently Deleted."
          doTask={deleteUserPromise}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="User Deleted"
          description="User Account Delete Successfully"
          doTask={doTask}
        />
      </CustomModal>

      <div className="options">
        {options.map(({ title, link, taskTodo }, index) => (
          <div className="option" key={index}>
            <Link onClick={taskTodo} to={link} className="link mb-0">
              {title}
            </Link>
            {/* {index === selectedTourGuide &&
              index < 1 &&
              userTourGuideData.length > 0 &&
              userTourGuideData[selectedTourGuide].isOpen &&
              selectedTourGuide < 2 && (
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 10,
                  }}
                >
                  <ViewProfileTourGuide
                    title={userTourGuideData[selectedTourGuide].title}
                    sub_title={userTourGuideData[selectedTourGuide].description}
                  />
                </div>
              )} */}
          </div>
        ))}
        <div className="option">
          <CustomButton
            bgcolor="transparent"
            color={primaryColor2}
            padding="0px"
            width="auto"
            type="submit"
            title="Signout"
            fontSize="16px"
            fontFamily="EnnVisionsMedium"
            border="none"
            clicked={logout}
          />
        </div>
      </div>
    </BecomeProfesionalMenuStyle>
  );
};

export default BecomeProfesionalMenu;
