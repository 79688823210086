import TermsStyle from "./style";
import logo from "../../assets/icons/ic_logo.svg";
import { Link } from "react-router-dom";

type termProps = {
  title: string;
};
const Terms: React.FC<termProps> = ({ title }) => {
  return (
    <TermsStyle>
      <blockquote>
        <h4>{title}</h4>
        <h6> Acceptance and Amendments </h6>
        <p>
          Welcome to the <strong> TechFista™ </strong>Website and mobile device
          applications (collectively, the "Website") operated by House Up Inc.
          (TechFista™)1. The Website present and future information, products
          and services (collectively, the "Products and Services") offered by
          TechFista™ through web pages and mobile device applications that
          display the
          <strong> TechFista™ </strong>navigation bar or identification. These
          Website and Mobile Device Applications Use Terms and Conditions
          contain legal obligations to which you will be bound upon using the
          Website2. Please read them carefully. Your access or use of this
          Website is expressly conditioned upon your acceptance of these Website
          and Mobile Device Applications Use Terms and Conditions
          andTechFista™’s Privacy Policy
          <strong></strong> and any access or use of any portion of the Website
          orTechFista™’s Privacy Policy constitutes your agreement to be bound
          by the Use Terms and Conditions and the <strong> TechFista™ </strong>
          Privacy Policy. If you do not agree with these Website and Mobile.
          Device Applications Use Terms and Conditions or the{" "}
          <strong> TechFista™ </strong>Privacy Policy in their entirety, do not
          access or use any portion of this Website.
        </p>
        <p>
          By accessing and using the Website, you agree to contract
          electronically withTechFista™. You agree that these electronic Website
          and Mobile Device Applications Use Terms and Conditions, combined with
          your act of using the information and services offered on the Website,
          have the same force and effect as your written signature and satisfy
          any laws that require a writing or signature, including any applicable
          Statute of Frauds. You further agree that you will not challenge the
          validity, enforceability, or admissibility of these Website and Mobile
          Device Applications Use Terms and Conditions on the basis that it was
          electronically transmitted or authorized. You further acknowledge that
          you have had the opportunity to print these Website and Mobile Device
          Applications Use Terms and Conditions and the agreement arising by
          your acceptance of them1.
        </p>
        <p>
          <strong> TechFista™ </strong>reserves the right, in its sole
          discretion, to make updates or changes to the Website and these
          Website and Mobile Device Applications Use Terms and Conditions at any
          time. The “Last Updated” in these Website and Mobile Device
          Applications Use Terms and Conditions legend above indicates when
          these Website and Mobile Device Applications Use Terms and Conditions
          were last changed. All changes are effective immediately upon posting
          to the Website. Continued use of the Website following the posting of
          revised terms will indicate your acknowledgment and agreement to be
          bound by the revised Website and Mobile Device Applications Use Terms
          and Conditions. For this reason, we encourage you to review these
          Website and Mobile Device Applications Use Terms and Conditions
          whenever you use the Website. Certain provisions of these Website and
          Mobile Device Applications Use Terms and Conditions may be superseded
          by expressly designated legal notices or terms located on particular
          pages at the Website.
        </p>
        <h6>Age Restrictions</h6>
        <p>
          This Website is considered to be a general audience site.TechFista™
          does not intend that any portion of the Website will be accessed or
          used by children under the age of 18 years and such use is prohibited.
          If you use this Website to register an account, establish a profile,
          access or purchase Products and Services from or through us, or enter
          our online surveys, sweepstakes, contests, promotions or drawings, you
          affirm that you are at least 18 years of age, or possess legal
          parental or guardian consent, and are fully able and competent to
          enter into, and comply with, the terms, conditions, obligations,
          affirmations, representations, and warranties set forth in these
          Website and Mobile Device Applications Use Terms and Conditions, the
          <strong> TechFista™ </strong>Privacy Policy, and any particular terms
          and conditions applicable to those Products and Services1. Please
          contact us at, if you believe your child has provided{" "}
          <strong> TechFista™ </strong>with personal information in connection
          with the Website.
        </p>
        <h6>Accuracy of Information</h6>
        <p>
          <strong> TechFista™ </strong>endeavours to provide complete, accurate,
          up-to-date information and content concerning the Products and
          Services on the Website Unfortunately, it is not possible to ensure
          that any website is completely free of human or technological errors.
          Notwithstanding our best efforts, the <strong> TechFista™ </strong>
          Website may contain typographical inaccuracies, mistakes, or
          omissions, some of which may relate to pricing and availability, and
          some Content may not be complete or current. As a result,{" "}
          <strong> TechFista™ </strong>does not warrant, nor isTechFista™
          responsible for, the accuracy, reliability, completeness, or
          usefulness of the Content or any Products and Services available on or
          through the <strong> TechFista™ </strong>Website.{" "}
          <strong> TechFista™ </strong>also has made every effort to display as
          accurately as possible the colors, sizes, and shapes of the Content
          and all Products and Services that appear on theTechFista™ Website.
          However, <strong> TechFista™ </strong>cannot guarantee that your
          computer or mobile device monitor’s display of any of the Products and
          Services will be accurate. Content (including pictures, photographs,
          images, videos, graphics, specifications, ingredients, narrative and
          interviews) on the <strong> TechFista™ </strong>Website are for
          illustration-purposes only.
        </p>
        <p>
          Not all Content and Products and Services are available in all
          geographic areas. Your eligibility for particular Content and any
          Products and Services is subject to final determination byTechFista™,
          compliance with local laws and regulations as interpreted
          byTechFista™, and acceptance by the entity or entities providing such
          Content and Products and Services; and the provision of such Content
          or Products and Services is not an obligation of or guaranteed
          byTechFista™.
        </p>
        <p>
          The Content and the Products and Services presented on or through the
          <strong> TechFista™ </strong>Website are made available solely for
          informational purposes. The Website has been designed to provide
          general information about how to create and search private home
          listings, either for sale or rent.TechFista™. The{" "}
          <strong> TechFista™ </strong>Website is not intended to provide
          personal shopping or personalized real estate advice or a guarantee to
          personal success or goals achievement many of which depend upon
          particular and individual user traits, characteristic, talent,
          finances and levels of ability. Nor does the Website provide
          instruction on the appropriate use of Content or Products and Services
          offered, supplied or under development byTechFista™, its affiliates,
          related companies, or its licensors, business partners, or other
          companies. All users should use Products and Services reasonably,
          conscientiously and in moderation, and with concurrent or prior input
          and advice from the user’s own professional advisors and experts. Any
          reliance you place on Content or Products and Services available on or
          through the <strong> TechFista™ </strong>Website is strictly at your
          own risk and <strong> TechFista™ </strong>or its affiliates or related
          companies are not liable or responsible for any reliance place on the
          Website’s Content or Products and Services. All users of the Website
          agree that all access and use of the Website, and any and all Content
          and Products and Services presented on the Website, is at the user’s
          own risk.
        </p>
        <p>
          <strong> TechFista™ </strong>reserves the right to correct any errors,
          inaccuracies or omissions, and to discontinue, add to, modify,
          upgrade, or replace any or all aspects of the Content, the Products
          and Services or the Website inTechFista™’s sole discretion and without
          notice. In particular, <strong> TechFista™ </strong>reserves the right
          in its sole discretion to modify, supplement, discontinue, or remove
          any Content or Products and Services appearing on or transmitted
          through the Website.TechFista™ also reserves the right to adjust
          prices, products and special offers at any time atTechFista™’s
          discretion.
        </p>
        <h6>Use</h6>
        <p>
          Except as otherwise stated on the Website or in these Website and
          Mobile Device Applications Use Terms and Conditions, you may view,
          display, download or print (“Use”) any page of this Website, subject
          to the following conditions:
        </p>
        <ol>
          <li>
            You use the material contained therein (including but not limited to
            text, graphics, images, data or sound, collectively, "Content")
            solely for personal, non-commercial purposes.
          </li>
          <li>
            or use of any text, pictures, photographs, illustrations, video or
            audio sequences or any graphics separately from the accompanying
            text), nor perform, publish or otherwise make public, or create
            derivative works of the Content or any portion thereof;
          </li>
          <li>
            You do not remove, obscure or modify any copyright, trademark or
            other legal notice;
          </li>
          <li>
            You do not transfer to any person, entity, or computer, server, or
            other communications medium any Content found on or derived from
            this Website for your own or any third party’s commercial purpose.
          </li>
        </ol>
        <p>
          No other uses of the Website are permitted without the express written
          consent ofTechFista™, and all rights herein not granted expressly
          granted are reserved.
        </p>
        <h6>
          Rules and Conduct - Prohibited Use of the Website, its Contents and
          Services
        </h6>
        <p>
          Without limitation to the provisions of Section 4 or any other
          provision of these Website and Mobile Device Applications Use Terms
          and Conditions, and subject to any further restrictions stated from
          time to time on the Website applicable to specific or other Content or
          Products and Services, you acknowledge and agree:
        </p>
        <ol>
          <li>
            Not to use the Website to post, transmit, link to, or otherwise
            distribute any information constituting or encouraging conduct that
            would constitute a criminal offense or give rise to civil liability,
            as determined by <strong> TechFista™ </strong>in its reasonable
            judgement;
          </li>
          <li>
            Not to use the Website or any Content or any of the Products and
            Services in a manner which is contrary to domestic or foreign
            federal, state, provincial or municipal law, rule or regulation
            (including, without limitation, any laws regarding the export of
            data or software to and from Spain or other countries), or would
            serve to restrict, inhibit or have a negative or damaging effect
            upon <strong> TechFista™ </strong>or any other user, as determined
            by <strong> TechFista™ </strong>in its reasonable judgement;
          </li>
          <li>
            Not to use any device, software or routine to interfere or attempt
            to interfere or interrupt the proper working of the Website or any
            computer network, or violate the security of or gain unauthorized
            access to, the Website or any computer network;
          </li>
          <li>
            Not to circumvent any technological measure implemented byTechFista™
            or any ofTechFista™’s providers or any other third party (including
            another user of the Website) to protect the Website;
          </li>
          <li>
            Not to use the Website or any Content or any Products and Services
            in any manner that could disable, overburden, damage, or impair the
            Website or interfere with any other party’s use of the Website, any
            Content or any Products and Services, including their ability to
            engage in real time activities through the Website;
          </li>
          <li>
            Not to upload, transmit, distribute, or run any computer virus,
            worm, Trojan horse, logic bomb, or any computer code or other
            material that could harm or alter a computer, portable device,
            computer network, communication network, data, the Website, or any
            other system, device or property;
          </li>
          <li>
            Not to use any software robots, spiders, crawlers, or other
            automatic device, process or means to access the Website for any
            purpose, including monitoring or copying any content on the Website;
          </li>
          <li>
            Not to transmit, or cause the sending of any advertising or
            promotional material withoutTechFista™’s prior written and informed
            consent, including any “junk mail,” “chain letter,” “spam” or any
            other similar solicitation;
          </li>
          <li>
            Not to attempt, in any manner, to obtain the password, account,
            credentials, profile information or other security information from
            any other user; or jeopardize the security of your account or anyone
            else’s account (for example, by allowing another individual to login
            to the Website as you);
          </li>
          <li>
            Not to impersonate or attempt to impersonateTechFista™, aTechFista™
            employee, contractor, sponsor, personality, another Website user or
            any other person or entity (including, without limitation, by using
            email addresses or screen names associated with any of the
            foregoing);
          </li>
          <li>
            Not to use the <strong> TechFista™ </strong>Website in any way that
            violates any applicable federal, provincial, state, local or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from Spain or
            other countries);
          </li>
          <li>
            Not to adapt, translate, modify, decompile, disassemble, remove, or
            reverse engineer any software, applications, programs or devices
            used in connection with the Website or any Content or any Products
            and Services;
          </li>
          <li>
            Not to send, knowingly receive, upload, download, use or re-use the
            Website or any Content or any Products and Services that do not
            comply with the standards and requirements set out in these Website
            and Mobile Device Applications Use Terms and Conditions;
          </li>
          <li>
            Not to engage in any other conduct that restricts or inhibits
            anyone’s use or enjoyment of the Website, or which, as determined by
            TechFista™, may harm <strong> TechFista™ </strong>or users of the
            Website or expose them to liability; or
          </li>
          <li>
            Not to use or otherwise exploit this Website or any Content or any
            Products and Services for any purposes other than as expressly
            provided by these Website and Mobile Device Applications Use Terms &
            Conditions.
          </li>
        </ol>
        <h6>Intellectual Property and User Submissions</h6>
        <ol>
          <li>
            All right title and interest in this Website and the intellectual
            property and other proprietary rights in and affecting this Website
            (and all Content therein and the results and proceeds of all
            Products and Services) including, without limitation, copyright,
            trademark, patent, industrial design, plant breeder rights,
            proprietary information and trade secrets applicable thereto or
            therein (collectively, “Intellectual Property”) are owned by
            <strong> TechFista™ </strong>or third parties with all rights
            reserved. Except as expressly provided, nothing herein or within the
            Website or its Content or the Products and Services shall be
            construed as conferring any license under any of the Intellectual
            Property rights of <strong> TechFista™ </strong>or any third party,
            whether by implication, waiver, estoppel, or otherwise. The{" "}
            <strong> TechFista™ </strong>trademarks and service marks include
            but are not limited to the following:
            <img
              className="d-block mx-auto my-2"
              src={logo}
              alt="logo"
              height={120}
              width={120}
            />
            <strong> TechFista™ </strong>and the TechFista logo are trademarks
            of <strong> TechFista™ </strong>and all rights are reserved in each
            of them.
          </li>
          <li>
            While <strong> TechFista™ </strong>welcomes your inquiries or
            feedback, <strong> TechFista™ </strong>does not accept or consider
            unsolicited ideas, including without limitation ideas for new
            advertising campaigns, new products or new services or technologies,
            or new product or service names. Please do not send any such
            unsolicited ideas. Comments, suggestions, ideas or materials sent or
            transmitted to <strong> TechFista™ </strong>by you or on your behalf
            will be deemed non-confidential information and will become the
            property of TechFista™. <strong> TechFista™ </strong>will have no
            obligation with respect to such submissions and will be free to use
            (without limitation as to time, media or geography), copy, modify,
            make derivative works and/or distribute or transfer it to third
            parties without limitation, for any purpose, commercial or
            otherwise, without compensation or acknowledgment to you.
          </li>
          <li>
            The Website may, from time to time, contain certain interactive
            features or services that allow users like you to post, upload,
            publish, display, transmit, or submit (this activity is referred to
            as “Post”) comments, suggestions, feedback, ideas, materials,
            communications, information, or other content on or through the
            Website (collectively “User Submissions”). Any and all User
            Submissions will be treated as non-proprietary and non-confidential.
            By Posting any User Submission, you grant{" "}
            <strong> TechFista™ </strong>
            and its business associates and other Website users, and each of
            their and our respective licensees, successors and assigns a
            royalty-free, perpetual, irrevocable, world-wide, sub-licensable
            exclusive license to access, copy, modify, adapt, reproduce,
            redistribute, publish, create derivative works from, compile,
            arrange, display, disclose, or otherwise make use of User
            Submissions in any form, media, or technology, whether now known or
            hereafter developed, alone or as part of other works, for any
            purpose and in any way without compensation to you (you
            acknowledging and agreeing that the full and complete compensation
            to you for all such rights is the value of your participation on the
            Website to that point in time.TechFista™ may sublicense its rights
            through multiple tiers of sublicenses.
            <strong> TechFista™ </strong>does not waive any right to use similar
            ideas previously known to it or developed by it.)
          </li>
          <li>
            If you Post a User Submission, you represent and warrant that you
            own or otherwise control the rights to such a User Submission and
            have the right to grant the license above to us and any of our
            business associates and other Website users, any licensees, and any
            of their successors and assigns. You agree that you will not Post
            any User Submission of any type that infringes or violates any
            rights of any party or that otherwise does not comply with these
            Website and Mobile Device Applications Use Terms & Conditions. You
            may not use false identifying information or contact information,
            impersonate any person or entity, or otherwise mislead{" "}
            <strong> TechFista™ </strong>or any other person as to the origin of
            any User Submission. You understand and acknowledge that you are
            solely responsible for any User Submission you Post, and you have
            full responsibility for such Submission, including its legality,
            reliability, accuracy, truthfulness and appropriateness.{" "}
            <strong> TechFista™ </strong>is not liable for the content or
            accuracy of any User Submission Posted by you or on your behalf or
            by or on behalf of any other user of the Website, any Content or any
            Products and Services.
          </li>
        </ol>
        <h6>Your Account and Profile</h6>
        <ol>
          <li>
            If you use the Website, you have the opportunity to register an
            account and/or profile withTechFista™. You are solely responsible
            for maintaining the confidentiality of your account/profile and
            password and for restricting access to your computer or mobile
            device, and you agree to accept responsibility for all activities
            that occur under your account/profile or password.{" "}
            <strong> TechFista™ </strong>is not liable for any loss or damages
            arising from your failure to maintain the confidentiality of your
            account.
          </li>
          <li>
            ou are further responsible for ensuring that all information you
            provide on the Website is correct, current, and complete. You agree
            that you will not provide any false or misleading information during
            the registration process, or in or in connection with your user or
            other profile.
          </li>
          <li>
            You have no right to transfer your account to any other individuals
            in any manner. If you are accessing and using the Website on someone
            else’s behalf, you represent that you have the authority to bind
            that person as a principal to these Website and Mobile Device
            Applications Use Terms & Conditions, and you agree to accept
            liability for harm caused by any wrongful use of the Website
            resulting from such access or use.
          </li>
          <li>
            You must notify <strong> TechFista™ </strong>immediately of any
            unauthorized access to or use of your user name or password or any
            other breach of security that you become aware of.
          </li>
          <li>
            All information you provide when you register for an account and/or
            establish a user or other profile on the Website is governed by the
            <strong> TechFista™ </strong>
            <Link to="/privacy">Privacy Policy</Link> , which is incorporated
            herein, and <strong> TechFista™ </strong>may take any action with
            respect to your information that is consistent with our Privacy
            Policy.
            <strong> TechFista™ </strong>reserves the right to refuse service or
            terminate accounts for any reason, inTechFista™’s sole discretion
            without prior notice.
          </li>
        </ol>
        <h6>Links to Third Party Websites</h6>
        <ol>
          <li>
            This Website may contain links to third party websites and other
            mobile device applications (“Third-Party Sites”). Links to other
            Third-Party Sites, including those of <strong> TechFista™ </strong>
            sponsors orTechFista™ personalities, are provided solely as a
            convenience to you.TechFista™ has no control over such Third-Party
            Sites, nor doesTechFista™ review, investigate, approve, monitor,
            endorse, or make any representations or warranties with respect to
            such Third-Party Sites; therefore, <strong> TechFista™ </strong>is
            not responsible or liable to you or to any party for any materials,
            information advertising, products, services, advice or other content
            on or available from such Third Party Sites. If you decide to access
            any Third-Party Sites, you do this entirely at your own risk. You
            acknowledge and agree that
            <strong> TechFista™ </strong>is not liable, either directly or
            indirectly, for any damage or loss caused by or in connection with
            use of or reliance on any such Third-Party Sites.
          </li>
        </ol>
        <h6>Linking to the Website and Social Media Features</h6>
        <ol>
          <li>
            <strong>Linking to the Website:</strong>
            You may link to THE WEBSite’s landing page, www.TechFista.ca and no
            other subpages, only if you comply with the requirements for Linking
            toTechFista™’S WEBSite. LINKING TOTechFista™’S WEBSITE INDICATES
            THAT YOU ACCEPT THESE TERMS AND THAT YOU WILL ABIDE BY THE
            REQUIREMENTS BELOW. IF YOU DO NOT ACCEPT THESE TERMS AND AGREE TO
            ABIDE BY THESE REQUIREMENTS, DO NOT LINK TO THIS WEBSITE.
          </li>
          <li>
            <strong>Requirements for Linking toTechFista™’s Website.</strong>
            ou may link to the Website’s landing homepage as noted in Section
            9(a) above (but not any other sub-page or pages) provided that you:
            <ol>
              <li>
                Do so in a way that is fair, legal, and does not damage
                TechFista™’s reputation or take advantage of it.
              </li>
              <li>
                Do not establish a link in any way that suggests any form of
                association, approval or endorsement onTechFista™’s or its
                business associates or its or their sponsors or personalities or
                other users; withoutTechFista™’s express and informed prior
                written consent.
              </li>
              <li>
                Do not use any <strong> TechFista™ </strong>registered and
                unregistered trademarks, logos and service marks without the
                express and informed prior written consent ofTechFista™.
              </li>
              <li>
                Do not present false, misleading or inaccurate information
                regarding <strong> TechFista™ </strong>or any Content or any
                Products and Services or any <strong> TechFista™ </strong>
                business associates, or its or their respective sponsors and
                personalities or other users.
              </li>
              <li>
                Do not establish a link from any website not owned by you.
              </li>
              <li>
                Do not cause the Website or any portion of it, or any Content or
                and Products and Services, to be displayed, or appear to be
                displayed on any other site, for example by framing or in-line
                linking, or otherwise create a browser or border environment
                around the Website or any Content or any Products and Services.
              </li>
              <li>
                Do not link to any part of the Website other than the home
                landing page, for example by deep linking.
              </li>
              <li>
                Do not establish a link from any website containing content that
                could be construed as illegal, distasteful, offensive,
                controversial, or otherwise not suitable for all age groups (as
                determined inTechFista™’s sole reasonable judgement).
              </li>
              <li>
                Otherwise take any action with respect to the Website or any
                Content or any Products and Services that is inconsistent with
                these Website and Mobile Device Applications Use Terms &
                Conditions or that violates applicable domestic or foreign laws
                or regulations.
              </li>
            </ol>
          </li>
          <li>
            <strong>Social Media Features:</strong>
            The Website may, from time to time, provide certain social media
            features that enable you to:
            <ol>
              <li>
                Link from your own or certain third-party websites to certain
                Content or Products and Services on the Website.
              </li>
              <li>
                Send emails, text messages or other communications with certain
                Content or pertaining to certain Products and Services or to and
                with other users of the Website or to and from other users or
                persons associated with the Website, or links to certain Content
                or Products and Services, on the Website.
              </li>
              <li>
                Cause portions of Content or the Products and Services on this
                Website to be displayed or appear to be displayed on your own or
                certain third-party websites.
              </li>
            </ol>
            <p>
              You may use the social media features available on the Website
              from time to time only as they are provided byTechFista™,
              including with respect to any Content or Products and Services or
              other material or links displayed with such social media features.
              Your use ofTechFista™’s social media features must also be in
              accordance with any additional terms and conditions{" "}
              <strong> TechFista™ </strong>may from time to time provide with
              respect to such features. You are solely responsible for complying
              with the terms and conditions of any third party social media
              websites, applications, or services with which you interact and
              you acknowledge that any information or other content that you
              Post on such third party social media websites, applications, or
              services is subject to that third party’s privacy policies and
              other terms and conditions, over which{" "}
              <strong> TechFista™ </strong>
              has no control, and for which <strong> TechFista™ </strong>will in
              no event be liable. You agree to cooperate with{" "}
              <strong> TechFista™ </strong>in causing any unauthorized framing
              or linking to cease immediately.
            </p>
            <li>
              <strong> TechFista™ </strong>reserves the right to withdraw
              linking permission without notice and{" "}
              <strong> TechFista™ </strong>may, in our sole discretion, request
              that you remove any link to the Website. Upon receipt of such
              request, you shall immediately remove such link.{" "}
            </li>
            <li>
              <strong> TechFista™ </strong>may disable all or any social media
              features and any links at any time without notice inTechFista™’s
              discretion.
            </li>
          </li>
        </ol>
        <h6>Privacy</h6>
        <p>
          <strong> TechFista™ </strong>is committed to protecting the privacy of
          our customers' and Website user’s personal information. Any
          information you input into this Website will be stored, kept and used
          by <strong> TechFista™ </strong>in accordance with the{" "}
          <strong> TechFista™ </strong>Privacy Policy1 from time to time in
          effect.
        </p>
        <p>
          We support industry and government initiatives to protect consumer
          privacy:
        </p>
        <ul>
          <li>
            support and endorse the Personal Information Protection and
            Electronic Documents Act
          </li>
          <li>
            model code for the Protection of Personal Information with the
            Canadian Standards Association
          </li>
        </ul>
        <p>
          If you have any questions about our privacy policies, please e-mail
          our Privacy Office at  1 and review theTechFista™
        </p>
        <h6>Monitoring, Deleting and Disclosure Notice</h6>
        <p>
          <strong> TechFista™ </strong>may, from time to time:
        </p>
        <ol>
          <li>
            Monitor the Website and collect information on Website user activity
            and any Content posted or provided, irrespective of who
            posted/provided the Content or whether it was posted/provided
            properly or in violation of these Website and Mobile Device
            Applications Use Terms and Conditions; and
          </li>
          <li>
            Employ a device to cause deletion of Content that is found on any
            computer when such computer’s browser is opened.{" "}
            <strong> TechFista™ </strong>may disclose any information as
            necessary to satisfy any domestic or foreign law, regulation or
            other governmental, court, administrative body or third-party
            request <strong> TechFista™ </strong>deems appropriate.TechFista™
            shall have no duty or obligation to challenge the scope or validity
            of any government, administrative body or court order to disclose
            any such information.
          </li>
        </ol>
        <h6>Downloads and Shareware</h6>
        <p>
          This Website may, from time to time, contain software or applications
          that may be available for downloading by <strong> TechFista™ </strong>
          or by various third parties. Such software and applications are
          available to you by
          <strong> TechFista™ </strong>or such third parties subject to the
          terms and conditions made available to you during the
          software/application installation process. You agree that it is your
          responsibility to review and evaluate any such software and
          applications, and the related terms and conditions, and that all risk
          associated with the use of, or reliance on, such software and
          applications rests with you. You further agree that{" "}
          <strong> TechFista™ </strong>shall not be responsible or liable,
          directly or indirectly, in any way for any loss or damage of any kind
          incurred as a result of, or in connection with your use of, or
          reliance on, any such software and applications, including the failure
          of such software and/or applications to meet your needs, standards,
          expectations or desired specifications.
        </p>
        <h6>Termination</h6>
        <p>
          You acknowledge and agree thatTechFista™, in its sole and absolute
          discretion, may, without notice to you, suspend or terminate your use
          of, or access to, the Website or any portion thereof or to any Content
          or to any Services. You further agree that{" "}
          <strong> TechFista™ </strong>
          will not be liable to you or to any other person as a result of any
          such suspension or termination. If you are dissatisfied with this
          Website or any Content or any Services, or with any terms, conditions,
          rules, policies, guidelines, or practices of{" "}
          <strong> TechFista™ </strong>in operating the Website, your sole and
          exclusive remedy is to discontinue using the Website.
        </p>
        <h6> Disclaimer of Warranties</h6>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT THIS WEBSITE AND ITS CONTENT
          AND ITS PRODUCTS AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
          AVAILABLE" BASIS AND THAT <strong> TechFista™ </strong>MAKES NO
          WARRANTY OR, REPRESENTATION (COLLECTIVELY, "WARRANTIES") OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
          WARRANTIES OF TREATMENT, MEDICAL OR THERAPEUTIC EFFICACY,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USE AND
          NON-INFRINGEMENT, AND THAT ALL SUCH WARRANTIES ARE HEREBY EXPRESSLY
          DISCLAIMED. <strong> TechFista™ </strong>MAKES NO WARRANTY THAT THIS
          WEBSITE, OR ANY CONTENT OR PRODUCTS AND SERVICES OR FUNCTION CONTAINED
          THEREIN WILL BE PROVIDED ON AN UNINTERRUPTED, TIMELY, SECURE OR
          ERROR-FREE/VIRUS-FREE BASIS, THAT DEFECTS WILL BE CORRECTED, OR THAT
          THE WEBSITE OR THE SERVERS THAT MAKE IT AVAILABLE ARE FREE OF VIRUSES
          OR OTHER HARMFUL COMPONENTS, OR THAT SUCH CONTENT OR PRODUCTS AND
          SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.
          <strong> TechFista™ </strong>MAKES NO EXPRESS OR IMPLIED WARRANTY
          REGARDING THE USE, MEDICAL REQUIREMENT OR SUITABILITY FOR OR
          THERAPEUTIC EFFICACY OF, OR THE RESULTS OF THE USE OF ANY CONTENT OR
          PRODUCTS AND SERVICES OF THIS WEBSITE IN TERMS OF ITS CORRECTNESS,
          ACCURACY, RELIABILITY, APPROPRIATENESS OR OTHERWISE.
        </p>
        <h6>Limitation of Liability</h6>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT SHALL{" "}
          <strong> TechFista™ </strong>OR ITS CORPORATE AFFILIATES BE LIABLE FOR
          ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT,
          INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY
          DAMAGES, OR ANY DAMAGES FOR PERSONAL INJURY, LOSS OF PROFITS,
          INTERRUPTION OF BUSINESS, LOSS OF PROGRAMS OR OTHER DATA, OR LOSS OF
          USE, SAVINGS, GOODWILL OR OTHER INTANGIBLE LOSSES, REGARDLESS OF
          WHETHER <strong> TechFista™ </strong>HAS BEEN ADVISED OF OR COULD HAVE
          FORESEEN THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN
          CONNECTION WITH: (A) THE USE, INABILITY TO USE OR PERFORMANCE OF ANY
          OF THE WEBSITE, ANY MOBILE DEVICE APPLICATIONS, ANY CONTENT OR ANY
          PRODUCTS AND SERVICES, (B) ANY OTHER MATTER RELATING TO THE WEBSITE
          AND ANY MOBILE DEVICE APPLICATIONS OR ITS CONTENT OR ITS PRODUCTS AND
          SERVICES; OR (C) YOUR USE OF ANY CONTENT PRODUCTS OR SERVICES AND THE
          RISKS ASSOCIATED WITH THAT USE (KNOWN OR UNKNOWN), REGARDLESS OR
          WHETHER ANY OF THE FOREGOING IS DETERMINED TO CONSTITUTE A FUNDAMENTAL
          BREACH OR FAILURE OF ESSENTIAL PURPOSE. Some jurisdictions may not
          permit the exclusion or limitation of warranties or damages in certain
          types of agreements and the foregoing exclusions and limitations may
          not be applicable to you.
        </p>
        <h6>Indemnity and Release</h6>
        <p>
          You agree to defend, indemnify and hold <strong> TechFista™ </strong>
          and its business associates and its and their sponsors and
          personalities, and each of their respective parent, affiliates,
          subsidiaries, and third party information providers, and their
          respective, officers, directors, employees, agents and contractors and
          advisors (collectively, “Indemnitees”) harmless from any and all
          liabilities, claims, costs and expenses, including costs of
          investigation, attorneys' fees, accounting fees and litigation
          expense, related to or in connection with:
        </p>
        <ol>
          <li>
            Any violation of these Website and Mobile Device Applications Use
            Terms and Conditions;
          </li>
          <li>
            Unauthorized use of any user ID or password provided to you; or{" "}
          </li>
          <li>
            Your service upon <strong> TechFista™ </strong>of a subpoena or
            other court or governmental order to produce a person or record in
            connection with a legal or equitable action or matter to which{" "}
            <strong> TechFista™ </strong>is not a party.
          </li>
        </ol>
        <p>
          You hereby release and forever discharge the Indemnitees in connection
          with any error, omission, interruption, deletion, defect, or delay in
          operations or transmission of this Website, any mobile device
          applications, any Content or any Products and Services; and technical,
          network, telephone, communications, computer hardware or software
          malfunctions or errors of any kind.
        </p>
        <h6>International Use</h6>
        <p>
          You agree to comply with all domestic, foreign and local laws and
          rules including, without limitation, laws governing the Internet,
          social media, data, email, Intellectual Property and privacy. You
          agree to comply with all applicable laws regarding the transmission of
          technical data exported from the country in which you reside and/or
          work and in the country to which transmission is or is intended to be
          made (as well as in any countries in which data transmission my route
          or transit on the way to the ultimate destination of delivery).
        </p>
        <h6>Jurisdiction and Disputes</h6>
        <ol>
          <li>
            This Website originates in Ontario – Spain. These Website and Mobile
            Device Applications Use Terms and Conditions, and all disputes,
            claims, actions, causes of action, issues, or requests you may make
            or initiate or commence for relief between you andTechFista™ arising
            out of or relating to these Website and Mobile Device Applications
            Use Terms and Conditions, your visit to the Website, or to or use of
            Content or Products and Services, (collectively “Disputes”), shall
            be governed by and construed in accordance with the laws of the
            Province of Ontario and the federal laws of Spain applicable
            therein, without giving effect to any conflict of laws principles
            that may provide the application of the law of another jurisdiction.
            You and we irrevocably consent to the exclusive jurisdiction and
            venue of the courts of the Province of Ontario, and the Federal
            Courts of Spain therein, for all Disputes that are heard in court,
            including any Dispute involving <strong> TechFista™ </strong>or any
            one or more Indemnitees.
          </li>
          <li>
            You agree that regardless of any statute or law to the contrary, any
            Dispute must be filed within one (1) year after the occurrence of
            the event or facts giving rise to a Dispute, or you waive the right
            to pursue any Dispute based upon such event or facts forever.
          </li>
          <li>
            TechFista™’s priority is to learn about and address any of your
            concerns related to the Website or the Content or Products and
            Services available on or through the Website. You agree that you
            will first notify <strong> TechFista™ </strong>about any Dispute you
            have with us regarding these Website and Mobile Device Applications
            Use Terms and Conditions, the Website, or any Content or Products
            and Services obtained on or through the Website by contacting{" "}
            <strong> TechFista™ </strong>at 1 . Once{" "}
            <strong> TechFista™ </strong>
            receives your notice, <strong> TechFista™ </strong>will try to
            resolve the Dispute informally by contacting you through E Mail. If
            a Dispute is not resolved within 90 days after your submission, you
            or TechFista™ may bring a formal proceeding.
          </li>
          <li>
            Notwithstanding the foregoing, you agree that{" "}
            <strong> TechFista™ </strong>may seek equitable remedies against you
            in any jurisdiction to protect or enforceTechFista™’s Intellectual
            Property and the rights therein, or to seek compliance with local
            laws in a corresponding jurisdiction.
          </li>
        </ol>
        <h6>General</h6>
        <ol>
          <li>
            Notwithstanding the foregoing, you agree that{" "}
            <strong> TechFista™ </strong>may seek equitable remedies against you
            in any jurisdiction to protect or enforceTechFista™’s Intellectual
            Property and the rights therein, or to seek compliance with local
            laws in a corresponding jurisdiction.
          </li>
          <li>
            Nothing contained in these Website and Mobile Device Applications
            Use Terms and Conditions creates any agency, partnership, or other
            form of joint enterprise between you andTechFista™.
          </li>
          <li>
            These Website and Mobile Device Applications Use Terms and
            Conditions, together with theTechFista™1{" "}
            <Link to="/privacy">Privacy Policy</Link> to Privacy Policy,
            constitute the complete and exclusive understanding between you and
            <strong> TechFista™ </strong>with respect to your use of the
            Website, all Content and all Products and Services, and these
            Website and Mobile Device Applications Use Terms and Conditions
            supersede and replace any and all prior oral or written
            understandings or agreements between
            <strong> TechFista™ </strong>and you regarding the Website, the
            Content and the Products and Services.{" "}
          </li>
          <li>
            TechFista™’s failure to enforce any right or provision in these
            Website and Mobile Device Applications Use Terms and Conditions will
            not constitute a waiver of such right or provision or of any other
            rights or provisions in these Website and Mobile Device Applications
            Use Terms and Conditions.{" "}
          </li>
          <li>
            If a court or tribunal should find that one or more rights or
            provisions set forth in these Website and Mobile Device Applications
            Use Terms and Conditions are invalid, you agree that the remainder
            of these Website and Mobile Device Applications Use Terms and
            Conditions will remain in full force and effect and that the invalid
            provision will be enforceable to the fullest extent permitted by
            law. <strong> TechFista™ </strong>will amend or replace such
            provisions with ones that are valid and enforceable and which
            achieve, to the extent possible,TechFista™’s original objectives and
            intent as reflected in the original provisions.
          </li>
          <li>
            The parties have required that these Website and Mobile Device
            Applications Use Terms and Conditions and all documents relating
            thereto be drawn up in English. Les parties ont demandé que les
            modalités d'utilisation ainsi que tous les documents qui s'y
            rattachent soient rédigés en anglais.
          </li>
          <li>
            If you have any questions regarding these Website and Mobile Device
            Applications Use Terms and Conditions, please contact us by E Mai
          </li>
        </ol>
        <h6>
          Attention: <strong> TechFista™ </strong>Privacy Officer
        </h6>
      </blockquote>
    </TermsStyle>
  );
};

export default Terms;
