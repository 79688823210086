import { Col, Row } from "react-bootstrap";
import selectIcon from "../../assets/icons/ic_select.svg";
import unSelectIcon from "../../assets/icons/ic_add_property_additional.svg";
import Headbar from "../../components/Headbar/Headbar";
import { FeatureDetailsStyle } from "./style";
import addFeatureIcon from "../../assets/icons/ic_add_property_general_info_add.svg";
import { useFormikContext } from "formik";
import { TertiraryHeading } from "../../components/GlobalStyle";
import plusIcon from "../../assets/icons/ic_maximum.svg";
import minusIcon from "../../assets/icons/ic_minimum.svg";
import InputField from "../../components/InputField/InputField";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../redux/store";
import Loader from "../../components/Loader/Loader";
import TextField from "../../components/InputField/TextField";
import { useEditPropetyContext } from "./EditProperty";
import { editPropertyInitTypes } from "./types";

const FeatureDetails = () => {
  const {
    propertyExtraOptions,
    propertyServiceOptions,
    propertyUniqueOptions,
    setPropertyExtraOptions,
    setPropertyServiceOptions,
    setPropertyUniqueOptions,
    facingData,
    setFacingData,
  } = useEditPropetyContext();

  const {
    data: { propertyAppData },
  } = useAppSelector((state: RootState) => state.appData);

  // formik context provider
  const { values, setFieldValue } = useFormikContext<editPropertyInitTypes>();

  // property data
  const propertyData = propertyAppData[+values.propertyTypeId - 1];

  //   property unique options
  const handlePropertyUniqueOptions = (index: number) => {
    const oldUniqueOptions = [...propertyUniqueOptions];
    const selectedOptionData = oldUniqueOptions.find(({ id }) => id === index);
    if (!selectedOptionData) {
      return;
    }

    setFieldValue(
      `propertyUniqueOptions.${selectedOptionData.keyName}`,
      !values.propertyUniqueOptions[selectedOptionData.keyName]
    );
  };

  //   property service options
  const handlePropertyServiceOptions = (index: number) => {
    const oldPropertyServiceOptions = [...propertyServiceOptions];
    const selectedOptionData = oldPropertyServiceOptions.find(
      ({ id }) => id === index
    );
    if (!selectedOptionData) {
      return;
    }
    selectedOptionData.isSelect = !selectedOptionData.isSelect;
    setPropertyServiceOptions(oldPropertyServiceOptions);
    setFieldValue(
      `propertyServices.${selectedOptionData.keyName}`,
      !values.propertyServices[selectedOptionData.keyName]
    );
  };

  // facing direction handler
  const facingDataHandler = (index: number) => {
    const oldFacingData = [...facingData];
    const updatedData = oldFacingData.map((oldData, itemIndex) => {
      if (itemIndex === index) {
        return { ...oldData, isSelect: true };
      } else {
        return { ...oldData, isSelect: false };
      }
    });
    const facingDataSelected = updatedData.find(
      ({ isSelect }) => isSelect === true
    );
    if (facingDataSelected) {
      setFieldValue("facingAndFlorInfo.direction", facingDataSelected.abbre);
    }
    setFacingData(updatedData);
  };

  return (
    <FeatureDetailsStyle>
      <div className="mt-4">
        <Headbar title="Additional Details" icon={addFeatureIcon} />
      </div>
      {propertyData.propertyExtraOptionDTOS.length !== 0 && (
        <>
          <TertiraryHeading className="mt-3">
            About Your Property(Optional)
          </TertiraryHeading>
          <Row>
            {propertyExtraOptions.length === 0 ? (
              <Loader />
            ) : (
              propertyExtraOptions.map(
                ({ displayName, keyName, id, value }) => (
                  <Col
                    key={id}
                    className="input-section d-flex align-items-center mt-2"
                    sm={6}
                    md={4}
                  >
                    <InputField
                      maxRange={20}
                      textAlign="left"
                      title={displayName}
                      value=""
                    />
                    {values["propertyExtraOptions"][keyName] > 0 && (
                      <>
                        <img
                          src={minusIcon}
                          className="arithmetic-icon"
                          alt="minus-icon"
                          onClick={() => {
                            setFieldValue(
                              `propertyExtraOptions.${keyName}`,
                              values["propertyExtraOptions"][keyName] - 1
                            );
                          }}
                        />
                        <h6 className="mx-2 fw-bold mb-0">
                          {values["propertyExtraOptions"][keyName]}
                        </h6>
                      </>
                    )}
                    <img
                      onClick={() => {
                        setFieldValue(
                          `propertyExtraOptions.${keyName}`,
                          values["propertyExtraOptions"][keyName]
                            ? values["propertyExtraOptions"][keyName] + 1
                            : 0 + 1
                        );
                      }}
                      src={plusIcon}
                      className="arithmetic-icon"
                      alt="plus-icon"
                    />
                  </Col>
                )
              )
            )}
          </Row>
          {propertyData.propertyUniqueOptionDTOS.length !== 0 && (
            <div className="features-container pb-3">
              <TertiraryHeading className="mt-3">
                Make Your Property Unique (Optional)
              </TertiraryHeading>
              <Row>
                {propertyUniqueOptions.map(
                  ({ displayName, keyName, isSelect, id }) => (
                    <Col key={id} sm={6} md={4}>
                      <div
                        onClick={() => handlePropertyUniqueOptions(id)}
                        className="feature cursor-pointer d-flex align-items-center"
                      >
                        <span>
                          <img
                            className="mb-1"
                            src={
                              values.propertyUniqueOptions[keyName]
                                ? selectIcon
                                : unSelectIcon
                            }
                            alt={
                              values.propertyUniqueOptions[keyName]
                                ? "check-icon"
                                : "uncheck-icon"
                            }
                          />
                        </span>
                        <p className="feature-text mb-0 ms-2">{displayName}</p>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          )}
          {propertyData.propertyServicesDTOS.length !== 0 && (
            <>
              <div className="features-container pb-3">
                <TertiraryHeading className="mt-3">
                  Specify the Services (Optional)
                </TertiraryHeading>
                <Row>
                  {propertyServiceOptions.map(
                    ({ keyName, displayName, isSelect, id }) => (
                      <Col key={id} sm={6} md={4}>
                        <div
                          onClick={() => handlePropertyServiceOptions(id)}
                          className="feature cursor-pointer d-flex align-items-center"
                        >
                          <span>
                            <img
                              src={
                                values.propertyServices[keyName]
                                  ? selectIcon
                                  : unSelectIcon
                              }
                              alt={isSelect ? "check-icon" : "uncheck-icon"}
                            />
                          </span>
                          <p className="feature-text mb-0 ms-2">
                            {displayName}
                          </p>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </>
          )}
          <TertiraryHeading className="mt-3">
            Facing & Floors Info (Optional)
          </TertiraryHeading>
          <Row className="propery-results align-items-center mt-3">
            <Col sm={6} md={4} className="mt-2 mt-sm-0">
              <TextField
                name="numberOfFloors"
                label="Number of floors"
                placeholder="Number of floors"
                padding="8px"
                type="number"
                labelMarginBottom="12px"
                value={values.facingAndFlorInfo.numberOfFloors}
                onChange={(event) =>
                  setFieldValue(
                    "facingAndFlorInfo.numberOfFloors",
                    event.target.value
                  )
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2 mt-sm-0">
              <TextField
                name="yourFloor"
                label="Your Floor"
                placeholder="Your Floor"
                padding="8px"
                type="number"
                labelMarginBottom="12px"
                value={values.facingAndFlorInfo.yourFloor}
                onChange={(event) =>
                  setFieldValue(
                    "facingAndFlorInfo.yourFloor",
                    event.target.value
                  )
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2 mt-sm-0"></Col>
          </Row>
          <div className="features-container pb-3">
            <TertiraryHeading className="mt-3">Facing</TertiraryHeading>
            <Row>
              {facingData.map(({ title, isSelect, abbre }, index) => (
                <Col key={index} sm={6} md={4}>
                  <div
                    onClick={() => facingDataHandler(index)}
                    className="feature cursor-pointer d-flex align-items-center"
                  >
                    <span>
                      <img
                        src={
                          values.facingAndFlorInfo.direction == abbre
                            ? selectIcon
                            : unSelectIcon
                        }
                        alt={isSelect ? "check-icon" : "uncheck-icon"}
                      />
                    </span>
                    <p className="feature-text mb-0 ms-2">{title}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </FeatureDetailsStyle>
  );
};

export default FeatureDetails;
