import { Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import { ErrorText, primaryColor2 } from "../../components/GlobalStyle";
import ic_logo from "../../assets/icons/ic_logo.svg";
import ResetPasswordStyle from "./style";
import AuthNavbar from "../../components/Navbar/AuthNavbar";
import { useGlobalContext } from "../../context/context";
import { useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import Head from "../../components/Head/Head";
import { errorTypes } from "../../utils/types";
import axios from "axios";
import { user_reset_password_url } from "../../utils/api_urls";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";

type initialValuesTypes = {
  phoneNumber: string | number;
  password: string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userPhoneNumber, resetPasswordToken } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null>(null);
  const resetPasswordToastId = useRef<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // get Validations from redux appData
  const { validations } = useAppSelector((state) => state.appData.data);

  // reset password validations
  const phoneNumber = validations[15];
  const password = validations[28];

  // reset password regExpressions
  const phoneNumberReg = new RegExp(phoneNumber.pattern);
  const passwordReg = new RegExp(password.pattern);

  // initialValues
  const initialValues: initialValuesTypes = {
    phoneNumber: userPhoneNumber || "",
    password: "",
  };

  // validation schema
  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(phoneNumber.notBlankMsg)
      .matches(phoneNumberReg, phoneNumber.patternMsg),
    password: Yup.string()
      .required(password.notBlankMsg)
      .matches(passwordReg, password.patternMsg),
  });

  const handleSubmit = async (values: any) => {
    console.log({ resetPasswordToken });
    const resetUserData = {
      ...values,
      resetPasswordToken,
    };
    try {
      setLoading(true);
      const { data } = await axios.post(user_reset_password_url, resetUserData);
      if (data.responseCode === "500") {
        resetPasswordToastId.current = toast(data.responseMessage, {
          type: "error",
          autoClose: 1000,
        });
        setLoading(false);
        return;
      }
      resetPasswordToastId.current = toast(data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      setIsModalVisible(true);
      setLoading(false);
      console.log({ data });
    } catch (error: any) {
      resetPasswordToastId.current = toast(
        error.response.data.responseMessage,
        {
          type: "error",
          autoClose: 1000,
        }
      );
      setLoading(false);
      setError(error.response.data.responseMessage);
    }
  };

  // do task when password reset
  const doTask = () => {
    setIsModalVisible(false);
    navigate("/login", {
      replace: true,
    });
  };

  return (
    <>
      <Head title="reset-password" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <EnnvisionModal
          description="Your password has been reset successfully. Thank you for using our app and keep your account secure"
          doTask={doTask}
          closeText="Thanks"
          bgBtn={primaryColor2}
          color="white"
        />
      </CustomModal>
      <ResetPasswordStyle>
        <div className="reset-password-container-card">
          <div className="reset-password-container-card-logo">
            <img src={ic_logo} alt="ic_logo" className="logo" />
          </div>
          <div className="reset-password-container-card-form">
            <h6 className="fw-bold my-2 text-center">Reset Passsword</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => {
                console.log(formik.values);
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <div className="reset-password-input-fields">
                      <FormControl
                        control="input"
                        type="tel"
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="User Name"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        labelMarginBottom="8px"
                        disabled={true}
                        className={
                          formik.errors.phoneNumber &&
                          formik.touched.phoneNumber
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                      <div className="reset-password-input-fields-field">
                        <FormControl
                          control="password"
                          type="text"
                          name="password"
                          label="New Password"
                          placeholder="Password"
                          fontFamily="EnnVisionsMedium"
                          labelMarginBottom="8px"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "is-invalid"
                              : "customPasswordInput"
                          }
                        />
                      </div>
                      {error ? <ErrorText>{error}</ErrorText> : null}
                      <div className="my-2">
                        <CustomButton
                          bgcolor={primaryColor2}
                          color="white"
                          padding="8px"
                          width="100%"
                          type="submit"
                          title="Reset Password"
                          fontSize="14px"
                          fontFamily="EnnVisionsMedium"
                          loading={loading}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </ResetPasswordStyle>
    </>
  );
};

export default ResetPassword;
