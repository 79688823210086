import AliceCarousel from "react-alice-carousel";
import { ChooseUsItemStyle, WhyChooseUsStyled } from "./style";
import whyChooseBanner from "../../assets/images/ic_services_img.png";
import serviceIcon1 from "../../assets/icons/ic_services_1.svg";
import serviceIcon2 from "../../assets/icons/ic_services_2.svg";
import serviceIcon3 from "../../assets/icons/ic_services_3.svg";
import serviceIcon4 from "../../assets/icons/ic_services_4.svg";
import { BlackDot, UnderlineRed } from "../../components/GlobalStyle";
import { Container } from "react-bootstrap";
import { useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import useScreenTranslation from "../../hooks/useScreenTranslation";



const WhyChooseUs = () => {
  const {getLabelByKey}=useScreenTranslation('whyChooseUs')
  // const whyChooseUsData = [
  //   {
  //     title: "  Anuncia tu Propiedad",
  //     sub_title: "Vende o alquila tu propiedad",
  //     sub_title1: "sin pagar comisiones de agente inmobiliario propiedades",
  //     img: serviceIcon2,
  //   },
  //   {
  //     title: " Encuentra un Hogar",
  //     sub_title: " Una forma más inteligente y sencilla de",
  //     sub_title1: "buscar casas y alquileres propiedades",
  //     img: serviceIcon3,
  //   },
  //   {
  //     title: "Obtén Ayuda de Profesionales",
  //     sub_title: "Encuentra un profesional que te ayude",
  //     sub_title1: "a vender tu casa propiedades.",
  //     img: serviceIcon4,
  //   },
  //   {
  //     title: "Captura la Atención del Público",
  //     sub_title: "Busca miles de casas",
  //     sub_title1: "y apartamentos en tu zona",
  //     img: serviceIcon1,
  //   },
  // ];
  
  // const whyChooseUsDetails = [
  //   {
  //     title: "Crea tu anuncio con nuestras funciones fáciles de usar",
  //     sub_title:
  //       "Utiliza nuestra plataforma para crear tu anuncio y conocer el valor de mercado. Añade imágenes y vídeos para mostrar tu hogar. Toma el control de cómo vendes tu casa con Global Living y ahorra dinero.",
  //     img: serviceIcon2,
  //   },
  //   {
  //     title:
  //       "Únete a nuestra creciente comunidad de profesionales en Global Living para conectarte con las personas que más podrían necesitar tus servicios.",
  //     sub_title:
  //       "Ya seas contratista, diseñador de interiores, preparador de viviendas o incluso abogado inmobiliario, registra tus servicios en Global Living y llega a miles de posibles clientes. Es una manera sencilla de llegar a una audiencia calificada y ayudar a desarrollar tu negocio al asistir a nuestros usuarios en la venta de sus hogares. ",
  //     img: serviceIcon3,
  //   },
  //   {
  //     title:
  //       "Explora nuestra red de profesionales locales que ofrecen una amplia variedad de servicios y soluciones a todos nuestros miembros.",
  //     sub_title:
  //       "Las primeras impresiones son importantes. Al tomarte el tiempo para preparar tu hogar para la venta, puedes aumentar su atractivo y mejorar tus posibilidades de una venta exitosa. ",
  //     img: serviceIcon4,
  //   },
  //   {
  //     title:
  //       "Únete a nuestra creciente comunidad de profesionales en Global Living para conectarte con las personas que más podrían necesitar tus servicios.",
  //     sub_title:
  //       "Ya seas contratista, diseñador de interiores, preparador de viviendas o incluso un abogado inmobiliario, registra tus servicios en Global Living y llega a miles de clientes potenciales. Es una forma sencilla de acceder a una audiencia cualificada y ayudar a desarrollar tu negocio al asistir a nuestros usuarios en la venta de sus hogares. ",
  //     img: serviceIcon3,
  //   },
  // ];
  
  const whyChooseUsData = [
    {
      title:getLabelByKey('title1'),
      sub_title: "Sell or rent your property without paying real estate agent commissions",
      sub_title1: "without paying real estate agent fees properties",
      img: serviceIcon2,
    },
    {
      title: "Find a Home",
      sub_title: "A smarter and simpler way to search for houses and rental properties",
      sub_title1: "search houses and rent properties",
      img: serviceIcon3,
    },
    {
      title: "Get Professional Assistance",
      sub_title: "Find a professional to help you sell your property",
      sub_title1: "to sell your home properties",
      img: serviceIcon4,
    },
    {
      title: "Capture Public Attention",
      sub_title: "Browse thousands of houses and apartments in your area",
      sub_title1: "and apartments in your area",
      img: serviceIcon1,
    },
  ];
  
  const whyChooseUsDetails = [
    {
      title: "Create your ad with our easy-to-use features",
      sub_title:
        "Use our platform to create your ad and know the market value. Add images and videos to show your home. Take control of how you sell your house with Global Living and save money.",
      img: serviceIcon2,
    },
    {
      title:
        "Join our growing community of professionals at Global Living to connect with the people who might most need your services.",
      sub_title:
        "Whether you are a contractor, interior designer, housing planner or even a real estate attorney, register your services with Global Living and reach thousands of potential customers. It is a simple way to reach a qualified audience and help grow your business by assisting our users in the sale of their homes. ",
      img: serviceIcon3,
    },
    {
      title:
        "Explore our network of local professionals who offer a wide range of services and solutions to all our members.",
      sub_title:
        "First impressions are important. By taking the time to prepare your home for sale, you can increase its appeal and improve your chances of a successful sale. ",
      img: serviceIcon4,
    },
    {
      title:
        "Join our growing community of professionals at Global Living to connect with the people who might most need your services.",
      sub_title:
        "Whether you are a contractor, interior designer, housing planner or even a real estate attorney, register your services with Global Living and reach thousands of potential customers. It is a simple way to reach a qualified audience and help grow your business by assisting our users in the sale of their homes. ",
      img: serviceIcon3,
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedChooseIndex, setSelectedChooseIndex] = useState(0);

  const responsive = {
    0: { items: 1.5 },
    568: { items: 2.5 },
    768: { items: 3 },
    1024: { items: 4 },
  };
  const items = [
    ...whyChooseUsData.map(
      ({ title, sub_title, sub_title1, img }: any, index: number) => (
        <div key={index} className="pxp-services-container rounded-lg p-0">
          <div
            onClick={() => {
              setSelectedChooseIndex(index);
              setShowModal(true);
            }}
            className="pxp-services-item"
          >
            <div className="pxp-services-item-fig">
              <img src={img} alt="properties" />
            </div>
            <div className="pxp-services-item-text text-center">
              <div className="pxp-services-item-text-title">{title}</div>
              <div className="pxp-services-item-text-sub">
                {sub_title}
                <br />
                {sub_title1}
              </div>
            </div>
          </div>
        </div>
      )
    ),
  ];

  return (
    <WhyChooseUsStyled>
      <CustomModal
        isModalVisible={showModal}
        setIsModalVisible={setShowModal}
        showCloseBtn={false}
      >
        <ChooseUsItemStyle>
          <h6>{whyChooseUsDetails[selectedChooseIndex].title}</h6>
          <p>{whyChooseUsDetails[selectedChooseIndex].sub_title}</p>
        </ChooseUsItemStyle>
      </CustomModal>
      <div
        className="pxp-services pxp-cover mt-100 pt-100 mb-200"
        style={{ backgroundImage: `url(${whyChooseBanner})` }}
      >
        <h2 className="text-center pxp-section-h2">Reasons to Choose Us</h2>
        {/* <p className="pxp-text-light text-center">
          Take Control Of The Most Important Decision Of Your Life
        </p> */}
        <div className="d-flex justify-content-center">
          <UnderlineRed />
          <BlackDot />
        </div>
        <Container>
          <div className="cards mt-4 mt-md-5">
            <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              disableDotsControls
              disableButtonsControls
            />
          </div>
        </Container>
      </div>
    </WhyChooseUsStyled>
  );
};

export default WhyChooseUs;
