import { useState } from "react";
import styled from "styled-components";
import CustomDate from "../Date/Date";
import CustomTime from "../Time/Time";
import { fontFamilyMedium, primaryColor2 } from "../../components/GlobalStyle";
import { Input } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  contact_request_url,
  edit_booking_url,
} from "../../utils/api_urls";
import { toast } from "react-toastify";
import axios from "axios";
import { useBookingContext } from "../../context/BookingContext";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionWarningModal from "../../components/CustomModals/WarningModal";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";

const { TextArea } = Input;

type bookingStateTypes = {
  newsFeedId: number;
  visitType: string;
  description?: string;
  visitDate?: string;
  visitStartTime?: string;
  visitEndTime?: string;
};
const EditInPerson = () => {
  // const { state: locationData }: { state: bookingStateTypes } = useLocation();
  const { selectedBooking } = useBookingContext();
  // if (!locationData) {
  //   return <Navigate to="/property-listing" />;
  // }
  const [date, setDate] = useState<string | null>(
    selectedBooking.visitDate || ""
  );
  const [description, setDescription] = useState(
    selectedBooking.description || ""
  );
  // Create a dayjs instance representing the current time as the initial value
  const [selectedTime, setSelectedTime] = useState<string>(
    selectedBooking.visitStartTime || ""
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    bookingId,
    newsFeedId = "",
    visitType = "",
    requestStatusId = "",
  } = selectedBooking;
  //   request tour promise
  const editTourPromise = async () => {
    if (!date || !description || !selectedTime) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        edit_booking_url,
        {
          bookingId,
          newsFeedId,
          visitType,
          requestStatusId,
          description,
          visitDate: date,
          visitStartTime: selectedTime,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  console.log({ date }, "date in on change");

  console.log({ date, selectedTime });

  return (
    <>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        {isLoading && <LoadingOverlay message="Editing.." />}
        <EnnvisionModal
          title="Visit Edit"
          description="Visited Edited Successfully"
          doTask={() => navigate(-1)}
        />
      </CustomModal>
      <Wrapper>
        <article>
          <label htmlFor="date">Select Date</label>
          <div className="mt-2">
            <CustomDate date={date} setDate={setDate} />
            {!date && <p className="error">Date is Required</p>}
          </div>
          {/* {date && <p>Selected date: {date.format("DD MMM YYYY")}</p>} */}
          <div className="mt-2">
            <label htmlFor="time">Select Time</label>
            <div className="mt-2">
              <CustomTime time={selectedTime} setTime={setSelectedTime} />
            </div>
            {!selectedTime && <p className="error">Time is Required</p>}
          </div>
          <div className="mt-2">
            <label htmlFor="description">Description</label>
            <div className="mt-2">
              <TextArea
                maxLength={100}
                style={{ height: 240, marginBottom: 16 }}
                onChange={(event) => setDescription(event.target.value)}
                value={description}
                placeholder="Describe the reason of schedule"
              />
              {!description && <p className="error">Description is Required</p>}
            </div>
          </div>
          <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="8px"
            width="100%"
            type="submit"
            title="Save"
            margin="auto"
            loading={isLoading}
            clicked={editTourPromise}
          />
        </article>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.section`
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 3px;
  width: 369px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 10px;
  margin-top: 20px;
  .error {
    margin-top: 10px;
    color: ${primaryColor2};
    font-family: ${fontFamilyMedium};
  }
  label {
    font-family: ${fontFamilyMedium};
    font-size: 14px;
  }
  input {
    padding: 4px;
  }
  .selected_Date {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisions";
    letter-spacing: 0px;
    color: #000000;
  }
`;
export default EditInPerson;
