import { Container } from "react-bootstrap";
import SettingAndPrivacyStyle from "./style";
import Terms from "../../components/Terms/Terms";
import enable_switch from "../../assets/icons/ic_post_enable.svg";
import disable_switch from "../../assets/icons/ic_post_disable.svg";
import { general_settings_url } from "../../utils/api_urls";
import usePostEvent from "../../hooks/usePostEvent";
import { Formik } from "formik";
import { Form } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor2 } from "../../components/GlobalStyle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { toast } from "react-toastify";
import { objectNotEmpty } from "../../utils/utilities";

type settingsInitValues = {
  notification: boolean;
  takePhotoWithCamera: boolean;
  photoMediaFile: boolean;
  location: boolean;
  phoneCallLogs: boolean;
  inAppPurchase: boolean;
  backgroundAppRefresh: boolean;
  socialMediaIntegration: boolean;
};

let initialValues: settingsInitValues = {
  notification: true,
  takePhotoWithCamera: false,
  photoMediaFile: false,
  location: true,
  phoneCallLogs: false,
  inAppPurchase: false,
  backgroundAppRefresh: false,
  socialMediaIntegration: false,
};

const settingOptions = [
  {
    title: "Notifications",
    name: "notification",
  },
  {
    title: "Take Photo with Camera",
    name: "takePhotoWithCamera",
  },
  {
    title: "Photos/Media/Files",
    name: "photoMediaFile",
  },
  {
    title: "Location",
    name: "location",
  },
  {
    title: "Phone/Call Logs",
    name: "phoneCallLogs",
  },
  {
    title: "In-app Purchases",
    name: "inAppPurchase",
  },
  {
    title: "Background App Refresh",
    name: "backgroundAppRefresh",
  },
  {
    title: "Social Media Integration",
    name: "",
  },
  {
    title: "SMS Notifications",
    name: "socialMediaIntegration",
  },
];
const SettingAndPrivacy = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { apiDataPromise, data, error, loading } = usePostEvent();

  // handle submit
  const handleSubmit = async () => {
    try {
      await apiDataPromise(general_settings_url, {
        notification: true,
        takePhotoWithCamera: false,
        photoMediaFile: false,
        location: true,
        phoneCallLogs: false,
        inAppPurchase: false,
        backgroundAppRefresh: false,
        socialMediaIntegration: false,
      });
      if (Object.keys(data as {}).length !== 0) {
        setIsModalVisible(true);
        setTimeout(() => {
          setIsModalVisible(true);
          navigate("/explore-nearby");
        }, 2000);
      } else {
        // Handle the case when the result is not true
        console.error("Promise result is not true");
      }
    } catch (error) {
      // Handle the error here
      console.error("Error occurred:", error);
      // Show an error message or perform any other error handling logic
    }
  };

  // do task when user deleted
  const doTask = () => {
    setIsModalVisible(false);
    navigate("/explore-nearby");
  };

  return (
    // <Container>
    <>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="General Settings"
          description="update general settings successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => {
          console.log(formik.values, "formik values", formik.errors);
          return (
            <Form
              name="basic"
              onFinish={formik.handleSubmit}
              autoComplete="off"
            >
              <SettingAndPrivacyStyle>
                <h6 className="title">General Settings</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque et dictum mi.
                </p>

                <div className="settings w-100">
                  {settingOptions.map(({ title, name }, index) => (
                    <div
                      key={index}
                      className="settings-option d-flex justify-content-between align-items-center"
                    >
                      <p className="mb-0 settings-option-title">{title}</p>
                      <img
                        src={
                          formik.values[name as keyof settingsInitValues]
                            ? enable_switch
                            : disable_switch
                        }
                        alt="one/off"
                        onClick={() =>
                          formik.setFieldValue(
                            name,
                            !formik.values[name as keyof settingsInitValues]
                          )
                        }
                        className="cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
                <h6
                  className="terms cursor-pointer"
                  onClick={() => navigate("/terms")}
                >
                  Terms Conditions of Use
                </h6>
                <h6
                  className="privacy cursor-pointer"
                  onClick={() => navigate("/privacy")}
                >
                  Privacy Policy Of Use
                </h6>
                <div className="my-2">
                  <CustomButton
                    bgcolor={primaryColor2}
                    color="white"
                    padding="6px"
                    width="100%"
                    type="submit"
                    textTransform="Captilize"
                    title="Save Changes"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    loading={loading}
                  />
                </div>
                <div className="my-1">
                  <CustomButton
                    bgcolor={primaryColor2}
                    color="white"
                    padding="6px"
                    width="100%"
                    type="button"
                    textTransform="Captilize"
                    title="Delete Account"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                  />
                </div>
              </SettingAndPrivacyStyle>
            </Form>
          );
        }}
      </Formik>
      </>
    // </Container>
  );
};

export default SettingAndPrivacy;
