import FindHomeStyle from "./style";
import Properties from "../Home/Properties";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { getAllProperties, singlePropertyTypes } from "../../utils/types";
import { useEffect, useState } from "react";
import {
  get_all_properties_url,
  get_locations_url,
  property_details_url,
} from "../../utils/api_urls";
import axios from "axios";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import MyMultipleComponent from "../Property/PropertyDetails/MultipleMarkers";
import PropertyDrawer from "./PropertyDrawer";
import FindHomeFilters from "./FindHomeFilters";
import { useSearchContext } from "../../context/SearchContext";
import Skeleton from "react-loading-skeleton";
import { PropertyDetailTypes } from "../Property/types";
import PropertiesSkeleton from "../../components/Skeletons/Properties";
import usePostNoAuthEvent from "../../hooks/usePostEventNoAuth";
import usePostNoAuth from "../../hooks/usePostNoAuth";
import MapIcon from "../../assets/icons/map.svg";
import ListIcon from "../../assets/icons/list.svg";
import { toast } from "react-toastify";
export type mapData = {
  id: number;
  newsFeedId: number;
  latitude: number;
  longitude: number;
};
type mapDataTypes = {
  loading: boolean;
  data: mapData[];
  error: string;
};

const FindHome = () => {
  const [showMap, setShowMap] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const {
    loading: getPropertyLoading,
    data,
    error: getPropertyError,
    apiDataPromise: getPropertyDetailsPromise,
  }: {
    loading: boolean;
    error: string;
    data: unknown;
    apiDataPromise: (url: string, postData: unknown) => Promise<void>;
  } = usePostNoAuthEvent();
  // get locations for map
  const {
    loading: latLngLoading,
    data: mapData,
    error: latLngError,
  }: mapDataTypes = usePostNoAuth(get_locations_url, {
    useCase: "PROPERTY",
    state: "Ontario",
  });

  const {
    loading,
    setLoading,
    error,
    setError,
    propertiesData,
    setPropertiesData,
    propertiesPage,
    setPropertiesPage,
    getFilterPropertiesPromise,
    setSearchCriteria,
    searchCriteria,
  } = useSearchContext();
  const [isShowProperty, setIsShowProperty] = useState(false);
  // properties promise
  const getPropertiesPromise = async () => {
    try {
      setLoading(true);
      const { data } = await axios(get_all_properties_url + propertiesPage);
      if (propertiesPage < 1) {
        setPropertiesData(data.results);
      } else {
        setPropertiesData({
          ...propertiesData,
          properties: [
            ...propertiesData?.properties,
            ...data?.results.properties,
          ],
        });
      }

      setLoadMore(data?.results?.properties.length > 10 && true);
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.responseMessage);
      setLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  useEffect(() => {
    //   // if (!propertiesData.hasOwnProperty("properties")) {
    // getPropertiesPromise();
    // setSearchCriteria([]);
    getFilterPropertiesPromise();
    //   // }
    //   // return () => {
    //   // setPropertiesData({} as getAllProperties);
    //   // };
  }, [propertiesPage]);

  // if (loading && propertiesPage < 1) return <Loader />;
  // if (error) return <NoDataAvailable title={error} />;

  const propertiesMarker =
    loading || error
      ? []
      : propertiesData?.properties.map(
          ({ longitude, latitude, price, propertyId }) => ({
            latitude,
            longitude,
            price,
            propertyId,
          })
        );

  // selected location handler
  const selectedLocationHandler = async (id: number) => {
    setIsShowProperty(true);
    await getPropertyDetailsPromise(property_details_url, {
      propertyId: id,
    });
  };

  console.log({ loading, error, propertiesMarker });

  return (
    <>
      <Helmet>
        <title>Find Home</title>
      </Helmet>
      <FindHomeFilters />
      <FindHomeStyle>
        <div
          className="mt-10 position-relative multiple-marker-parent"
          style={{ display: showMap ? "block" : "none" }}
        >
          <div className="position-absolute w-100 property-drawer">
            <PropertyDrawer
              isShowProperty={isShowProperty}
              setIsShowProperty={setIsShowProperty}
              propertyData={data as PropertyDetailTypes}
              loading={getPropertyLoading}
              error={getPropertyError}
            />
          </div>
          {latLngLoading ? (
            <Skeleton height={440} />
          ) : latLngError ? (
            <NoDataAvailable />
          ) : (
            <MyMultipleComponent
              markers={
                latLngLoading
                  ? []
                  : propertiesMarker?.map(
                      ({ propertyId, latitude, longitude }) => ({
                        propertyId,
                        latitude,
                        longitude,
                      })
                    )
              }
              selectedLocationHandler={selectedLocationHandler}
            />
          )}
        </div>
        <Container>
          {loading ? (
            <div className="properties">
              <PropertiesSkeleton />
            </div>
          ) : error ? (
            <NoDataAvailable title={error} />
          ) : (
            <div style={{ display: showMap ? "none" : "block" }}>
              <Properties
                {...propertiesData}
                loadMore={
                  propertiesData?.properties.length <= 10 ? true : false
                }
              />
            </div>
          )}
        </Container>

        <div style={{ textAlign: "center" }}>
          <button
            style={{
              border: "1px solid black",
              borderRadius: "25px",
              backgroundColor: "#000",
              color: "#fff",
              padding: "10px",
              position: "fixed",
              bottom: "15px",
              zIndex: "2",
              // left: 0,
              // right: 0,
              width: "150px",
              // margin: "auto",
            }}
            onClick={() => {
              setShowMap(!showMap);
            }}
          >
            {showMap ? "Show list" : "Show map"}
            <img
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "5px",
              }}
              src={!showMap ? MapIcon : ListIcon}
            />
          </button>
        </div>
      </FindHomeStyle>
    </>
  );
};

export default FindHome;
