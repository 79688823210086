import Card from "react-bootstrap/Card";
import {
  ImgContainer,
  MiniHeadingSecondary,
  primaryColor2,
  ProfileMiniImg,
  tertiaryGrey20,
} from "../../components/GlobalStyle";
import unLikeIcon from "../../assets/icons/ic_newsfeed_like.svg";
import sent from "../../assets/icons/ic_newsfeed_sent.svg";
import comment from "../../assets/icons/ic_newsfeed_comment.svg";
import save from "../../assets/icons/ic_newsfeed_saved.svg";
import { BgImage, PostStyle } from "../ExploreNearby/style";
import CustomLink from "../../components/CustomLink/Index";
import moreOptionIcon from "../../assets/icons/ic_newsfeed_more.svg";
import AliceCarousel from "react-alice-carousel";
import { NewsFeedRecord } from "../../utils/types";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  follow_url,
  like_url,
  media_base_url,
  post_details_url,
  unfollow_url,
} from "../../utils/api_urls";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import likeIcon from "../../assets/icons/ic_heart_like.svg";
import getTimeAgo from "../../components/GetTimeAgo";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { RootState } from "../../redux/store";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import useDeleteEvent from "../../hooks/useDelete";
import placeholder_round from "../../assets/icons/ic_use_placeholder.svg";
import { addLikeTypes } from "../../hooks/useLike";
import PostPrevBtn from "../../components/Custom/PostPrevBtn";
import PostNextBtn from "../../components/Custom/PostNextBtn";
import { truncateString } from "../../utils/utilities";
import SlidePrevBtn from "../../components/Custom/SlidePrevBtn";
import SlideNextBtn from "../../components/Custom/SlideNextBtn";
import AutoVideoPlayer from "../../components/VideoPlayers/AutoVideoPlay";
import { ca } from "date-fns/esm/locale";
import useFetchById from "../../hooks/useFetchById";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import styled from "styled-components";
import { Container } from "react-bootstrap";

// carosuel responseive
const responsive = {
  0: { items: 1 },
};

type postDataTypes = {
  data: postDetailTypes;
  error: string;
  loading: boolean;
};

const Post = () => {
  const [propertyImgIndex, setPropertyImgIndex] = useState(0);
  const { id } = useParams();
  const {
    loading: postLoading,
    data: postData,
    error,
  }: postDataTypes = useFetchById(post_details_url, id);

  console.log({ id }, "post data id param");

  const {
    description = "",
    address = "",
    contactRequestPermission = false,
    boostPermission = false,
    saveFavourite = false,
    sharingEnabled = false,
    turnOffCommenting = false,
    readComments = false,
    hideLikesAndViewsCounts = false,
    videosURLS = "",
    imagesURLS = [],
    postId = 0,
    newsFeedId = 0,
    createdDateTime = "",

    lastLikeAndComment: {
      lastLikedUserId = 0,
      lastCommentUserId = 0,
      lastCommentUserFirstName = "",
      lastLikedUserLastName = "",
      lastCommentText = "",
      lastLikedUserFirstName = "",
      lastCommentUserProfilePicture = "",
      lastCommentUserLastName = "",
      totalLikes = 0,
      totalComments = 0,
      isLiked = false,
    } = {},
    ownerData: {
      firstName = "",
      lastName = "",
      id: ownerId = 0,
      profilePicture = "",
    } = {},
  } = postData;
  const [followingError, setFollowingError] = useState("");
  const [likesLoading, setLikesLoading] = useState(false);
  const [likesData, setLikesData] = useState(false);
  const [likesError, setLikesError] = useState(false);
  const { newsfeedData, setNewsfeedData } = useNewsfeedContext();
  const navigate = useNavigate();
  const likeToastId = useRef<any>();
  const userData = useAppSelector(
    (state: RootState) => state.loginData.data?.userDetails
  );
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  // login data
  const { data } = useAppSelector((state: RootState) => state.loginData);

  // volume change detector
  const onVolumeChange = (muted: boolean) => {
    console.log("video volume change runs", muted);
    setIsMuted(muted);
  };

  // loginData from redux
  const { data: loginData } = useAppSelector((state) => state.loginData);

  // follow promsie
  const followPromise = async () => {
    try {
      const { data } = await axios.post(
        follow_url,
        {
          followToUserId: ownerId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast(data.responseMessage);
    } catch (err: any) {
      toast(err.response.data.responseMessage);
      // setError(err.response.data.responseMessage);
    }
  };

  // unfollow promise
  const {
    apiDataPromise: unFollowPromise,
    loading,
    data: unFollowResults,
    error: unFollowError,
  } = useDeleteEvent();

  // add likes promise handler
  const addLikesPromiseHandler = async ({
    isLike,
    newsFeedId,
    userId,
  }: addLikeTypes) => {
    console.log({ isLike });
    try {
      setLikesLoading(true);
      const { data: likesData } = await axios.post(
        like_url,
        {
          newsFeedId: newsFeedId.toString(),
          userId: userId.toString(),
          isLike: !isLike,
        },
        {
          headers: {
            Authorization: `Bearer ${data?.jwtDetails.token}`,
          },
        }
      );
      setLikesData(likesData.results);
      setLikesLoading(false);

      likeToastId.current = toast(likesData.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      setLikesError(error.response.data.responseMessage);
      setLikesLoading(false);
      likeToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // old post data
  const oldNewsfeedData = { ...newsfeedData };

  // on video ended
  const onVideoEnd = () => {};

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  if (postLoading) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;

  console.log({ imagesURLS, videosURLS });

  return (
    <Warpper>
      <PostStyle className="post-container">
        <Container>
          <div className="post-owner-section d-flex justify-content-between align-align-items-center">
            <div className="d-flex">
              <ImgContainer
                src={
                  profilePicture ? media_base_url + profilePicture : placeholder
                }
                alt={firstName}
                height="40px"
                width="40px"
                className="cursor-pointer"
                onClick={() => navigate(`/professional/${ownerId}`)}
              />
              <div className="ms-3">
                <div className="d-flex">
                  <p
                    className="name mb-0 cursor-pointer"
                    onClick={() => navigate(`/professional/${ownerId}`)}
                  >
                    {firstName} {lastName}
                  </p>
                </div>
                <p className="time mb-0">
                  {createdDateTime && getTimeAgo(createdDateTime)}
                </p>
              </div>
            </div>
            {/* <img
          className="view-more-icon cursor-pointer align-self-start"
          onClick={() => {
            selectedPostHandler(postData, "post_options");
          }}
          src={moreOptionIcon}
          alt="newFeed"
        /> */}
          </div>

          <Card>
            <div className="position-relative">
              <AliceCarousel
                mouseTracking
                items={
                  !loading &&
                  imagesURLS.filter((item) => item !== "").length > 0
                    ? imagesURLS.map((img, index: number) => {
                        return (
                          <div
                            key={index}
                            style={{
                              backgroundImage: `url(${
                                typeof img === "string"
                                  ? media_base_url + img
                                  : URL.createObjectURL(img)
                              })`,
                              backgroundRepeat: "no-repeat",
                              height: "82vh",
                              backgroundSize: "100%",
                              backgroundPosition: "center",
                              // if cropped is 1/1 then 2/1
                              // if cropped is 16 /9 then 1/1
                              // if cropped is 4/2 then 2/1
                              // aspectRatio: "16/9",
                            }}
                          />
                        );
                      })
                    : videosURLS
                    ? [
                        <video
                          src={
                            typeof videosURLS === "string"
                              ? media_base_url + videosURLS
                              : URL.createObjectURL(videosURLS)
                          }
                          className="media-post-item rounded w-100 position-relative"
                          controls
                        />,
                      ]
                    : []
                }
                responsive={responsive}
                activeIndex={propertyImgIndex}
                controlsStrategy="alternate"
                disableButtonsControls
                disableDotsControls={imagesURLS.length < 2 && videosURLS !== ""}
              />
              {propertyImgIndex > 0 && (
                <SlidePrevBtn
                  clicked={() => setPropertyImgIndex((prev) => prev - 1)}
                />
              )}
              {imagesURLS.length > 1 &&
                propertyImgIndex < imagesURLS.length - 1 && (
                  <SlideNextBtn
                    clicked={() => setPropertyImgIndex((prev) => prev + 1)}
                  />
                )}
            </div>

            <Card.Body
              className="cursor-pointer pb-0 pt-3"
              onClick={() => {
                navigate(`/post/${postId}`);
              }}
            >
              <MiniHeadingSecondary>{description}</MiniHeadingSecondary>
              {/* <Card.Text>
            <p className="sub-title fs-6">{address}</p>
          </Card.Text> */}
            </Card.Body>
            <div className="react d-flex justify-content-between align-items-center mt-1">
              <div className="react-icons mt-xs-2 mt-sm-0 ms-2">
                {/* checking like exist in post */}
                {!hideLikesAndViewsCounts && (
                  <img
                    className="cursor-pointer like-icon"
                    src={isLiked ? likeIcon : unLikeIcon}
                    // onClick={() => {
                    //   setNewsfeedData({
                    //     ...oldNewsfeedData,
                    //     newsFeedRecords: oldNewsfeedData.newsFeedRecords.map(
                    //       (newsfeed) => {
                    //         if (postData?.newsFeedId === newsfeed.newsFeedId) {
                    //           return {
                    //             ...newsfeed,
                    //             lastLikeAndComment: {
                    //               ...newsfeed.lastLikeAndComment,
                    //               isLiked: !newsfeed.lastLikeAndComment.isLiked,
                    //             },
                    //           };
                    //         }
                    //         return newsfeed;
                    //       }
                    //     ),
                    //   });
                    //   addLikesPromiseHandler({
                    //     isLike: isLiked,
                    //     newsFeedId: newsFeedId!,
                    //     userId: ownerId!,
                    //   });
                    // }}
                    alt={isLiked ? "like" : "dislike"}
                  />
                )}
                {/* checking contact us allow or not */}
                {!turnOffCommenting && (
                  <button>
                    <img src={comment} alt="comment" />
                  </button>
                )}
                {sharingEnabled && (
                  <button>
                    <img src={sent} alt="send" />
                  </button>
                )}
              </div>
              <div className="d-flex align-items-center me-2">
                {/* show promotion when boost permission is true or user id is same */}
                {boostPermission && loginData?.userDetails.id === ownerId && (
                  <CustomButton
                    bgcolor="transparent"
                    color={primaryColor2}
                    padding="8px 8px"
                    width="100%"
                    type="submit"
                    title="Promotion"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    textTransform="Captilize"
                  />
                )}
                {/* show contact when contact request is true or user id ot same */}
                {contactRequestPermission &&
                  loginData?.userDetails.id !== ownerId && (
                    <CustomButton
                      bgcolor="transparent"
                      color={primaryColor2}
                      padding="8px 8px"
                      width="100%"
                      type="submit"
                      title="Contact"
                      margin="auto"
                      textTransform="Captilize"
                      fontFamily="EnnVisionsMedium"
                      fontSize="16px"
                      clicked={() => {
                        navigate("/in-person", {
                          state: {
                            newsFeedId,
                            visitType: "IN_PERSON",
                          },
                        });
                      }}
                    />
                  )}
                {saveFavourite && (
                  <span>
                    <img
                      className="cursor-pointer me-2 save-icon"
                      src={save}
                      alt="save"
                    />
                  </span>
                )}
              </div>
            </div>
            {/* checking like exist in post */}
            {/*] <div className="caption-section px-2 mt-1">
          {/* {lastLikedUserId && (
            <div
              className="d-flex align-items-center"
              onClick={() => {
                selectedPostHandler(postData, "like");
              }}
            >
              <p className="mb-2 ms-2 cursor-pointer liked-by">
                Liked by
                <strong className="ms-2">
                  {lastLikedUserFirstName} {lastLikedUserLastName}
                </strong>
                {totalLikes > 1 && (
                  <span className="ms-2">
                    and
                    <strong className="ms-2">{totalLikes} </strong>
                    others
                  </span>
                )}
              </p>
            </div>
          )} 
        {/* {lastCommentUserId && (
            <p className="post-caption ms-1 m-0">
              {lastCommentUserProfilePicture ? (
                <ProfileMiniImg
                  width="32px"
                  height="32px"
                  borderRadius="50%"
                  src={media_base_url + lastCommentUserProfilePicture}
                  alt={lastCommentUserFirstName}
                />
              ) : (
                <img
                  width={32}
                  height={32}
                  src={placeholder_round}
                  alt={lastCommentUserFirstName}
                />
              )}

              <strong className="ms-2">
                {lastCommentUserFirstName} {lastCommentUserLastName}
              </strong>
              <span className="ms-2">
                {truncateString(lastCommentText, 220)}
              </span>
            </p>
          )} */}
            {/* checking contact exist in post */}
            {/* {lastCommentUserId && (
            <button
              disabled={!readComments}
              className="p-1 rounded ms-1 mt-3 mb-3"
              onClick={() => selectedPostHandler(postData, "comment")}
            >
              <CustomLink
                fontSize="14px"
                color={tertiaryGrey20}
                link="#"
                title={`View all ${totalComments} Comments`}
                textDecoration="none"
              />
            </button>
          )} */}
            {/* </div>  */}
          </Card>
        </Container>
      </PostStyle>
    </Warpper>
  );
};

export default Post;

const Warpper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 14px;
  .post-container {
    width: 600px;

    video {
      height: 380px;
    }
  }
`;
// post detail types

export interface postDetailTypes {
  postId: number;
  newsFeedId: number;
  description: string;
  postLayout: number;
  imagesURLS: string[];
  videosURLS: string;
  longitude: number;
  latitude: number;
  address: string;
  city: string;
  state: string;
  country: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  isFavourite: any;
  turnOffCommenting: boolean;
  createdDateTime: string;
  updatedDateTime: string;
  lastLikeAndComment: LastLikeAndComment;
  ownerData: OwnerData;
  isEdited: boolean;
}

export interface LastLikeAndComment {
  isLiked: boolean;
  totalLikes: number;
  lastLikedUserId: any;
  lastLikedUserFirstName: any;
  lastLikedUserLastName: any;
  totalComments: number;
  lastCommentUserId: any;
  lastCommentUserFirstName: any;
  lastCommentUserLastName: any;
  lastCommentText: any;
  lastCommentUserProfilePicture: any;
}

export interface OwnerData {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  id: number;
}
