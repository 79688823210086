import {
  useState,
  createContext,
  Dispatch,
  useContext,
  SetStateAction,
  useEffect,
} from "react";
import { findProfessionalTypes, getAllProperties } from "../utils/types";
import axios from "axios";
import {
  search_professionals_url,
  search_properties_url,
} from "../utils/api_urls";
import { bathroomFilterItems } from "../screens/FindHome/BathroomFilter";
import { bedroomFilterItems } from "../screens/FindHome/BedroomFilter";

interface NavItemEventKey {
  id: number;
  keyName: string;
}
export interface ProfessionNavItemEventKey {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
}

type searchContextTypes = {
  propertyType: string;
  setPropertyType: Dispatch<SetStateAction<string>>;
  propertyTypeNumber: number;
  setPropertyTypeNumber: Dispatch<SetStateAction<number>>;
  showPropertyTypes: boolean;
  setShowPropertyTypes: Dispatch<SetStateAction<boolean>>;
  showPriceRange: boolean;
  setShowPriceRange: Dispatch<SetStateAction<boolean>>;
  showBathrooms: boolean;
  setShowBathrooms: Dispatch<SetStateAction<boolean>>;
  showBedrooms: boolean;
  setShowBedrooms: Dispatch<SetStateAction<boolean>>;
  showAreas: boolean;
  setShowAreas: Dispatch<SetStateAction<boolean>>;
  showProfessions: boolean;
  setShowProfessions: Dispatch<SetStateAction<boolean>>;
  showMoreFilters: boolean;
  setShowMoreFilters: Dispatch<SetStateAction<boolean>>;
  purpose: string;
  setPurpose: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  propertiesData: getAllProperties;
  setPropertiesData: Dispatch<SetStateAction<getAllProperties>>;
  getFilterPropertiesPromise: () => Promise<void>;
  searchCriteria: {
    filterKey: string;
    value: unknown;
    operation: string;
  }[];
  setSearchCriteria: Dispatch<
    SetStateAction<
      {
        filterKey: string;
        value: unknown;
        operation: string;
      }[]
    >
  >;
  professionalSearchCriteria: {
    filterKey: string;
    value: unknown;
    operation: string;
  }[];
  setProfessionalSearchCriteria: Dispatch<
    SetStateAction<
      {
        filterKey: string;
        value: unknown;
        operation: string;
      }[]
    >
  >;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  profession: string;
  setProfession: Dispatch<SetStateAction<string>>;
  selectedSubTypes: NavItemEventKey[];
  setSelectedSubTypes: Dispatch<SetStateAction<NavItemEventKey[]>>;
  selectedProfessions: ProfessionNavItemEventKey[];
  setSelectedProfessions: Dispatch<SetStateAction<ProfessionNavItemEventKey[]>>;
  setFilterCriteria: (filterKey: string, filterValue: unknown) => void;
  setProfessionalFilterCriteria: (
    filterKey: string,
    filterValue: unknown
  ) => void;
  propertiesPage: number;
  setPropertiesPage: Dispatch<SetStateAction<number>>;
  professionalAddress: string;
  setProfessionalAddress: Dispatch<SetStateAction<string>>;
  professionalsLoading: boolean;
  setProfessionalsLoading: Dispatch<SetStateAction<boolean>>;
  professionals: findProfessionalTypes;
  setProfessionals: Dispatch<SetStateAction<findProfessionalTypes>>;
  professionalError: string;
  setProfessionalError: Dispatch<SetStateAction<string>>;
  priceMinRange: string | number;
  setPriceMinRange: Dispatch<SetStateAction<string | number>>;
  priceMaxRange: string | number;
  setPriceMaxRange: Dispatch<SetStateAction<string | number>>;
  bathroomItems: {
    isSelect: boolean;
    beds: string;
  }[];
  setBathroomItems: Dispatch<
    SetStateAction<
      {
        isSelect: boolean;
        beds: string;
      }[]
    >
  >;
  bedroomItems: {
    isSelect: boolean;
    beds: string;
  }[];
  setBedroomItems: Dispatch<
    SetStateAction<
      {
        isSelect: boolean;
        beds: string;
      }[]
    >
  >;
  areaMinRange: string | number;
  setAreaMinRange: Dispatch<SetStateAction<string | number>>;
  areaMaxRange: string | number;
  setAreaMaxRange: Dispatch<SetStateAction<string | number>>;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const searchContext = createContext({} as searchContextTypes);

const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const [propertyTypeNumber, setPropertyTypeNumber] = useState(0);
  const [propertyType, setPropertyType] = useState("Residential");
  const [showPropertyTypes, setShowPropertyTypes] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [showBathrooms, setShowBathrooms] = useState(false);
  const [showBedrooms, setShowBedrooms] = useState(false);
  const [showAreas, setShowAreas] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [showProfessions, setShowProfessions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [professionalsLoading, setProfessionalsLoading] = useState(true);
  const [professionals, setProfessionals] = useState(
    {} as findProfessionalTypes
  );
  const [purpose, setPurpose] = useState("Buy");
  const [address, setAddress] = useState("");
  const [propertiesData, setPropertiesData] = useState({} as getAllProperties);
  const [error, setError] = useState("");
  const [searchCriteria, setSearchCriteria] = useState<
    {
      filterKey: string;
      value: unknown;
      operation: string;
    }[]
  >([]);
  const [professionalSearchCriteria, setProfessionalSearchCriteria] = useState<
    {
      filterKey: string;
      value: unknown;
      operation: string;
    }[]
  >([]);
  // professional name
  const [name, setName] = useState("");
  const [profession, setProfession] = useState("");
  const [propertiesPage, setPropertiesPage] = useState(0);
  //   property sub type
  const [selectedSubTypes, setSelectedSubTypes] = useState<NavItemEventKey[]>(
    []
  );
  const [selectedProfessions, setSelectedProfessions] = useState<
    ProfessionNavItemEventKey[]
  >([]);
  // professional addres
  const [professionalAddress, setProfessionalAddress] = useState("");
  const [professionalError, setProfessionalError] = useState("");
  // price range
  const [priceMinRange, setPriceMinRange] = useState<number | string>(0);
  const [priceMaxRange, setPriceMaxRange] = useState<number | string>(0);
  // bathroom items
  const [bathroomItems, setBathroomItems] = useState(bathroomFilterItems);
  // bedroom items
  const [bedroomItems, setBedroomItems] = useState(bedroomFilterItems);
  // area ranges
  const [areaMinRange, setAreaMinRange] = useState<number | string>(0);
  const [areaMaxRange, setAreaMaxRange] = useState<number | string>(0);
  // select all in property type filter
  const [active, setActive] = useState(false);

  // properties promise
  const getFilterPropertiesPromise = async () => {
    try {
      setLoading(true);
      setError("");
      const { data } = await axios.post(
        search_properties_url + propertiesPage,
        { searchCriteria, searchOn: "PROPERTY" }
      );
      console.table(data?.results);
      setPropertiesData({
        ...propertiesData,
        properties: data?.results.propertyList,
      });
      console.log({ data });
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.responseMessage);
      setLoading(false);
      
    }
  };

  // professionals promise
  const getFilterProfessionalsPromise = async () => {
    try {
      setProfessionalsLoading(true);
      const { data } = await axios.post(search_professionals_url + 0, {
        searchCriteria: professionalSearchCriteria,
        searchOn: "PROFESSIONAL",
      });
      console.log(data.results, "result of search creteria");
      setProfessionalError("");
      setProfessionals({
        ...professionals,
        professionalRecords: data?.results?.professionalList || [],
        currentPage: data.results.currentPage,
        totalItems: data.results.totalItems,
        totalPages: data.results.totalPages,
      });
      console.log({ data });
      setProfessionalsLoading(false);
    } catch (error: any) {
      console.log(">>searchError", error?.response?.data?.responseMessage);
      console.log(error, "error in professionals data");
      setProfessionalError(error?.response?.data?.responseMessage);
      setProfessionalsLoading(false);
    }
  };

  useEffect(() => {
    //getFilterProfessionalsPromise();
  }, [professionalSearchCriteria]);

  useEffect(() => {
    // console.log(">> search called");
    // if (searchCriteria.length > 0) {
   // getFilterPropertiesPromise();
    // }
  }, [searchCriteria]);

  // Function to update an item by ID
  const setFilterCriteria = (filterKey: string, filterValue: unknown) => {
    setSearchCriteria((prevItems) => {
      const index = prevItems.findIndex((item) => item.filterKey === filterKey);
      if (index === -1) {
        return [
          ...prevItems,
          {
            filterKey,
            value: filterValue,
            operation: "cn",
          },
        ];
      } else {
        const updatedItems = [...prevItems];
        updatedItems[index] = {
          filterKey,
          value: filterValue,
          operation: "cn",
        };
        return updatedItems;
      }
    });
  };

  console.log({ professionalSearchCriteria });
  // Function to update an item by ID
  const setProfessionalFilterCriteria = (
    filterKey: string,
    filterValue: unknown
  ) => {
    console.log({ filterValue });
    setProfessionalSearchCriteria((prevItems) => {
      const index = prevItems.findIndex((item) => item.filterKey === filterKey);
      if (index === -1) {
        return [
          ...prevItems,
          {
            filterKey,
            value: filterValue,
            operation: "eq",
          },
        ];
      } else {
        const updatedItems = [...prevItems];
        updatedItems[index] = {
          filterKey,
          value: filterValue,
          operation: "eq",
        };
        return updatedItems;
      }
    });
  };

  return (
    <searchContext.Provider
      value={{
        purpose,
        setPurpose,
        address,
        setAddress,
        propertyType,
        setPropertyType,
        propertyTypeNumber,
        setPropertyTypeNumber,
        showPropertyTypes,
        setShowPropertyTypes,
        showPriceRange,
        setShowPriceRange,
        showBathrooms,
        setShowBathrooms,
        showBedrooms,
        setShowBedrooms,
        showAreas,
        setShowAreas,
        showMoreFilters,
        setShowMoreFilters,
        loading,
        setLoading,
        error,
        setError,
        getFilterPropertiesPromise,
        propertiesData,
        setPropertiesData,
        searchCriteria,
        setSearchCriteria,
        selectedSubTypes,
        setSelectedSubTypes,
        setFilterCriteria,
        propertiesPage,
        setPropertiesPage,
        professionalSearchCriteria,
        setProfessionalSearchCriteria,
        name,
        profession,
        setName,
        setProfession,
        selectedProfessions,
        setSelectedProfessions,
        setShowProfessions,
        showProfessions,
        professionalAddress,
        setProfessionalAddress,
        setProfessionalFilterCriteria,
        professionals,
        setProfessionals,
        professionalsLoading,
        setProfessionalsLoading,
        professionalError,
        setProfessionalError,
        priceMinRange,
        setPriceMinRange,
        priceMaxRange,
        setPriceMaxRange,
        bathroomItems,
        setBathroomItems,
        bedroomItems,
        setBedroomItems,
        areaMaxRange,
        areaMinRange,
        setAreaMaxRange,
        setAreaMinRange,
        active,
        setActive,
      }}
    >
      {children}
    </searchContext.Provider>
  );
};

// make sure use
const useSearchContext = () => {
  return useContext(searchContext);
};

export { useSearchContext, SearchProvider };
