// import { useState } from "react";
// import styled from "styled-components";
// import CustomDate from "../Date/Date";
// import CustomTime from "../Time/Time";
// import {
//   fontFamilyMedium,
//   primaryColor2,
//   primaryRed,
// } from "../../components/GlobalStyle";
// import { Input } from "antd";
// import CustomButton from "../../components/CustomButton/CustomButton";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { useAppSelector } from "../../app/hooks";
// import { authorizationToken, contact_request_url } from "../../utils/api_urls";
// import { toast } from "react-toastify";
// import axios from "axios";

// const { TextArea } = Input;

// type bookingStateTypes = {
//   newsFeedId: number;
//   visitType: string;
// };

// const InPerson = () => {
//   const { state: locationData }: { state: bookingStateTypes } = useLocation();
//   const navigate = useNavigate();

//   const [date, setDate] = useState<string | null>("");
//   const [description, setDescription] = useState("");
//   // Create a dayjs instance representing the current time as the initial value
//   const [selectedTime, setSelectedTime] = useState<string>("");
//   const [isLoading, setIsLoading] = useState(false);
//   const { data: loginData } = useAppSelector((state) => state.loginData);
//   //   request tour promise
//   const requestTourPromise = async () => {
//     if (!date || !description || !selectedTime) {
//       return;
//     }
//     try {
//       setIsLoading(true);
//       const { data } = await axios.post(
//         contact_request_url,
//         {
//           newsFeedId: locationData.newsFeedId,
//           visitType: locationData.visitType,
//           description,
//           visitDate: date,
//           visitStartTime: selectedTime,
//           visitEndTime: null,
//         },
//         {
//           headers: {
//             ...authorizationToken(loginData!),
//           },
//         }
//       );
//       setIsLoading(false);
//       navigate(-1);
//     } catch (error: any) {
//       console.log(error);
//       setIsLoading(false);
//       toast(error.response.data.responseMessage, {
//         type: "error",
//       });
//     }
//   };
//   console.log({ date }, "date in on change");

//   console.log({ date, selectedTime });

//   return (
//     <Wrapper>
//       <article>
//         <form></form>
//         <label htmlFor="date">Select Date</label>
//         <div className="mt-2">
//           <CustomDate date={date} setDate={setDate} />
//           {!date && <p className="error">Date is Required</p>}
//         </div>
//         {/* {date && <p>Selected date: {date.format("DD MMM YYYY")}</p>} */}
//         <div className="mt-2">
//           <label htmlFor="time">Select Time</label>
//           <div className="mt-2">
//             <CustomTime time={selectedTime} setTime={setSelectedTime} />
//           </div>
//           {!selectedTime && <p className="error">Time is Required</p>}
//         </div>
//         <div className="mt-2">
//           <label htmlFor="description">Description</label>
//           <div className="mt-2">
//             <TextArea
//               maxLength={100}
//               style={{ height: 240, marginBottom: 16 }}
//               onChange={(event) => setDescription(event.target.value)}
//               value={description}
//               placeholder="Describe the reason of schedule"
//             />
//             {!description && <p className="error">Description is Required</p>}
//           </div>
//         </div>
//         <CustomButton
//           bgcolor={primaryColor2}
//           color="white"
//           padding="8px"
//           width="100%"
//           type="submit"
//           title="Save"
//           margin="auto"
//           loading={isLoading}
//           clicked={requestTourPromise}
//         />
//       </article>
//     </Wrapper>
//   );
// };
// const Wrapper = styled.section`
//   background: #ffffff;
//   border-radius: 3px;
//   max-width: 600px;
//   margin: 0 auto;
//   padding: 10px;
//   margin-top: 20px;
//   .error {
//     margin-top: 10px;
//     color: ${primaryRed};
//   }
//   label {
//     font-family: ${fontFamilyMedium};
//     font-size: 14px;
//   }
//   input {
//     padding: 4px;
//   }
//   .selected_Date {
//     text-align: left;
//     font-size: 14px;
//     font-family: "EnnVisions";
//     letter-spacing: 0px;
//     color: #000000;
//   }
// `;
// export default InPerson;

// ******************Formkik***********************

import { useState } from "react";
import styled from "styled-components";
import CustomDate from "../Date/Date";
import CustomTime from "../Time/Time";
import {
  fontFamilyMedium,
  primaryColor2,
  primaryRed,
} from "../../components/GlobalStyle";
import { Input } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { authorizationToken, contact_request_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import axios from "axios";

const { TextArea } = Input;

type bookingStateTypes = {
  newsFeedId: number;
  visitType: string;
};

const InPerson = () => {
  // const { state: locationData }: { state: bookingStateTypes } = useLocation();
  const location = useLocation();
  const { id, visitType } = location.state;
  const navigate = useNavigate();

  const [date, setDate] = useState<string | null>("");
  const [description, setDescription] = useState("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const requestTourPromise = async () => {
    if (!date || !description || !selectedTime) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        contact_request_url,
        {
          newsFeedId: id,
          visitType: visitType,
          description,
          visitDate: date,
          visitStartTime: selectedTime,
          visitEndTime: null,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast(data?.responseMessage, {
        type: "success",
      });
      setIsLoading(false);
      navigate("/find-home");
    } catch (error: any) {
      setIsLoading(false);
      toast(error?.response?.data?.responseMessage, {
        type: "error",
      });
    }
  };

  const handleDateChange = (newDate: string | null) => {
    setDate(newDate);
    setDateError(false);
  };

  const handleTimeChange = (newTime: string) => {
    setSelectedTime(newTime);
    setTimeError(false);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
    setDescriptionError(false);
  };

  const handleBlur = (field: string) => {
    switch (field) {
      case "date":
        setDateError(!date);
        break;
      case "time":
        setTimeError(!selectedTime);
        break;
      case "description":
        setDescriptionError(!description);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    requestTourPromise();
  };
  return (
    <Wrapper>
      <article>
        <form onSubmit={handleSubmit}>
          <label htmlFor="date">Select Date</label>
          <div className="mt-2">
            <CustomDate
              date={date}
              setDate={handleDateChange}
              onBlur={() => handleBlur("date")}
            />
            {dateError && <p className="error">Date is Required</p>}
          </div>

          <div className="mt-2">
            <label htmlFor="time">Select Time</label>
            <div className="mt-2">
              <CustomTime
                time={selectedTime}
                setTime={handleTimeChange}
                onBlur={() => handleBlur("time")}
              />
            </div>
            {timeError && <p className="error">Time is Required</p>}
          </div>

          <div className="mt-2">
            <label htmlFor="description">Description</label>
            <div className="mt-2">
              <TextArea
                maxLength={100}
                style={{ height: 240, marginBottom: 16 }}
                onChange={handleDescriptionChange}
                onBlur={() => handleBlur("description")}
                value={description}
                placeholder="Describe the reason for the schedule"
              />
              {descriptionError && (
                <p className="error">Description is Required</p>
              )}
            </div>
          </div>

          <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="8px"
            width="100%"
            type="submit"
            title="Save"
            margin="auto"
            loading={isLoading}
          />
        </form>
      </article>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: #ffffff;
  border-radius: 3px;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 20px;
  .error {
    margin-top: 10px;
    color: ${primaryRed};
  }
  label {
    font-family: ${fontFamilyMedium};
    font-size: 14px;
  }
  input {
    padding: 4px;
  }
  .selected_Date {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisions";
    letter-spacing: 0px;
    color: #000000;
  }
`;
export default InPerson;
