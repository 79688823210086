import placeholder from "../../assets/icons/ic_use_placeholder.svg";
import soldPropertyIcon from "../../assets/icons/ic_professional_post.svg";
import person from "../../assets/icons/ic_professional_meet_peron.svg";
import UserProfileBoxStyle from "./style";
import { Col, Row } from "react-bootstrap";
import { userProfileTypes } from "../../screens/User/types";
import { media_base_url } from "../../utils/api_urls";
import Loader from "../Loader/Loader";

type UserProfileBoxProps = {
  userData: userProfileTypes;
  profileImage?: File | null;
  setProfileImage?: any;
  profileLoading: boolean;
};

const UserProfileBox: React.FC<UserProfileBoxProps> = ({
  userData,
  profileImage,
  setProfileImage,
  profileLoading,
}) => {
  const {
    firstName,
    lastName,
    userName,
    totalProperties,
    totalSoldProperties,
    countryCode,
    phoneNumber,
    profilePictureURL,
    totalFollowers,
    totalFollowing,
  } = userData;
  return (
    <UserProfileBoxStyle>
      <div className="owner-details">
        <Row>
          <Col md={6} lg={8}>
            <div className="d-flex align-items-center flex-wrap">
              <label className="label">
                <input
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let files = event.target.files;
                    if (files) {
                      setProfileImage(files[0]);
                    }
                  }}
                />
                <span>
                  {profileLoading ? (
                    <Loader />
                  ) : profileImage ? (
                    <div
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(
                          profileImage
                        )})`,
                        height: "80px",
                        width: "80px",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        border: "1px solid #9fa1a0",
                      }}
                    />
                  ) : (
                    <img
                      src={
                        profilePictureURL
                          ? media_base_url + profilePictureURL
                          : placeholder
                      }
                      alt={firstName}
                      className="profile-img"
                    />
                  )}
                </span>
              </label>

              <div className="bio ms-3 mt-2 mt-sm-0">
                <h6>
                  {firstName} {lastName}
                </h6>
                <p className="branch mt-2 mb-2">{userName}</p>
                <p className="location mb-0">
                  {countryCode}
                  {phoneNumber}
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4} className="mt-3 mt-md-0">
            <div className="sold-properties">
              <Row>
                <Col xs={6} className="mt-md-0 p-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowers}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Followers
                      </p>
                    </div>
                  </div>
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="buildings"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalProperties}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Available
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="p-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowing}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Following
                    </p>
                  </div>

                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="building"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalSoldProperties}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Sold
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </UserProfileBoxStyle>
  );
};

export default UserProfileBox;
