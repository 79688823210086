import { prRecordTypes } from "../../utils/types";
import { media_base_url } from "../../utils/api_urls";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
type professionalProps = {} & prRecordTypes;
const Professional: React.FC<professionalProps> = ({
  profilePictureURL,
  firstName,
  lastName,
  userId,
  professionTypeId,
}) => {
  const professions = useAppSelector((state) => state.appData.data.professions);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="professional-container cursor-pointer"
        style={{
          backgroundImage: `url(${
            profilePictureURL ? media_base_url + profilePictureURL : placeholder
          })`,
          backgroundRepeat: "no-repeat",
          position: "relative",
          height: "240px",
          backgroundSize: "cover",
          borderRadius: "5px",
          backgroundPosition: "center",
        }}
        onClick={() => navigate(`/professional/${userId}`)}
      />
      <div className="d-flex justify-content-center">
        <div className="detail-text">
          <h6 className="name">
            {firstName} {lastName}
          </h6>
          {/* <p className="profession">{emailAddress}</p> */}
          <p className="profession">
            {professions.find(({ id }) => id === professionTypeId) &&
              professions.find(({ id }) => id === professionTypeId)?.name}
          </p>
          {/* <div className="contact-btns d-flex justify-content-center mt-3">
          <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="6px 8px"
            width="130px"
            type="submit"
            title="Chat"
            margin="auto"
            icon={<img className="me-2" src={chat} alt="chat" />}
            fontSize="16px"
            border="none"
            // clicked={() => editHandler(content)}
          />
          <div className="d-flex ms-2">
            <CustomButton
              bgcolor={greenishColor}
              color="white"
              padding="6px 8px"
              width="130px"
              type="submit"
              title="Call"
              margin="auto"
              fontSize="16px"
              border="none"
              icon={
                <img className="me-2" src={phone} alt="phone" />
              }
              // clicked={() => editHandler(content)}
            />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Professional;
