import { Form } from "react-bootstrap";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { primaryRed } from "../GlobalStyle";
import Wrapper from "./style";
import { useState } from "react";
import { authorizationToken, hide_post_url } from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useGlobalContext } from "../../context/context";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";

const OtherPostOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setShowSocialOptions, setShowPostOptions, selectedPost } =
    useNewsfeedContext();
  const { setCounter } = useGlobalContext();
  const navigate = useNavigate();
  const copyToClipboard = useCopyToClipboard();

  // hide post promise
  const hidePropertyPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        hide_post_url,
        {
          hideType: "POST",
          newsFeedId: selectedPost.newsFeedId,
          hideToId: selectedPost.postId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setCounter((prev) => prev + 1);
        setIsModalVisible(false);
        setShowPostOptions(false);
        navigate("/explore-nearby");
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      setShowPostOptions(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  const postOptions = [
    {
      title: "Copy link to Share this Post",
      doTask: () => {
        copyToClipboard(`post/${selectedPost.postId}`);
        setShowPostOptions(false);
      },
    },
  ];
  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Property Hide"
          description="Property successfully hidden from your timeline."
          doTask={() => {
            setShowPostOptions(false);
            setCounter((prev) => prev + 1);

            navigate("/explore-nearby");
          }}
        />
      </CustomModal>
      <ul>
        <li
          className="title"
          onClick={() => {
            navigate("/post-report");
            setShowPostOptions(false);
          }}
          style={{ color: primaryRed, fontWeight: "500" }}
        >
          Report Post
        </li>
        <li
          className="title"
          onClick={isLoading ? () => {} : hidePropertyPromise}
          style={{ color: primaryRed, fontWeight: "500" }}
        >
          {isLoading ? "Report Hiding..." : "Hide Post"}
        </li>
        {/* <li className="d-flex justify-content-between w-100">
          <label htmlFor="custom-switch">Turn comment off for this Post</label>
          <Form.Check
            onChange={(event) => console.log(event.target.checked)}
            type="switch"
            id="custom-switch"
          />
        </li> */}
        {postOptions.map(({ title, doTask }, index) => {
          return (
            <li key={index} onClick={doTask}>
              {title}
            </li>
          );
        })}
        <li
          onClick={() => {
            setShowSocialOptions(true);
            setShowPostOptions(false);
          }}
        >
          Share on other media
        </li>
      </ul>
    </Wrapper>
  );
};

export default OtherPostOptions;
