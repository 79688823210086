import { Col, Container, Row } from "react-bootstrap";
import {
  SecondaryHeading,
  TertiraryHeadingMini,
} from "../../components/GlobalStyle";
import { WhoWeAreStyled } from "./style";
import whoWeAreImg from "../../assets/images/ic_who_we_are.png";
import RedBlackUnderline from "../../components/RedBlackUnderline/RedBlackUnderline";
import useScreenTranslation from "../../hooks/useScreenTranslation";

const WhoWeAre = () => {
  const { getLabelByKey } = useScreenTranslation("whoWeAre");
  return (
    <WhoWeAreStyled>
      <SecondaryHeading className="text-center">
        {getLabelByKey("title")}
    
      </SecondaryHeading>
      {/* <TertiraryHeadingMini className="text-center">
        Know More About Us
      </TertiraryHeadingMini> */}
      <RedBlackUnderline />
      <Container>
        <Row className="inner-container">
          <Col md={6}>
            <img src={whoWeAreImg} alt="who-we-are" />
          </Col>
          <Col md={6} className="mt-0 mt-md-0">
            <p>
            {getLabelByKey('text1')}
              <br/>
              <br/>
              {getLabelByKey('text2')}
            </p>
            <p>
             {getLabelByKey('text3')}
            </p>
          </Col>
        </Row>
      </Container>
    </WhoWeAreStyled>
  );
};

export default WhoWeAre;
