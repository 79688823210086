import { ErrorMessage, Formik } from "formik";
import logo from "../../../assets/icons/ic_logo.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { ImgContainer, primaryColor2 } from "../../../components/GlobalStyle";
import ForgetGenerateOtpStyle from "./style";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPhoneInput from "../../../components/CustomInputNumber/CustomPhoneInput";
import { useAppSelector } from "../../../app/hooks";
import Errormsg from "../../../components/ErrorMessage";
import { Form } from "antd";
import axios from "axios";
import {
  generate_otp_url,
  useCaseForgetPassowrd,
} from "../../../utils/api_urls";
import { useRef, useState } from "react";
import { errorTypes } from "../../../utils/types";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../../context/context";
import Head from "../../../components/Head/Head";

type registerPhoneTypes = {
  phoneNumber: string;
};

const ForgetGenerateOtp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log({ state });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toastId = useRef<any>(null);
  const { setUserPhoneNumber } = useGlobalContext();

  // get Validations from redux appData
  const {
    countryName: {
      results: {
        countryCode,
        countryFlagURL,
        examplePhoneNumber,
        name,
        phoneNumberLength,
      },
    },
    validations,
  } = useAppSelector((state) => state.appData.data);

  if (!state) {
    navigate("/login");
    return null;
  }

  // user validations
  const phoneNumber = validations[15];
  // user regExpressions
  const phoneNumberReg = new RegExp(phoneNumber.pattern);

  // initial values
  const initialValues: registerPhoneTypes = {
    phoneNumber: "",
  };
  // validation schema
  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(phoneNumber.notBlankMsg)
      .matches(phoneNumberReg, phoneNumber.patternMsg),
  });

  // register phone handler
  const handleSubmit = async (values: registerPhoneTypes) => {
    setUserPhoneNumber(values.phoneNumber.toString());
    console.log(values.phoneNumber, "phone number");
    const phoneData = {
      phoneNumber: values.phoneNumber.toString(),
      useCase: state.useCase,
    };
    try {
      setLoading(true);
      setError("");
      const { data } = await axios.post(generate_otp_url, phoneData);
      if (data.responseCode === "500") {
        toastId.current = toast(data.responseMessage, {
          type: "error",
          autoClose: 1000,
        });
        setLoading(false);
        return;
      }
      toastId.current = toast(data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      setLoading(false);
      navigate("/forget-password/verify-otp", {
        state: {
          useCase: state.useCase,
        },
      });
      console.log({ data });
    } catch (error: any) {
      toastId.current = toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setLoading(false);
      console.log({ error }, "error in reset pas");
      setError(error.response.data.responseMessage);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  return (
    <>
      <Head title="generate-otp" />
      <ForgetGenerateOtpStyle>
        <div className="inner-container">
          <ImgContainer src={logo} alt="logo" height="50px" width="164px" />
          <p className="message">
            {state.useCase === "FORGETPASSWORD"
              ? "Enter your registered mobile number to receive a verification code and securely reset your account password"
              : "Enter your registered mobile number to receive a verification code and Retrieve the username"}
          </p>
          <div className="mt-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => {
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <div className="mt-2">
                      <CustomPhoneInput
                        countryNumber={countryCode}
                        placeholder={examplePhoneNumber}
                        phoneLength={10}
                        countryFlag={countryFlagURL}
                        phoneValueHandler={(value: number | string) =>
                          formik.setFieldValue("phoneNumber", value)
                        }
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        countryName={name}
                      />
                      <div className="mt-2">
                        <ErrorMessage name="phoneNumber" component={Errormsg} />
                      </div>
                      {error && <p className="text-danger">{error}</p>}
                    </div>
                    <div className="mt-3">
                      <CustomButton
                        bgcolor={primaryColor2}
                        color="white"
                        padding="8px 8px"
                        width="100%"
                        type="submit"
                        title="SUBMIT"
                        margin="auto"
                        fontSize="16px"
                        loading={loading}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </ForgetGenerateOtpStyle>
    </>
  );
};

export default ForgetGenerateOtp;
