import styled from "styled-components";
import in_person from "../../assets/icons/ic_professional_meet_peron.svg";
import phoneIcon from "../../assets/icons/ic_phone.svg";
import virtual from "../../assets/icons/ic_professional_meet_virtual.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  fontFamilyMedium,
  mediaDeviceMax,
  primaryColor2,
  secondaryDark4,
  tertiaryBlue,
  tertiaryBlue1,
} from "../../components/GlobalStyle";
import { Button, Col, Row } from "react-bootstrap";
type requestTourProps = {
  newsFeedId: number;
  propertyContactPersonNumber: string | number | undefined;
};

const RequestTour: React.FC<requestTourProps> = ({
  newsFeedId,
  propertyContactPersonNumber,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h6 className="heading">Request a Home Tour</h6>
      <div className="inner-section">
        <article className="tours d-flex">
          <Button className="d-flex justify-content-center align-items-center gap-1 tour-btn1 btn w-100">
            <span>
              <img src={in_person} alt="person" />
            </span>
            <span
              className="mt-1 ms-2"
              onClick={() =>
                navigate("/in-person", {
                  state: {
                    id,
                    visitType: "IN_PERSON",
                  },
                })
              }
            >
              Visit Request
            </span>
          </Button>
          {/* <button
            className="d-flex justify-content-center align-items-center gap-1 tour-btn2 btn w-100"
            onClick={() => navigate("/virtual")}
          >
            <span>
              <img src={virtual} alt="virtual" />
            </span>
            <span className="mt-1 ms-2">Virtual</span>
          </button> */}
        </article>
        {/* <Row>
          <Col md={6}>
            <CustomDate date={date} setDate={setDate} />
            {date && <p>Selected date: {date.format("DD MMM YYYY")}</p>}
          </Col>
          <Col md={6}>
            <CustomTime time={selectedTime} setTime={setSelectedTime} />
          </Col>
        </Row> */}
        <div className="mt-3">
          {/* <CustomButton
            bgcolor={primaryColor2}
            color="white"
            padding="8px"
            width="100%"
            type="submit"
            title="Request for tour"
            margin="auto"
          /> */}
          <p className="cancal-payment">
            its free no obligation -cancel anytime
          </p>
        </div>

        <div className="phone-number d-flex flex-column align-items-start">
          <h6 className="title">Contact Info</h6>
          <div className="number d-flex align-items-center">
            <p className="subtitle mb-0 me-2">Phone Number: </p>
            <p className="quantity mb-0">{propertyContactPersonNumber}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .heading {
    font-family: ${fontFamilyMedium};
  }
  @media screen and (max-width: 1390px) {
    width: 100%;
  }
  .inner-section {
    border-radius: 10px;
    transition: all 0.4s;
    background-color: white;
    padding: 10px;
    border: 1px solid #e9e9e9;
    @media screen and (max-width: 1390px) {
      width: 440px;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    .ant-input-textarea {
      margin-bottom: 24px;
      height: 230px;
    }

    input {
      padding: 6px;
    }
    .cancal-payment {
      margin-top: 7px;
    }
    .tours {
      padding: 10px;
      border-radius: inherit;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      /* padding-bottom: 20px; */
      width: 400px;

      @media screen and (max-width: 480px) {
        width: 100%;
        grid-template-columns: 100%;
      }
    }
    .btn {
      text-transform: capitalize;
      outline: 1px solid #e6e4e4;
      border: 1px solid ${tertiaryBlue};
      border-radius: 4px;
      padding: 5px;
      background-color: white;
      transition: all 0.4s;
    }
    .tour-btn1 {
      background-color: transparent;
      color: ${tertiaryBlue};
      &:hover,
      :focus {
        background-color: #fff;
        color: ${tertiaryBlue1};
      }
      &:active {
        color: #00000047;
        background-color: #07000024;
      }
    }
    .btn:hover {
      border: 1px solid ${tertiaryBlue1};
      background-color: #cbd2d638;
    }
    .tour-btn2 {
      /* &:is(:hover, :focus) {
      background-color: ${primaryColor2};
      color: #ffffff;
    }
    &:active {
      color: #ffffff8f;
      background-color: #07000024;
    } */
    }
    .request-btn {
      width: 100%;
      background: ${primaryColor2};

      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0px;
      color: #ffffff;
      padding: 10px;
      /* &:is(:hover, :focus) {
      background-color: #790617;
      color: #d8d2d246;
    }
    &:active {
      background-color: #03020222;
      color: #ffffff8f;
    } */
    }
    p {
      text-align: center;
      letter-spacing: 0px;
      color: ${secondaryDark4};
    }
    .phone-number {
      padding: 0 10px;
      .title {
        font-size: 18px;
        font-family: EnnVisionsMedium;
        color: rgb(0, 0, 0);
      }
      .subtitle {
        color: rgb(35, 40, 55);
        font-size: 16px;
      }
      .quantity {
        color: rgb(79, 84, 96);
        font-size: 14px;
      }
      .phone-number-icon {
        vertical-align: baseline;
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export default RequestTour;
