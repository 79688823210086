import SecondaryHeading from "../../components/SecondaryHeading/Index";
import useScreenTranslation from "../../hooks/useScreenTranslation";
import { FeaturePropertiesStyled } from "./style";

const FeatureProperties = () => {
  const { getLabelByKey } = useScreenTranslation('home')
  return (
    <FeaturePropertiesStyled>
      <SecondaryHeading
        heading={getLabelByKey('featuredProperties')}
        sub_heading=""
        // sub_heading="Browse The Best Of Our Latest Listing's"
      />
    </FeaturePropertiesStyled>
  );
};

export default FeatureProperties;
