import Wrapper from "./style";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { primaryColor2 } from "../GlobalStyle";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import { authorizationToken, delete_post_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useGlobalContext } from "../../context/context";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";

const MyPostOptions = () => {
  const { setShowSocialOptions, setShowPostOptions, selectedPost } =
    useNewsfeedContext();
  const { setCounter } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const copyToClipboard = useCopyToClipboard();

  // delete post promise
  const deletePostPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.delete(delete_post_url, {
        headers: {
          ...authorizationToken(loginData!),
        },
        data: {
          postId: selectedPost?.postId,
          newsFeedId: selectedPost?.newsFeedId,
        },
      });
      setIsLoading(false);
      setIsModalVisible(true);

      setTimeout(() => {
        setIsModalVisible(false);
        setShowPostOptions(false);
        navigate("/explore-nearby");
        setCounter((prev) => prev + 1);
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  const postOptions = [
    {
      title: "Edit Post",
      doTask: () => {
        setShowPostOptions(false);
        navigate(`/edit-post/${selectedPost.postId}`);
      },
    },
    {
      title: "Edit Post Privacy",
      doTask: () => {
        setShowPostOptions(false);
        navigate("/post-privacy");
      },
    },
    {
      title: "Copy link to share Post",
      doTask: () => {
        copyToClipboard(`post/${selectedPost.postId}`);
        setShowPostOptions(false);
      },
    },
    {
      title: "Share on other media",
      doTask: () => {
        setShowSocialOptions(true);
        setShowPostOptions(false);
      },
    },
  ];
  console.log({ postOptions });
  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Delete Post"
          description="Post Deleted Successfully from your timeline."
          doTask={() => {
            setCounter((prev) => prev + 1);
            setIsModalVisible(false);
            navigate("/explore-nearby");
          }}
        />
      </CustomModal>
      <ul>
        <li
          className="title"
          style={{ color: primaryColor2, fontWeight: "500" }}
          onClick={isLoading ? () => {} : deletePostPromise}
        >
          {isLoading ? "Deleting Post..." : "Delete Post"}
        </li>
        {postOptions.map(({ title, doTask }, index) => {
          return (
            <React.Fragment key={index}>
              <li onClick={doTask}>{title}</li>
            </React.Fragment>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default MyPostOptions;
