import styled from "styled-components";
import {
  fontFamilyMedium,
  mediaDeviceMax,
  tertiaryGrey7,
  whiteColor,
} from "../GlobalStyle";

const NavbarStyle = styled.div`
  background: transparent;
  width: 100%;
  z-index: 20;
  flexWrap: nowrap;
  .auth-text {
    margin-bottom: 0;
    color: ${whiteColor};
    font-size: 15px;
  }
  .profile-icon {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .user-name {
    font-family: ${fontFamilyMedium};
  }
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;  
  }
  .company-logo {
    height:55px;
    margin-top:15px;
    @media ${mediaDeviceMax.mobileL} {
      height: 35px;
      margin-top: 10px;
      margin-left: -15px;
    }
  }
  .scroll-navbar {
    background-color: ${whiteColor} !important;
    transition: background-color 200ms linear;
    box-shadow: 0px 0px 3px #00000029;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
  .active {
    font-family: "EnnVisionsMedium";
  }
  .nav-link {
    color: ${whiteColor} !important;
    font-size: 15px;
    @media screen and (min-width: 991px) {
      &:not(:first-child) {
        margin-left: 27px;
      }
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .nav-link-dark {
    color: ${tertiaryGrey7} !important;
  }
  
`;
export default NavbarStyle;

export const AuthNavbarStyle = styled.div`
  background: transparent;
  width: 100%;
  z-index: 20;
  .auth-text {
    margin-bottom: 0;
    color: ${whiteColor};
    font-size: 15px;
  }
  .company-logo {
    height: 55px;
    margin-top:15px;
   
    @media ${mediaDeviceMax.mobileXL} {
      height: 35px;
      margin-top: 10px;
      margin-left: -15px;
    }
    @media ${mediaDeviceMax.mobileM} {
      height: 30px;
      margin-top: 10px;
      margin-left: -15px;
    }
  }
  .profile-icon {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    @media ${mediaDeviceMax.mobileM} {
      height: 35px;
      width:35px;
    }
  }
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
  }
  .user-name {
    font-family: ${fontFamilyMedium};
  }
  .logo {
    height: 65px;
  }
  .navbar {
    background: ${whiteColor};
    box-shadow: 0px 0px 3px #00000029;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .active {
    font-family: "EnnVisionsMedium";
  }
  .nav-link {
    color: ${tertiaryGrey7} !important;
    font-size: 15px;
    @media screen and (min-width: 991px) {
      &:not(:first-child) {
        margin-left: 27px;
      }
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
