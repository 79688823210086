import styled from "styled-components";
import {
  fontFamilyMedium,
  lightBlue2,
  lightColor1,
  lightDark,
  lightGrey,
  lightGrey10,
  lightGrey2,
  lightGrey6,
  lightGrey7,
  primaryColor2,
  pureDark,
  secondaryDark3,
  tertiaryDark,
  tertiaryGrey19,
  tertiaryGrey7,
  whiteColor,
} from "../../components/GlobalStyle";

export const PostListStyled = styled.div`
  margin-top: 28px;

  .table-link {
    color: ${secondaryDark3} !important;
    font-family: ${fontFamilyMedium};
    font-size: 16px !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .ant-table-body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .ant-table-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
  }

  .ant-table-thead {
    background-color: ${lightGrey6};
    border-radius: 8px;
    .ant-table-cell {
      background: transparent;
      color: ${secondaryDark3};
      font-family: ${fontFamilyMedium};
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 3px 16px;
      &::before {
        height: 0 !important;
      }
      a {
        text-decoration: none;
        color: ${lightGrey7};
        font-size: 14px;
        &:first-child {
        }
      }
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  .action_icons {
    background: #e1e2e2;
    width: 23px;
    height: 22px;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
  }
  .deleteicon {
    background: #d63229 !important;
    cursor: pointer;
  }
  .editicon {
    background: #007aff !important;
    cursor: pointer;
  }
`;

export const MobileTableStyled = styled.div`
  .plus-icon {
    right: 5%;
    bottom: 5%;
    position: fixed;
    z-index: 30;
  }
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 16px;
  }

  .inner-section {
    padding-bottom: 10px;
    .card:first-child {
      margin-top: 12px;
    }
    .card:not(:first-child) {
      margin-top: 20px;
    }
    .card {
      padding: 12px;

      .name-section {
        .name {
          font-weight: 600;
        }
        .id {
          color: #156985;
        }
      }

      .details {
        margin-top: 10px;
        font-size: 16px;
      }
      .details-1 {
        margin-top: 10px;
        font-size: 13px;
      }

      .price-section {
        margin-top: 14px;
      }

      .actions-section {
        margin-top: 16px;
        img {
          background-color: #e1e2e2;
          padding: 10px;
          height: 35px;
        }
        img:not(:first-child) {
          margin-left: 4px;
        }

        .warn-actions {
          img:first-child {
            background-color: ${primaryColor2};
          }
          img:last-child {
            background-color: #007aff;
          }
        }
      }
    }
  }
`;
export const PostList1Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner-section {
    background: ${whiteColor};
    border: 1px solid ${whiteColor};
    border-radius: 6px;
    max-width: 450px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 94%;
      margin: 0 auto;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: ${lightColor1};
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PostList2Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner-section {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    max-width: 450px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 94%;
      margin: 0 auto;
    }

    .post-img {
      height: 313px;
      border-radius: 7px;
      object-fit: cover;
    }

    .last-img {
      .text {
        font-size: 24px;
        color: ${whiteColor};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: ${fontFamilyMedium};
        z-index: 1;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: 0.5s ease;
        background: ${pureDark};
        border-radius: 7px;
        opacity: 0.46;
      }
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        flex-wrap: wrap;
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PostList3Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner-section {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    max-width: 450px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 94%;
      margin: 0 auto;
    }
    .more-img-text {
      font-size: 24px;
      color: ${whiteColor};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: ${fontFamilyMedium};
      z-index: 1;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transition: 0.5s ease;
      background: ${pureDark};
      border-radius: 7px;
      opacity: 0.46;
    }
    .row {
      background-color: ${tertiaryGrey7};
      width: 99.9%;
      margin: 0 auto;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PostList4Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner-section {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    max-width: 450px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 94%;
      margin: 0 auto;
    }

    .more-img-text {
      font-size: 24px;
      color: ${whiteColor};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: ${fontFamilyMedium};
      z-index: 1;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transition: 0.5s ease;
      background: ${pureDark};
      border-radius: 7px;
      opacity: 0.46;
    }

    .say-something {
      color: ${lightGrey};
      font-size: 17px;
      margin-top: 26px;
      text-align: center;
    }

    .row {
      width: 100%;
      margin: 0 auto;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PostList5Styled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding-top: 18px;
  }
  .inner-section {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 94%;
      margin: 0 auto;
    }

    .left-side {
      .main-img {
        height: 333px;
      }
    }

    .mini-title {
      color: ${pureDark};
      font-size: 14px;
    }

    .btm-border {
      border-bottom: 1px solid ${tertiaryGrey19};
    }
    .tag-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;

      button {
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
        color: ${pureDark};
      }
    }
    .advanced-title {
      font-size: 17px;
      color: ${pureDark};
      font-family: ${fontFamilyMedium};
    }

    .request-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;
    }

    .advanced-settings {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PostDetailsStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 86px);

  .inner-container {
    background-color: white;
    max-width: 500px;
    margin: 0 auto;
    position: relative;

    .card {
      border: 1px solid ${lightColor1};
      border-radius: 6px;
      .card-img-top {
        height: 346px;
        padding: 10px;
      }
      .video-img-top {
        height: 346px;
        padding: 10px;
      }
      .card-body {
        padding-top: 0px;
        padding: 10px;
        .details-section {
          margin-top: -24px;
          position: relative;
          z-index: 10;
          video {
            width: 100%;
            height: auto;
          }
          .message {
            font-size: 13px;
            color: ${tertiaryGrey7};
            margin-top: 10px;
          }
          .hast-tags {
            font-size: 13px;
            color: ${primaryColor2};
            margin-top: 10px;
            z-index: 40;
          }

          .react-icons {
            background: rgba(255, 255, 255, 0.84);
            border-radius: 6px;
            padding: 10px;
          }
          .comment-section {
            input {
              margin-top: 20px;
            }
            .post-link {
              color: ${lightBlue2};
              bottom: -12%;
              font-family: ${fontFamilyMedium};
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

// post detail style
export const PostDetailStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  @media screen and (max-width: 768px) {
    height: 100%;
  }
  .inner-section {
    background: ${whiteColor};
    box-shadow: 0px 0px 15px #0000000f;
    border: 1px solid ${lightGrey10};
    border-radius: 6px;
    width: 100%;
    border-radius: 6px;
    margin: 0 auto;
    max-height: 800px;
    min-height: 400px;

    @media screen and (max-width: 900px) {
      width: 600px;
    }

    @media screen and (max-width: 480px) {
      width: 480px;
    }
    .mini-imgs-section {
      &-image {
        position: relative;
        display: flex;
        justify-content: end;
        .close-icon {
          position: absolute;
          right: 2%;
          height: 26px;
          background-color: white;
        }
      }
    }

    .alice-carousel__dots {
      margin-top: 12px;
    }
    .alice-carousel__stage-item __active __target {
      width: 100%;
    }
    .b-refs-buttons {
      button {
        position: absolute;
        svg {
          font-size: 32px;
        }
      }
      .prev-btn {
        left: 0%;
        top: 42%;
      }
      .next-btn {
        right: 0%;
        top: 42%;
      }
    }
    .media-post-item {
      height: 82vh;
      object-fit: cover;
      width: 100%;
    }
    .slider-item {
      position: relative;

      .close-icon {
        right: 2%;
        top: 2%;
        position: absolute;
        border-radius: 50%;
        background-color: ${lightDark};
        cursor: pointer;
      }
    }
    video {
      width: 100%;
      height: 390px;
    }
    .media-post {
      bottom: 1%;
      right: 2%;
      .gallery-icon {
        border-radius: 3px;
        bottom: 4%;
        right: 4%;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 480px) {
      width: 96%;
      margin: 0 auto;
    }

    .say-something {
      color: ${lightGrey};
      font-size: 13px;
      margin: 10px 0;
      text-align: center;
    }
    .row {
      width: 100%;
      margin: 0 auto;
    }

    .mini-title {
      color: ${pureDark};
      font-size: 14px;
      font-family: ${fontFamilyMedium};
    }
    .sub-title {
      border-bottom: 1px solid ${lightGrey2};
      padding-bottom: 10px;
    }

    .btm-border {
      border-bottom: 1px solid ${tertiaryGrey19};
    }
    .tag-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;

      button {
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
        color: ${pureDark};
        &:hover {
          background: ${primaryColor2};
          color: ${whiteColor};
        }
      }
    }
    .advanced-title {
      font-size: 17px;
      color: ${pureDark};
      font-family: ${fontFamilyMedium};
    }

    .request-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;
    }

    .advanced-settings {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      border-bottom: 1px solid ${lightGrey2};
      margin-bottom: 10px;
      padding: 0 10px;
      .gallery {
        font-size: 18px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
    }
  }
`;
