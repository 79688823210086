import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AppProvider } from "./context/context";
import { Provider } from "react-redux";
import store from "./redux/store";
import { RandomProvider } from "./context/RandomContext";
import { PropertyProvider } from "./context/PropertyContext";
import { AuthProvider } from "./context/AuthContext";
import { StoryProvider } from "./context/StoryContext";
import { UserProvider } from "./context/UserContext";
import { PostProvider } from "./context/PostContext";
import { ProfessionalProvider } from "./context/ProfessionalContext";
import { RequestTourProvider } from "./context/RequestTourContext";
import { NavbarProvider } from "./context/NavbarContext";
import { LoadScript } from "@react-google-maps/api";
import { SearchProvider } from "./context/SearchContext";
import { NewsfeedProvider } from "./context/NewsfeedContext";
import axios, { AxiosRequestConfig } from "axios";
import { base_url, local_storage_web_key } from "./utils/api_urls";
import { v4 as uuidv4 } from "uuid";
import { BookingProvider } from "./context/BookingContext";
import IntroScreen from "./components/IntroScreen";

// set header before app initialize
export const initializeApp = async () => {
  // uuid unique id

  // Set up an Axios interceptor to modify the request config
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const UUID = uuidv4();
      // getting local storage token data
      const tokenData = localStorage.getItem(local_storage_web_key);

      config.headers = config.headers || {}; // Ensure headers object is defined
      config.headers["loggingToken"] = UUID;
      config.headers["userName"] =
        JSON.parse(tokenData!)?.userDetails?.username || "AnonymousUser";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // get system ip address
  let ipAddress = "168.8.1";

  // try {
  //   const response = await fetch("https://api.ipify.org?format=json");
  //   const data = await response.json();
  //   ipAddress = data.ip;
  //   // Continue with the rest of your code that depends on the ipAddress
  // } catch (error) {
  //   console.error("Error retrieving IP address:", error);
  // }

  // Set the Axios defaults headers
  axios.defaults.baseURL = base_url;
  axios.defaults.headers.common["context"] = "WebApp";
  axios.defaults.headers.common["deviceIp"] = ipAddress;
  axios.defaults.headers.common["version"] = "0.0.1";
};

export const MAP_API = "AIzaSyCce3AaeUJi09LlE7J-Ia9rkWqco3bDtrA";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Call the initializeApp function before rendering the app
initializeApp().then(() => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <LoadScript
            googleMapsApiKey={MAP_API}
            libraries={["places"]}
            loadingElement={<IntroScreen />}
          >
            <AppProvider>
              <NavbarProvider>
                <BookingProvider>
                  {/* <RandomProvider> */}
                  <SearchProvider>
                    <NewsfeedProvider>
                      <PropertyProvider>
                        <ProfessionalProvider>
                          <AuthProvider>
                            <StoryProvider>
                              <PostProvider>
                                <RequestTourProvider>
                                  {/* <UserProvider> */}
                                  <App />
                                </RequestTourProvider>
                              </PostProvider>
                              {/* </UserProvider> */}
                            </StoryProvider>
                          </AuthProvider>
                        </ProfessionalProvider>
                      </PropertyProvider>
                      {/* </RandomProvider> */}
                    </NewsfeedProvider>
                  </SearchProvider>
                </BookingProvider>
              </NavbarProvider>
            </AppProvider>
          </LoadScript>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
