import { DatePicker, DatePickerProps } from "antd";
import React from "react";
import { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import moment, { MomentInput } from "moment"; // Import the 'moment' and 'MomentInput' types

type CustomDateProps = {
  date: string | null;
  setDate: (newDate: string | null) => void;
  onBlur?: () => void;
};

const CustomDate: React.FC<CustomDateProps> = ({ date, setDate, onBlur }) => {
  const handleDateChange = (value: Moment | null) => {
    if (value) {
      const formattedDate = value.format("YYYY-MM-DD");
      setDate(formattedDate);
    } else {
      setDate(null);
    }
  };

  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   return current && current <= dayjs().startOf("day");
  // };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current <= dayjs().startOf("day");
  };

  const defaultDate: MomentInput = date
    ? moment(dayjs(date).toDate())
    : undefined;

  return (
    <DatePicker
      className="w-100"
      value={defaultDate as Moment | null} // Use 'as' to assert the type
      onChange={handleDateChange}
      disabledDate={disabledDate}
      format="YYYY-MM-DD"
      onBlur={onBlur}
    />
  );
};

export default CustomDate;
