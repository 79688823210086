import { Nav } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { primaryColor2 } from "../GlobalStyle";
import FiltersStyle from "./style";
import { useSearchContext } from "../../context/SearchContext";
import { NavItemEventKey } from "../../screens/Home/HeroSection";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const PropertyTypeFilters = () => {
  const {
    address,
    purpose,
    propertyType,
    setPropertyType,
    setPropertyTypeNumber,
    propertyTypeNumber,
    setShowPropertyTypes,
    selectedSubTypes,
    setSelectedSubTypes,
    setFilterCriteria,
    searchCriteria,
    setSearchCriteria,
    active,
    setActive,
  } = useSearchContext();
  console.log({ propertyType, propertyTypeNumber });
  const navigate = useNavigate();
  const loginData = useAppSelector((state) => state.loginData.data);

  const propertyData = useAppSelector(
    (state) => state.appData.data.propertyAppData[propertyTypeNumber]
  );
  const propertySubTypes = propertyData.propertySubTypesDTOS;
  // route path
  const { pathname } = useLocation();

  const handleSelect = (eventKey: string | null) => {
    if (!eventKey) return;
    const item: NavItemEventKey = JSON.parse(eventKey);
    if (item.keyName === "all") {
      setActive(true);
      setSelectedSubTypes(propertySubTypes);
    } else if (selectedSubTypes.some((i) => i.keyName === item.keyName)) {
      setSelectedSubTypes(
        selectedSubTypes.filter((i) => i.keyName !== item.keyName)
      );
    } else {
      setSelectedSubTypes([...selectedSubTypes, item]);
    }
  };

  useEffect(() => {
    setActive(false);
    setSelectedSubTypes([]);
    setSearchCriteria([]);
  }, [propertyType]);

  console.table(selectedSubTypes);
  return (
    <FiltersStyle>
      <div className="head-bar">
        <Nav
          defaultActiveKey="Residential"
          onSelect={(selectedType) => {
            setPropertyType(selectedType!);
            setPropertyTypeNumber(
              selectedType === "Residential"
                ? 0
                : selectedType === "Commercial"
                ? 1
                : 2
            );
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Residential" className="">
              Residential
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Commercial">Commercial</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Plots">Plots</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="types mt-2">
        <Nav
          variant="pills"
          defaultActiveKey={propertySubTypes[0].displayName}
          onSelect={handleSelect}
        >
          <Nav.Item>
            <Nav.Link
              eventKey={JSON.stringify({
                keyName: "all",
              })}
              active={active}
            >
              All
            </Nav.Link>
          </Nav.Item>
          {propertySubTypes.map(({ id, displayName, keyName }) => (
            <Nav.Item key={id}>
              <Nav.Link
                eventKey={JSON.stringify({
                  id,
                  keyName,
                })}
                active={selectedSubTypes.some(
                  (item) => item.keyName === keyName
                )}
              >
                {displayName}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="d-flex justify-content-end w-100">
        <div className="me-3 mb-3">
          <CustomButton
            bgcolor="#EFEFF4"
            color="#717272"
            padding="6px 8px"
            width="100px"
            type="button"
            title="Cancel"
            fontSize="16px"
            clicked={() => {
              setActive(false);
              setSelectedSubTypes([]);
              setSearchCriteria([]);
              const oldSearchCreteria = searchCriteria.filter(
                ({ filterKey }) =>
                  filterKey !== "propertyType" &&
                  filterKey !== "propertySubType"
              );
              setSearchCriteria(oldSearchCreteria);

              setShowPropertyTypes(false);
            }}
            border="none"
          />
        </div>
        <div className="me-2 mb-3">
          <CustomButton
            bgcolor={primaryColor2}
            color="#FCFBFB"
            padding="6px 8px"
            width="100px"
            type="button"
            fontSize="16px"
            title="Apply"
            border="none"
            clicked={() => {
              console.log({ loginData });
              // setting purpose
              setFilterCriteria("purpose", purpose);
              // setting address
              setFilterCriteria("address", address);
              // setting property type
              const selectedIds = selectedSubTypes
                .map(({ id }) => id)
                .join(",");
              // when no subtype is selected by user
              const zeroSelectedIds = propertySubTypes
                .map(({ id }) => id)
                .join(",");
              setFilterCriteria("propertyType", propertyTypeNumber + 1);
              // setting subtypes search filter
              setFilterCriteria(
                "propertySubType",
                selectedSubTypes.length < 1 ? zeroSelectedIds : selectedIds
              );
              setShowPropertyTypes(false);
              if (pathname === "/") {
                navigate("/find-home");
              }
            }}
          />
        </div>
      </div>
    </FiltersStyle>
  );
};

export default PropertyTypeFilters;
