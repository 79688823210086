import styled from "styled-components";
import {
  fontFamilyMedium,
  lightDark,
  primaryColor2,
  tertiaryGrewish,
} from "../../components/GlobalStyle";

const PropertiesByStatusStyle = styled.div`
  .properties {
    padding-top: 24px;
    .title {
      text-transform: capitalize;
      font-family: ${fontFamilyMedium};
      font-size: 18px;
      margin-bottom: 6px;
    }
    .card {
      box-shadow: 0px 0px 20px #00000012;
      border-radius: 3px;
      margin-bottom: 10px;
      border: none;
      cursor: pointer;
      .sub-title {
        color: ${lightDark};
        font-size: 14px;
      }
      .property-description {
        border-bottom: 1px solid ${tertiaryGrewish};
        padding-bottom: 5px;
        .btn-primary {
          color: ${primaryColor2};
          background-color: rgb(17 137 199 / 20%);
          font-size: 14px;
          border-radius: 3px;
          text-transform: uppercase;
          font-family: ${fontFamilyMedium};
          border: none;
        }
        .price {
          font-size: 18px;
          font-family: "EnnVisionsMedium";
          color: ${primaryColor2};
        }
      }
      .property-details {
        margin-top: 10px;
        .img {
          vertical-align: super;
        }
        .text {
          margin-top: 3px;
          margin-left: 4px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default PropertiesByStatusStyle;
