import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/icons/ic_blog_detail_arrow.svg";
import downArrow from "../../assets/icons/ic_add_property_dropdown.svg";
import { FAQStyle } from "./style";
import { useAppSelector } from "../../app/hooks";
import { useState } from "react";

const FAQ = () => {
  // get faq form appdata in redux
  const { faqs } = useAppSelector((state) => state.appData.data);

  console.log({ faqs });

  const [selectedFAQ, setSelectedFAQ] = useState(0);

  console.log({ selectedFAQ });

  return (
    <Container>
      <FAQStyle>
        <div className="faq-container">
          <h4 className="title text-center">Frequently asked questions</h4>
          <p className="description text-center">
            Welcome to our FAQ section! Here you will find answers to some of
            the most commonly asked questions about our mobile applications &
            service.
          </p>
          <Row className="align-items-center">
            <Col sm={4}>
              {faqs.map(({ question }, index) => (
                <div
                  onClick={() => setSelectedFAQ(index)}
                  key={index}
                  className="faq-container-section d-flex justify-content-between align-items-center"
                >
                  <h6 className="faq-question">{question}</h6>
                  <img
                    className="toggle-arrow"
                    src={selectedFAQ === index ? downArrow : rightArrow}
                    alt={selectedFAQ === index ? "open" : "close"}
                  />
                </div>
              ))}
            </Col>
            <Col sm={8}>
              <h6 className="faq-question">{faqs[selectedFAQ].question}</h6>
              <p className="mt-2 faq-answer">{faqs[selectedFAQ].answer}</p>
            </Col>
          </Row>
        </div>
      </FAQStyle>
    </Container>
  );
};

export default FAQ;
