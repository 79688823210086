import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { media_base_url } from "../../utils/api_urls";
import { singlePropertyTypes } from "../../utils/types";
import { MiniHeadingSecondary } from "../GlobalStyle";
import bedroomIcon from "../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../assets/icons/ic_property_area.svg";
import PropertyStyle from "./style";
import { numberToLocalString } from "../../utils/utilities";
import { useAppSelector } from "../../app/hooks";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";

const Property: React.FC<singlePropertyTypes> = ({
  propertyId,
  imageURLS,
  title,
  location,
  purpose,
  price,
  fullBathrooms,
  fullBedrooms,
  propertySize: { keyName, size },
}) => {
  const navigate = useNavigate();
  // navigate to property details
  const navigateToDetails = (id: any) => {
    navigate(`/properties/${id}`);
  };
  const propertySizeTypes = useAppSelector(
    (state) => state.appData.data.propertyAppData[0]?.propertySizeDTOS
  );
  const currencySymbol = useAppSelector(
    (state) => state.appData.data?.countryName?.results?.currencySymbol
  );
  return (
    <PropertyStyle>
      <Card  onClick={() => navigateToDetails(propertyId)}>
        <div className="image-container">
          <img
           // onClick={() => navigateToDetails(propertyId)}
            src={imageURLS[0] ? media_base_url + imageURLS[0] : placeholder}
            alt="property"
            className="image"
          />
        </div>
        <Card.Body>
          <div className="d-flex justify-content-between">
            {title && (
              <MiniHeadingSecondary>
                {/* {title} */}
                {title?.length > 20 ? `${title.slice(0, 20)}...` : title}
                {/* {truncateString(title, 26)} */}
              </MiniHeadingSecondary>
            )}
            {/* {loginData && (
              <Dropdown
                open={isShowOptions}
                onOpenChange={(open) => {
                  setIsShowOptions(open);
                }}
                placement="bottomLeft"
                dropdownRender={PropertyOptions}
                trigger={["click"]}
              >
                <span>
                  <BsThreeDotsVertical onClick={() => setIsShowOptions(true)} />
                </span>
              </Dropdown>
            )} */}
          </div>
          <Card.Text>
            {location && (
              <p className="sub-title fs-6">
                {location?.length > 30
                  ? `${location.slice(0, 30)}...`
                  : location}
              </p>
            )}
          </Card.Text>

          <div className="d-flex justify-content-between align-items-center property-description">
            <Button variant="primary text-captilize">{purpose}</Button>
            <p className="price m-0">
              {" "}
              {numberToLocalString(+price)}
              {currencySymbol}
              {purpose === "Rent" && "/mes"}
            </p>
          </div>
          <div className="property-details d-flex justify-content-between align-items-center">
            <div className="beds d-flex justify-content-between align-items-center">
              <span>
                <img src={bedroomIcon} alt="bed" />
              </span>
              <p className="text">{fullBedrooms} Bed</p>
            </div>

            <div className="bathrooms d-flex justify-content-between align-items-center">
              <span>
                <img src={bathIcon} alt="bath" />
              </span>
              <p className="text">{fullBathrooms} Bath</p>
            </div>
            <div className="length d-flex justify-content-between align-items-center">
              <span>
                <img src={areaIcon} alt="area" />
              </span>
              <p className="text">
                {size}
                {
                  propertySizeTypes.find(
                    (propertySize) => propertySize.keyName === keyName
                  )?.displayName
                }
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </PropertyStyle>
  );
};

export default Property;
