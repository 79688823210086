import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  TertiaryHeading,
  fontFamilyMedium,
  lightGrey5,
  lightGrey9,
  mediaDeviceMax,
  primaryColor2,
  tertiaryGrey7,
} from "../../components/GlobalStyle";
import Head from "../../components/Head/Head";
import Errormsg from "../../components/ErrorMessage";
import { useAppSelector } from "../../app/hooks";
import CustomPhoneInput from "../../components/CustomInputNumber/CustomPhoneInput";
import CustomModal from "../../components/Modal/CustomModal";
import { useRef, useState } from "react";
// import CreatedUserModal from "./RegisteredModal";
import axios from "axios";
import { contact_us_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import styled from "styled-components";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

// create user initial values types
type initialValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | number;
  message: string;
};

const ContactUs = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const createUserToastId = useRef<any>();
  const { result: userLocation } = useAppSelector(
    (state) => state.userLocation
  );
  // get Validations from redux appData
  const {
    countryName: {
      results: {
        countryCode,
        countryFlagURL,
        examplePhoneNumber,
        name,
        phoneNumberLength,
      },
    },
    validations,
  } = useAppSelector((state) => state.appData.data);
  // create user initial values
  const initialValues: initialValuesType = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  // user validations
  const email = validations[14];
  const phoneNumber = validations.find(
    ({ key }) => key === "PHONE_NUMBER_LENGTH"
  )!;
  const firstName = validations[16];
  const lastName = validations[19];

  // user regExpressions
  const firstNameReg = new RegExp(firstName?.pattern);
  const lastNameReg = new RegExp(lastName?.pattern);
  const emailAddressReg = new RegExp(email?.pattern);
  const phoneNumberReg = new RegExp(phoneNumber?.pattern);

  // create user validation schema
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(firstName?.notBlankMsg)
      .matches(firstNameReg, firstName?.patternMsg),
    lastName: Yup.string()
      .required(lastName?.notBlankMsg)
      .matches(lastNameReg, lastName?.patternMsg),
    email: Yup.string()
      .required(email.notBlankMsg)
      .matches(emailAddressReg, email?.patternMsg),
    phoneNumber: Yup.string().required(phoneNumber?.notBlankMsg),
    // .matches(phoneNumberReg, phoneNumber.patternMsg),
    message: Yup.string().required("Message is required"),
  });
  // create user data submit
  const onSubmit = async (values: initialValuesType, { resetForm }: any) => {
    const contactValues = {
      ...values,
      phoneNumber: Number(values?.phoneNumber),
    };
    console.log({ contactValues });
    try {
      setIsLoading(true);
      const { data } = await axios.post(contact_us_url, contactValues);
      setIsShowModal(true);
      resetForm();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      createUserToastId.current = toast(
        error?.response?.data?.errors[0].errorMessage,
        {
          type: "error",
          autoClose: 1000,
        }
      );
    }
  };

  return (
    <>
      <Head title="Create User" />
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Contact Us"
          description="Contact Us Successfully"
          doTask={() => setIsShowModal(false)}
        />
      </CustomModal>
      <Wrapper>
        <div className="inner-container">
          <div className="inner-container-card">
            <div className="inner-container-card-logo">
              <TertiaryHeading>Contact Us</TertiaryHeading>
            </div>
            <p className="text-center message mb-3">
              Any Question or Remarks? Just write Us A Message!
            </p>
            <div className="inner-container-card-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form
                      name="basic"
                      onFinish={formik.handleSubmit}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      // validateMessages={validationSchema}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="d-flex justify-content-between gap-2">
                            <FormControl
                              control="input"
                              type="text"
                              name="firstName"
                              labelMarginBottom="8px"
                              labelFamily={fontFamilyMedium}
                              label="First Name"
                              border="1px solid #EFEFF4"
                              placeholder="First Name"
                              className={
                                formik.errors.firstName &&
                                formik.touched.firstName
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                            <FormControl
                              control="input"
                              type="text"
                              name="lastName"
                              labelMarginBottom="8px"
                              labelFamily={fontFamilyMedium}
                              label="Last Name"
                              border="1px solid #EFEFF4"
                              placeholder="Last Name"
                              className={
                                formik.errors.lastName &&
                                formik.touched.lastName
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                          </div>
                          <div>
                            <FormControl
                              control="input"
                              type="email"
                              name="email"
                              label="Email"
                              labelMarginBottom="8px"
                              labelFamily={fontFamilyMedium}
                              border="1px solid #EFEFF4"
                              placeholder="Enter Email"
                              className={
                                formik.errors.email && formik.touched.email
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                          </div>
                          <CustomPhoneInput
                            countryNumber={countryCode}
                            placeholder={examplePhoneNumber}
                            phoneLength={phoneNumberLength}
                            countryFlag={countryFlagURL}
                            phoneValueHandler={(value: number | string) =>
                              formik.setFieldValue("phoneNumber", value)
                            }
                            label="Phone"
                            value={formik.values.phoneNumber}
                            name="phoneNumber"
                            countryName={name}
                            labelFamily={fontFamilyMedium}
                            labelFontSize="14px"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component={Errormsg}
                          />
                        </Col>
                        <Col md={6} className="position-relative">
                          <FormControl
                            control="textarea"
                            type="text"
                            name="message"
                            labelFamily={fontFamilyMedium}
                            label="Message"
                            border="1px solid #EFEFF4"
                            placeholder="Enter Message"
                            height="136px"
                            className={
                              formik.errors.message && formik.touched.message
                                ? "is-invalid"
                                : "customPasswordInput"
                            }
                          />
                          <div className="mt-1 submit-btn d-flex justify-content-center align-items-center d-md-block">
                            <CustomButton
                              bgcolor={primaryColor2}
                              color="white"
                              padding="8px"
                              width="100%"
                              type="submit"
                              title="Submit"
                              fontSize="16px"
                              loading={isLoading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ContactUs;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${lightGrey5};
  margin: 0 auto;
  .inner-container {
    padding-top: 0px;
    .submit-btn {
      bottom: 1%;
      width: 96%;
      position: absolute;
      @media ${mediaDeviceMax.tablet} {
        position: static;
        margin-top: 12px;
        width: 100%;
      }
    }
    &-card {
      .message {
        font-size: 13px;
        color: ${lightGrey9};
      }
      width: 800px;

      @media ${mediaDeviceMax.tabletB} {
        width: 620px;
      }

      @media ${mediaDeviceMax.tablet} {
        width: 520px;
      }
      @media ${mediaDeviceMax.mobileBS} {
        width: 400px;
      }

      @media ${mediaDeviceMax.mobileXL} {
        width: 320px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 6px;
      margin: auto;

      padding: 20px 12px;
      &-form {
        width: 96%;
      }
    }
  }
  .terms {
    max-width: 390px;
    width: 98%;
    span {
      font-size: 14px;
      &:first-child {
        color: ${lightGrey9};
      }
      &:last-child {
        color: ${tertiaryGrey7};
      }
    }
  }
  a {
    font-family: ${fontFamilyMedium};
    text-decoration: none;
    color: ${primaryColor2};
    margin: 0 2px;
  }
  .is-invalid {
    border: 1px solid red;
    border-radius: 5px;
    background: white;
    border-radius: 2px;
    padding: 10px;
    width: 100%;
    width: -moz-available;
    outline: none;
    margin-bottom: 8px;
  }
  .customInput,
  .customPasswordInput {
    background: white;
    border: 1px solid #c6c6c8;
    border-radius: 2px;
    padding: 10px;
    width: 100%;
    width: -moz-available;
    outline: none;
    margin-bottom: 8px;
  }

  .ant-input-suffix {
    margin-left: 30px;
  }

  @media screen and (max-width: 425px) {
    .inner-container {
      &-card {
        padding: 3px;
        &-logo {
          h1 {
            display: none;
          }
        }
      }
    }
  }
`;
