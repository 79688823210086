import { Col, Row } from "react-bootstrap";
import { ExplorePropertyStyled } from "./style";
import ic_image_2 from "../../assets/images/ic_image_2.png";
import {
  BlackDot,
  primaryColor2,
  SecondaryHeading,
  TertiaryHeading,
  TertiraryHeadingMini,
  UnderlineRed,
} from "../../components/GlobalStyle";
import useScreenTranslation from "../../hooks/useScreenTranslation";
const ExploreProperty = () => {
  const {getLabelByKey}=useScreenTranslation('exploreProperty')
  return (
    <ExplorePropertyStyled>
      <SecondaryHeading className="text-center">
        {getLabelByKey('title')}
      </SecondaryHeading>
      {/* <TertiraryHeadingMini className="text-center">
        Browse Listings Curated By Neighborhoods
      </TertiraryHeadingMini> */}
      <div className="d-flex justify-content-center">
        <UnderlineRed />
        <BlackDot />
      </div>
      <Row className="position-relative">
        <Col sm={6} md={6} className="p-0 pe-sm-2">
          <div className="img-section">
            <img src={ic_image_2} alt="property" />
          </div>
        </Col>
        <Col sm={6} md={6} className="mt-4 mt-md-none pb-5">
          <TertiaryHeading> {getLabelByKey('heading')}
</TertiaryHeading>
          <p className="sub-title">
          {getLabelByKey('subtitle')}
        
          </p>
          <TertiaryHeading>{getLabelByKey('heading2')}</TertiaryHeading>
          <p className="sub-title">
            <span>
            {getLabelByKey('subtitle2')}
            </span>
          </p>
          {/* <div className="explore-btn position-absolute w-100 d-flex justify-content-end me-3 mt-3">
            <CustomButton
              bgcolor={primaryColor2}
              color="white"
              padding="6px 8px"
              width="160px"
              type="submit"
              title="EXPLORE"
              fontSize="16px"
            />
          </div> */}
        </Col>
      </Row>
    </ExplorePropertyStyled>
  );
};

export default ExploreProperty;
