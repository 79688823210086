import { useEffect, useState } from "react";
import { MAP_API } from "../App";
import { addCurrentLocation } from "../redux/features/location/userLocationSlice";
import { getAppData } from "../redux/features/user/appDataSlice";
import { getScreenTranslation } from "../redux/features/user/screenTranslationSlice";
import store from "../redux/store";

interface Location {
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  country: string;
  address: string;
}

interface Position {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GeocodeResult {
  address_components: AddressComponent[];
  formatted_address: string;
}

const useLocationData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [location, setLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
    city: "",
    state: "",
    country: "",
    address: "",
  });

  const getLocation = () => {
    console.log("use geolocation");
    // if (navigator.geolocation) {
    //   console.log("use if geolocation");

      // navigator.geolocation.getCurrentPosition(
      //   async (position: Position) => {
          // const { latitude, longitude } = position.coords;
          // setLoading(true);
          // const response = await fetch(
          //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAP_API}`
          // );

        const  latitude=40.416775
           const  longitude= -3.70379
          const  city='Mardad'
          const  state='Mardad'
           const country='spain'
           const address='Prta del Sol, 7, Centro, 28013 Madrid, Spain'
          // const data = await response.json();

          // const city =
          //   data.results.length !== 0 &&
          //   data.results[0].address_components.find(
          //     (component: AddressComponent) =>
          //       component.types.includes("locality")
          //   )?.long_name;

          // const state =
          //   data.results.length !== 0 &&
          //   data.results[0].address_components.find(
          //     (component: AddressComponent) =>
          //       component.types.includes("administrative_area_level_1")
          //   )?.long_name;

          // const country =
          //   data.results.length !== 0 &&
          //   data.results[0].address_components.find(
          //     (component: AddressComponent) =>
          //       component.types.includes("country")
          //   )?.long_name;

          // const address =
          //   data.results.length !== 0 && data.results[0].formatted_address;

          setLocation({
            latitude,
            longitude,
            city: city
              ? city
              : state
              ? state
              : country
              ? country
              : "Global Living",
            state: state
              ? state
              : city
              ? city
              : country
              ? country
              : "Global Living",
            country: country
              ? country
              : state
              ? state
              : city
              ? city
              : "Global Living",
            address,
          });

          const locationData = {
            latitude,
            longitude,
            city: city
              ? city
              : state
              ? state
              : country
              ? country
              : "Global Living",
            state: state
              ? state
              : city
              ? city
              : country
              ? country
              : "Global Living",
            country: country
              ? country
              : state
              ? state
              : city
              ? city
              : "Global Living",
            address,
          };
         
          store.dispatch(addCurrentLocation(locationData));
          store.dispatch(getAppData(country));
          store.dispatch(getScreenTranslation())
          setLoading(false);
        // },
        // (error) => {
        //   console.log("Error retrieving location:", error);
        //   store.dispatch(getAppData("Spain"));
        //   setError("Error retrieving location.");
        //   setLoading(false);
        // }
      // );
    // } else {
    //   console.log(
    //     "Geolocation is not supported by this browser.",
    //     "error in geolocation"
    //   );
    //   setError("Geolocation is not supported by this browser.");
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    getLocation();
  
  }, []);

  return {
    loading,
    error,
    location,
  };
};

export default useLocationData;
