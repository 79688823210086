import styled from "styled-components";
import {
  basicColor,
  fontFamilyMedium,
 
  fontFamilyRegular,
 
  lightDark,
 
  primaryColor2,
  pureDark2,
  secondaryDark3,
  tertiaryGrey12,
  tertiaryGrey7,
  tertiaryGrey8,
} from "../../components/GlobalStyle";

const RegisterStyle = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
height: 170vh;
background: ${tertiaryGrey8};
margin:0 auto;

.inner-container {
  &-card {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    width: 100%;
    margin: auto;
    padding: 20px 12px;
    &-form {
      width: 96%;
    }

    &-logo {
      text-align: center;

      img {
        width: 164px;
        margin-bottom: 0;
      }

      h1 {
        font-size: 20px;
        font-weight: 700;
        color: #1b283f;
      }
    }
  }
  .title {
    font-family: ${fontFamilyMedium};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${pureDark2};
  }
  .message {
    font-family: ${fontFamilyRegular};
    color: ${lightDark};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .orText {
    font-family: ${fontFamilyMedium};
    color: ${pureDark2};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 15px;
}
  .terms-conditions {
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.signup-text {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: ${primaryColor2};
    text-decoration: none;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: #8e8e93;
  }
  h6 {
    margin-top: -8px;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    color: ${basicColor};
  }
}
.is-invalid {
  border: 1px solid red;
  border-radius: 5px;
  background: white;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  width: -moz-available;
  outline: none;
  margin-bottom: 8px;
}
.customInput,
.customPasswordInput {
  background: white;
  border: 1px solid #c6c6c8;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  width: -moz-available;
  outline: none;
  margin-bottom: 8px;
}
.forget_password {
  color: ${primaryColor2};
  font-size: 14px;
  text-decoration: none;
  font-family: "EnnVisionsMedium" !important;
}
.remember-password {
  &-text {
    color: ${tertiaryGrey12};
  }
}
.ant-input-suffix {
  margin-left: 30px;
}
.logo {
  height: 77px;
  width: 155px;
  margin-bottom: 20px;
}
// .line {
//   display: none;
// }
.line {
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  height: 1px;
  flex-grow: 1;
  background: #bdbdbd;}

@media screen and (max-width: 425px) {
  .login-container {
    &-card {
      padding: 3px;
      &-logo {
        h1 {
          display: none;
        }
      }
    }
  }
  .signup-text {
    p {
      font-size: 14px;
    }
    h6 {
      font-size: 14px;
      margin-left: 3px;
    }
  }
  .line {
    display: inline;
    width: 90%;
    margin-bottom: 6px;
  }
}
`;

export default RegisterStyle;

export const CreatedUserModalStyle = styled.div`
  text-align: center;
  margin: 0 auto;
  height: 93vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // .inner-container {
  //   max-width: 420px;
  //   background: #ffffff;
  //   box-shadow: 0px 0px 16px #00000017;
  //   border-radius: 7px;
  //   padding: 18px;
    .inner-container {
      &-card {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 6px;
        width: 100%;
        margin: auto;
        padding: 20px 12px;
        &-form {
          width: 96%;
        }
  
        &-logo {
          text-align: center;}
    img {
      height: 60px;
      margin-bottom: 12px;
      width: 190px;
    }
    .account-created {
      font-family: "EnnVisionsMedium";
      font-size: 19px;
    }
    .message {
      margin-top: 14px;
      font-size: 16px;
      width: 90%;
      margin: 0 auto;
      color: ${secondaryDark3};
      padding-bottom: 10px;
      border-bottom: 1px solid ${tertiaryGrey12};
    }
  }
`;
