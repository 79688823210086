import ExploreProperty from "./ExploreProperty";
import FeatureProperties from "./FeatureProperties";
import { Container } from "react-bootstrap";
import WhyChooseUs from "./WhyChooseUs";
import FindProperties from "./FindProperties";
import WhoWeAre from "./WhoWeAre";
import Testomonials from "./Testomonials";
import MembershipPlans from "./MembershipPlans";
import { Helmet } from "react-helmet";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import Properties from "./Properties";
import { HomeStyle } from "./style";
import HeadingViewMore from "../../components/HeadingViewMore/HeadingViewMore";
import { useNavigate } from "react-router-dom";
import { useNavbarContext } from "../../context/NavbarContext";
import FooterHome from "../../components/Footer/FooterHome";
import HeroSection from "../../screens/Home/HeroSection";
import useScreenTranslation from "../../hooks/useScreenTranslation";

const Home = () => {
  const { homeData, error, loading, triggerRefetch } = useNavbarContext();
  const {getLabelByKey}=useScreenTranslation('home')
  const navigate = useNavigate();

  if (error) {
    return <NoDataAvailable title={error} />;
  }
  const propertyData = loading
    ? {
        currentPage: 0,
        properties: [],
        totalItems: 0,
        totalPages: 0,
      }
    : {
        currentPage: homeData?.propertiesList?.currentPage,
        properties: homeData?.propertiesList?.propertyList?.slice(0, 8) || [],
        totalItems: homeData?.propertiesList?.totalItems,
        totalPages: homeData?.propertiesList?.totalItems,
      };

  return (
    <>
      <HeroSection />
      <HomeStyle>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <Container className="properties-section">
          {propertyData.properties.length > 0 && (
            <>
           
              <FeatureProperties />
              <Properties
                {...propertyData}
                loadMore={false}
                propertiesLoading={loading}
              />
              <div className="load-more d-flex justify-content-start mt-3">
                <HeadingViewMore
                  title=""
                  bottomLines={false}
                  listing={getLabelByKey('moreProperties')}
                  viewMoreTask={() => navigate("/find-home")}
                />
              </div>
            </>
          )}
        </Container>
        <FindProperties />
        <Container>
          <ExploreProperty />
        </Container>

        <WhyChooseUs />
        {/* <Container>
          <div className="load-more d-flex justify-content-start mt-3">
            <HeadingViewMore
              title=""
              bottomLines={false}
              listing="View More Cities"
              viewMoreTask={() => navigate("/find-home")}
            />
          </div>
        </Container> */}
        {/* <Professionals professionals={homeData.professionalList} /> */}
        <WhoWeAre />
        {/* <Testomonials /> */}
        {/* <MembershipPlans /> */}
        <FooterHome />
      </HomeStyle>
    </>
  );
};

export default Home;
