import { configureStore } from "@reduxjs/toolkit";
import appDataSlice from "./features/user/appDataSlice";
import getAllUsersSlice from "./features/admin/allUsersSlice";
import getLoginDataSlice from "./features/user/getLoginDataSlice";
import professionalDropdownSlice from "./features/professional/dropdownSlice";
import userLocationSlice from "./features/location/userLocationSlice";
import userSlice from "./features/user/users";
import userDetailsSlice from "./features/user/userDetailsSlice";
import searchUserByAdminSlice from "./features/user/searchUserByAdminSlice";
import updateUserStatusSlice from "./features/user/updateStatusSlice";
import updateUserSlice from "./features/user/updateUserSlice";
import newsfeedStoriesSlice from "./features/newsfeed/storiesSlice";
import professionalsDropdownSlice from "./features/professional/professionalDropdownSlice";
import professionalProfileDetailsSlice from "./features/professional/professionalDetailSlice";
import screenTranslationSlice from "./features/user/screenTranslationSlice";
import selectedLanguageSlice from "./features/user/selectedLanguageSlice";

const store = configureStore({
  reducer: {
    appData: appDataSlice,
    userLocation: userLocationSlice,
    loginData: getLoginDataSlice,
    getAllUser: getAllUsersSlice,
    professionalDropdown: professionalDropdownSlice,
    userDetails: userDetailsSlice,
    searchUserByAdmin: searchUserByAdminSlice,
    updateUserStatus: updateUserStatusSlice,
    updateUser: updateUserSlice,
    translations: screenTranslationSlice,
    selectedLanguage: selectedLanguageSlice,
    professionalDetails: professionalProfileDetailsSlice,
    professionalsDropdown: professionalsDropdownSlice,
    user: userSlice,
    newsfeedStories: newsfeedStoriesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


