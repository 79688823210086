import styled from "styled-components";
import {
  fontFamilyMedium,
  lightDark,
  lightGrey2,
  mediaDeviceMax,
  primaryColor2,
  secondaryDark4,
  tertiaryGrewish,
  tertiaryGrey14,
  tertiaryGrey7,
  whiteColor,
} from "../../components/GlobalStyle";

const ExploreNearbyStyle = styled.div`
  .stories-list {
    overflow-x: auto;
    .story-img {
      border-radius: 4px;
      margin-left: 10px;
    }
  }
  .story-card {
    border: 0.5px solid ${lightGrey2};
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 16px;
    border: 0.5px solid #efeff4;

    img {
      height: 166px;
      object-fit: cover;
    }
    .card-body {
      padding-bottom: 4px;
      width: 100%;

      border-radius: 4px;
      .card-title {
        font-size: 14px;
        color: ${whiteColor};
        margin-bottom: 6px;
        font-family: ${fontFamilyMedium};
      }
    }
  }
`;

export default ExploreNearbyStyle;

export const SuggestionStyle = styled.div`
  position: sticky;
  top: 11%;
  /* height: 500px;
  overflow-y: auto; */

  .main-profile-icon {
    width: 58px;
    height: 58px;
    border-radius: 50%;
  }
  .profile-details {
    margin-left: 14px;
    &-title {
      font-family: ${fontFamilyMedium};
      font-size: 14px;
    }
    &-sub-title {
      color: ${tertiaryGrey14};
      font-size: 14px;
    }
  }

  .title {
    font-size: 15px;
    color: ${tertiaryGrey14};
  }
  .profile-icon {
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }
  .suggestion-profile-details {
    margin-left: 10px;
    &-title {
      font-family: ${fontFamilyMedium};
      font-size: 14px;
    }
    &-sub-title {
      color: ${tertiaryGrey14};
      font-size: 12px;
    }
  }
`;

export const PostStyle = styled.div`
  padding-bottom: 10px;
  margin-top: 10px;
  .alice-carousel__dots-item {
    width: 6px;
    height: 6px;
    &:not(.__custom):not(:last-child) {
      margin-right: 8px;
    }
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: 3%;
    width: 100%;
    z-index: 10;
  }
  .visited-text {
    font-size: 16px;
    color: ${whiteColor};
    position: absolute;
    z-index: 2;
    background-color: ${primaryColor2};
    padding: 0 10px;
    bottom: 0;
    font-family: ${fontFamilyMedium};
    cursor: pointer;
    pointer-events: auto; /* Allow clicking on this element */
  }
  .post-owner-section {
    padding: 10px;
    border: 0.5px solid #efeff4;
    padding-bottom: 6px;
    .name {
      color: ${tertiaryGrey7};
      font-family: ${fontFamilyMedium};
    }
    .time {
      font-size: 14px;
      color: ${secondaryDark4};
    }
    @media ${mediaDeviceMax.mobileXL} {
      .name,
      .ant-btn > span {
        font-size: 14px;
      }
    }
  }
  .card {
    box-shadow: 0px 0px 20px #00000012;
    border-radius: 3px;
    border: none;
    .sub-title {
      color: ${lightDark};
      font-size: 14px;
    }
    .property-description {
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 5px;
      .btn-primary {
        color: ${primaryColor2};
        background-color: rgb(108 177 193 / 20%) !important;
        font-size: 14px;
        border-radius: 3px;
        border: none;
        font-family: ${fontFamilyMedium};
        text-transform: uppercase;
        @media screen and (max-width: 480px) {
          font-family: ${fontFamilyMedium};
        }
        /* &:hover {
            color: ${whiteColor};
            background-color: ${primaryColor2};
          } */
      }
      .price {
        font-size: 18px;
        font-family: ${fontFamilyMedium};
        color: ${primaryColor2};
      }
    }
    .property-details {
      margin-top: 10px;
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 10px;

      .img {
        vertical-align: super;
      }
      .text {
        margin-top: 3px;
        margin-left: 4px;
        margin-bottom: 0;
      }
    }
    .save-icon {
      @media ${mediaDeviceMax.mobileXL} {
        height: 22px;
      }
    }

    .react {
      button {
        background-color: transparent;
      }
      .react {
        &-icons {
          .like-icon {
            height: 34.5px;
          }
          img {
            border-radius: 3px;
            padding: 6px;
            
            @media ${mediaDeviceMax.mobileXL} {
              height: 32px;
            }
          }
          @media ${mediaDeviceMax.mobileXL} {
            .like-icon {
              height: 31px;
            }
          }
        }
      }
    }
    .caption-section {
      @media ${mediaDeviceMax.mobileXL} {
        .liked-by {
          font-size: 14px;
        }
      }
      .post-caption {
        font-size: 14px;
        line-height: 18px;
        color: ${tertiaryGrey7};
      }
    }
  }
`;

export const PropertyPostStyle = styled.div`
  padding-bottom: 10px;
  margin-top: 10px;

  .alice-carousel__dots-item {
    width: 6px;
    height: 6px;
    &:not(.__custom):not(:last-child) {
      margin-right: 8px;
    }
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: 10%;
    width: 100%;
    z-index: 10;
  }
  .visited-text {
    font-size: 16px;
    color: ${whiteColor};
    position: absolute;
    z-index: 2;
    background-color: ${primaryColor2};
    padding: 0 10px;
    bottom: 0;
    font-family: ${fontFamilyMedium};
    cursor: pointer;
    pointer-events: auto; /* Allow clicking on this element */
  }
  .post-owner-section {
    padding: 10px;
    border: 0.5px solid #efeff4;
    padding-bottom: 6px;
    .name {
      color: ${tertiaryGrey7};
      font-family: ${fontFamilyMedium};
    }
    .time {
      font-size: 14px;
      color: ${secondaryDark4};
    }
    @media ${mediaDeviceMax.mobileXL} {
      .name,
      .ant-btn > span {
        font-size: 14px;
      }
    }
  }
  .save-icon {
    @media ${mediaDeviceMax.mobileXL} {
      height: 22px;
    }
  }
  .card {
    box-shadow: 0px 0px 20px #00000012;
    border-radius: 3px;
    border: none;
    .sub-title {
      color: ${lightDark};
      font-size: 14px;
    }
    .property-description {
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 5px;
      .btn-primary {
        color: ${primaryColor2};
        background-color: rgb(108 177 193 / 20%) !important;
        font-size: 14px;
        border-radius: 3px;
        border: none;
        font-family: ${fontFamilyMedium};
        text-transform: uppercase;
        @media screen and (max-width: 480px) {
          font-family: ${fontFamilyMedium};
        }
        /* &:hover {
            color: ${whiteColor};
            background-color: ${primaryColor2};
          } */
      }
      .price {
        font-size: 18px;
        font-family: ${fontFamilyMedium};
        color: ${primaryColor2};
        .discount {
          text-decoration: line-through;
          font-size: 16px;
        }
      }
    }
    .property-details {
      margin-top: 10px;
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 10px;

      .bed,
      .bathroom,
      .size {
        img {
          height: 18px;
        }
      }
      .text {
        margin-top: 3px;
        margin-left: 4px;
        margin-bottom: 0;
      }
    }

    .react {
      &-icons {
        .like-icon {
          height: 34.5px;
        }
        img {
          border-radius: 3px;
          padding: 6px;
          @media ${mediaDeviceMax.mobileXL} {
            height: 32px;
          }
        }
        @media ${mediaDeviceMax.mobileXL} {
          .like-icon {
            height: 31px;
          }
        }
      }
    }
    .caption-section {
      @media ${mediaDeviceMax.mobileXL} {
        .liked-by {
          font-size: 14px;
        }
      }
      .post-caption {
        font-size: 14px;
        color: ${tertiaryGrey7};
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const SuggestedStoriesStyle = styled.div`
  padding-bottom: 14px;
  margin-top: 10px;
  .card {
    border: 0.5px solid ${lightGrey2};
    border-radius: 4px;
    margin-top: 16px;
    margin-left: 16px;
    .mini-profile-img {
      position: absolute;
      z-index: 10;
      left: 10px;
      top: 10px;
    }

    &-img-top {
      height: 260px;
      object-fit: cover;
    }
    &-body {
      padding-bottom: 8px;
      .card-title {
        font-size: 16px;
        color: ${whiteColor};
        margin-bottom: 2px;
        font-family: ${fontFamilyMedium};
      }
      .card-subtitle {
        font-size: 12px;
        margin-top: 4px;
        color: white;
      }
    }
  }
`;

export const StorySliderStyle = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;

  .section-center {
    margin: 0 auto;
    width: 100%;
    /* have to have a height */
    height: 100vh;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  .story-img {
    margin-bottom: 1rem;
    width: 150px;
    height: 100vh;
    object-fit: cover;
    border: 4px solid hsl(210, 31%, 80%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: hsl(21, 62%, 45%);
  }

  .icons {
    img {
      border-radius: 3px;
      padding: 6px;
      height: 34px;
      width: 34px;
      cursor: pointer;
      &:not(last-child) {
        margin-right: 10px;
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    color: black;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .prev:hover,
  .next:hover {
    background: hsl(21, 62%, 45%);
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
  }
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
`;

export const BgImage = styled.div`
  .post {
    margin: 0 auto;
    background-color: ${whiteColor};
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 400px;
  }
  .visited-text {
    font-size: 16px;
    color: ${whiteColor};
    background-color: ${primaryColor2};
    padding: 0 10px;
    position: absolute;
    z-index: 100;
    font-family: ${fontFamilyMedium};
  }
  .post-image {
    /* position: relative;
    overflow: hidden; */
    height: 100%;
  }

  .post-image img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
