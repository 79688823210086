import React, { useRef, useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import map_pin from "../../assets/icons/map_icon.svg";

export type markerDataTypes = {
  latitude: number;
  longitude: number;
  id: number;
  userId: number;
  // newsFeedId: number;
};

type Props = {
  markers: markerDataTypes[];
  selectedLocationHandler: (id: number) => void;
};

const MultipleMarkerMap: React.FC<Props> = ({
  markers,
  selectedLocationHandler,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [defaultCenter, setDefaultCenter] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 40.416775, lng: -3.70379 });
  useEffect(() => {
    if (mapRef.current && markers) {
      // Calculate average latitude and longitude of markers
      const sumLat = markers.reduce((acc, marker) => acc + marker.latitude, 0);
      const sumLng = markers.reduce((acc, marker) => acc + marker.longitude, 0);
      const avgLat = sumLat / markers.length;
      const avgLng = sumLng / markers.length;

      // Set map center to average latitude and longitude
      // mapRef.current?.setCenter({ lat: avgLat, lng: avgLng });

      // Add click event listener to show info window when marker is clicked
      markers.forEach((marker) => {
        const latLng = new window.google.maps.LatLng(
          marker.latitude,
          marker.longitude
        );
        const mapMarker = new window.google.maps.Marker({
          position: latLng,
          map: mapRef.current,
          icon: {
            url: map_pin, // Set the online image link for the marker icon
          },
        });

        mapMarker.addListener("click", () => {
          mapRef.current?.setCenter({
            lat: marker.latitude,
            lng: marker.longitude,
          });
          setDefaultCenter({ lat: marker.latitude, lng: marker.longitude });
          mapRef.current?.setZoom(7);
          selectedLocationHandler(marker?.userId);
        });
      });
    }
  }, [markers]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(7);
    }
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%" }}
      center={defaultCenter} // Set the center to Ontario's latitude and longitude
      zoom={4}
      options={{
        mapTypeId: "roadmap", // Set map type to "roadmap"
        mapTypeControl: false, // Disable map type control
      }}
      onLoad={(map) => {
        mapRef.current = map;
      }}
    />
  );
};

export default MultipleMarkerMap;
