import { Col, Row } from "react-bootstrap";
import { PropertiesStyle } from "./style";
import { getAllProperties } from "../../utils/types";
import Property from "../../components/Property/Property";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor2, whiteColor } from "../../components/GlobalStyle";
import { useSearchContext } from "../../context/SearchContext";
import PropertySkeleton from "../../components/Skeletons/Properties";

type propertiesProps = {
  loadMore?: boolean;
  propertiesLoading?: boolean;
} & getAllProperties;
const Properties: React.FC<propertiesProps> = ({
  properties,
  totalPages,
  loadMore,
  propertiesLoading = false,
}) => {
  const { propertiesPage, setPropertiesPage, loading } = useSearchContext();
  return (
    <PropertiesStyle>
      { propertiesLoading ? (
        <PropertySkeleton />
      ) : (
        <Row>
          {properties?.map((propertyData) => (
            <Col key={propertyData.propertyId} sm={6} md={6} lg={4} xl={3}>
              <Property {...propertyData} />
            </Col>
          ))}
        </Row>
      )}
      {loadMore && (
        <div className="d-flex justify-content-center my-3">
          <CustomButton
            bgcolor={primaryColor2}
            color={whiteColor}
            padding="6px"
            width="150px"
            type="button"
            loading={loading}
            title="Load More"
            margin="auto"
            clicked={() => setPropertiesPage!(propertiesPage + 1)}
          />
        </div>
      )}
    </PropertiesStyle>
  );
};

export default Properties;
