import PuffLoader from "react-spinners/PuffLoader";
import LoaderStyle from "./style";
import { primaryColor2 } from "../GlobalStyle";

const Loader = () => {
  return (
    <LoaderStyle className="mt-3">
      <PuffLoader color={primaryColor2} loading={true} />
    </LoaderStyle>
  );
};

export default Loader;
