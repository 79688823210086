import { ProfessionalStyled } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { findProfessionalTypes } from "../../utils/types";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor2, whiteColor } from "../../components/GlobalStyle";
import { Dispatch, SetStateAction } from "react";
import Professional from "./Professional";

type ProfessionalsProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  loading: boolean;
  totalPages: number;
} & findProfessionalTypes;

const Professionals: React.FC<ProfessionalsProps> = ({
  professionalRecords,
  page,
  setPage,
  loading,
  totalPages,
}) => {
  return (
    <ProfessionalStyled>
      <Container>
        <Row className="professionals">
          {professionalRecords.map((professionalData, index: number) => (
            <Col sm={6} md={4} lg={3} key={index}>
              <Professional key={index} {...professionalData} />
            </Col>
          ))}
        </Row>
        {page + 1 !== totalPages && (
          <div className="d-flex justify-content-center my-3">
            <CustomButton
              bgcolor={primaryColor2}
              color={whiteColor}
              padding="6px"
              width="150px"
              type="button"
              loading={loading}
              title="Load More"
              margin="auto"
              clicked={() => setPage(page + 1)}
            />
          </div>
        )}
      </Container>
    </ProfessionalStyled>
  );
};

export default Professionals;
