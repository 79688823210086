import {
  lightGrey2,
  primaryColor2,
  primaryRed,
  TertiraryHeading,
} from "../../components/GlobalStyle";
import { Col, Row } from "react-bootstrap";
import FormControl from "../../components/FormControl";
import { useFormikContext } from "formik";
import TextField from "../../components/InputField/TextField";

// border toggler
export const borderErrorToggler = (error: any) => {
  return error ? `1px solid ${primaryRed}` : `1px solid ${lightGrey2}`;
};

const MpacAdditionalDetails = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();

  // mpac details object

  return (
    <>
      <TertiraryHeading>Basic Residential (Optional)</TertiraryHeading>
      <Row className="propery-results align-items-center">
        {values.basicResidentialOptions.assessmentValue && (
          <Col sm={6} md={4} className="mt-2">
            <TextField
              name="basicResidentialOptions.assessmentValue"
              label="Assessment Value"
              placeholder="Assessment Value"
              padding="8px"
              labelMarginBottom="14px"
              value={values.basicResidentialOptions.assessmentValue}
              onChange={(event) =>
                setFieldValue(
                  "basicResidentialOptions.assessmentValue",
                  event.target.value
                )
              }
            />
          </Col>
        )}

        {/* {
        values.basicResidentialOptions.salesDate && (

        )
       } */}
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="dates"
            type="text"
            padding="8px"
            name="saleDate"
            label="Sale Date"
            marginBottom="-5px"
            placeholder="Sale Date"
            value={values.basicResidentialOptions.saleDate}
            labelFamily="EnnVisionsMedium"
            onChange={(value: string) =>
              setFieldValue("basicResidentialOptions.saleDate", value)
            }
            className={
              errors.basicResidentialOptions ? "is-invalid" : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.saleAmount"
            label="Sale Amount"
            placeholder="Sale Amount"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.saleAmount}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.saleAmount",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.hydroServiceDescription"
            label="Hydro Service Description"
            placeholder="Hydro Service Description"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.hydroServiceDescription}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.hydroServiceDescription",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.waterServiceDescription"
            label="Water Service Description"
            placeholder="Water Service Description"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.waterServiceDescription}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.waterServiceDescription",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.sanitaryServiceDescription"
            label="sanitaryServiceDescription"
            placeholder="sanitaryServiceDescription"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.sanitaryServiceDescription}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.sanitaryServiceDescription",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.heatingSystemTypeCode"
            label="Heating System TypeCode"
            placeholder="Heating System TypeCode"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.heatingSystemTypeCode}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.heatingSystemTypeCode",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.airConditioningFlag"
            label="Air Conditioning Flag"
            placeholder="Air Conditioning Flag"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.airConditioningFlag}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.airConditioningFlag",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.actualFrontage"
            label="Actual Frontage"
            placeholder="Actual Frontage"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.actualFrontage}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.actualFrontage",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.actualDepth"
            label="Actual Depth"
            placeholder="Actual Depth"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.actualDepth}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.actualDepth",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basicResidentialOptions.actualDepth"
            label="Site Areah"
            placeholder="Site Areah"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.siteArea}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.siteArea",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="basementArea"
            label="Basement Area"
            placeholder="Basement Area"
            padding="8px"
            labelMarginBottom="14px"
            value={values.basicResidentialOptions.basementArea}
            onChange={(event) =>
              setFieldValue(
                "basicResidentialOptions.basementArea",
                event.target.value
              )
            }
          />
        </Col>
      </Row>
      <TertiraryHeading className="mt-3">
        Primary Residential (Optional)
      </TertiraryHeading>
      <Row className="propery-results align-items-center">
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="primaryResidence.actualYear"
            border={`1px solid ${lightGrey2}`}
            value={values.primaryResidence.actualYear}
            label="Actual Year"
            placeholder="Actual Year"
            labelFamily="EnnVisionsMedium"
            onChange={(value: string) =>
              setFieldValue("primaryResidence.actualYear", value)
            }
            className={
              errors.actualYear && touched.actualYear
                ? "is-invalid"
                : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="primaryResidence.effectiveYear"
            border={`1px solid ${lightGrey2}`}
            value={values.primaryResidence.effectiveYear}
            label="Effective Year"
            placeholder="Effective Year"
            labelFamily="EnnVisionsMedium"
            onChange={(value: string) =>
              setFieldValue("primaryResidence.effectiveYear", value)
            }
            className={
              errors.saleDate && touched.saleDate ? "is-invalid" : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="primaryResidence.additionYear"
            border={`1px solid ${lightGrey2}`}
            label="Addition Year"
            value={values.primaryResidence.additionYear}
            placeholder="Addition Year"
            labelFamily="EnnVisionsMedium"
            onChange={(value: string) =>
              setFieldValue("primaryResidence.additionYear", value)
            }
            className={
              errors.additionYear && touched.additionYear
                ? "is-invalid"
                : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="primaryResidence.firstFloorArea"
            label="First Floor Area"
            placeholder="First Floor Area"
            padding="8px"
            labelMarginBottom="14px"
            value={values.primaryResidence.firstFloorArea}
            onChange={(event) =>
              setFieldValue(
                "primaryResidence.firstFloorArea",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="primaryResidence.secondFloorArea"
            label="Second Floor Area"
            placeholder="Second Floor Area"
            padding="8px"
            labelMarginBottom="14px"
            value={values.primaryResidence.secondFloorArea}
            onChange={(event) =>
              setFieldValue(
                "primaryResidence.secondFloorArea",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="primaryResidence.thirdFloorArea"
            label="Third Floor Area"
            placeholder="Third Floor Area"
            padding="8px"
            labelMarginBottom="14px"
            value={values.primaryResidence.thirdFloorArea}
            onChange={(event) =>
              setFieldValue(
                "primaryResidence.thirdFloorArea",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="primaryResidence.basementArea"
            label="Basement Total"
            placeholder="Basement Total"
            padding="8px"
            labelMarginBottom="14px"
            value={values.primaryResidence.basementArea}
            onChange={(event) =>
              setFieldValue("primaryResidence.basementArea", event.target.value)
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="primaryResidence.basementFinished"
            label="Basement Finished"
            placeholder="Basement Finished"
            padding="8px"
            labelMarginBottom="14px"
            value={values.primaryResidence.basementFinished}
            onChange={(event) =>
              setFieldValue(
                "primaryResidence.basementFinished",
                event.target.value
              )
            }
          />
        </Col>
      </Row>
      <TertiraryHeading className="mt-3">
        Secondary Residential (Optional)
      </TertiraryHeading>
      <Row className="propery-results align-items-center">
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="actualYearBuilt"
            label="Year Built"
            placeholder="Year Built"
            padding="8px"
            labelMarginBottom="14px"
            value={values.actualYearBuilt}
            onChange={(event) =>
              setFieldValue("actualYearBuilt", event.target.value)
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="floorArea"
            label="Total Floor Area"
            placeholder="Total Floor Area"
            padding="8px"
            labelMarginBottom="14px"
            value={values.floorArea}
            onChange={(event) => setFieldValue("floorArea", event.target.value)}
          />
        </Col>
      </Row>
      {/* garage structures */}
      <TertiraryHeading className="mt-3">
        Garage Structures (Optional)
      </TertiraryHeading>
      <Row className="propery-results align-items-center">
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="garageStructure.garageName"
            label="Garage Name"
            placeholder="Garage Name"
            padding="8px"
            labelMarginBottom="14px"
            value={values.garageStructure.garageName}
            onChange={(event) =>
              setFieldValue("garageStructure.garageName", event.target.value)
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="garageStructure.garageType"
            label="Garage Type"
            placeholder="Garage Type"
            padding="8px"
            labelMarginBottom="14px"
            value={values.garageStructure.garageType}
            onChange={(event) =>
              setFieldValue("garageStructure.garageType", event.target.value)
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <TextField
            name="garageStructure.garageDescription"
            label="Garage Description"
            placeholder="Garage Description"
            padding="8px"
            labelMarginBottom="14px"
            value={values.garageStructure.garageDescription}
            onChange={(event) =>
              setFieldValue(
                "garageStructure.garageDescription",
                event.target.value
              )
            }
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="garageStructure.actualYear"
            border={`1px solid ${lightGrey2}`}
            label="Actual Year"
            placeholder="Actual Year"
            labelFamily="EnnVisionsMedium"
            value={values.garageStructure.actualYear}
            onChange={(value: string) =>
              setFieldValue("garageStructure.actualYear", value)
            }
            className={
              errors.actualYear && touched.actualYear
                ? "is-invalid"
                : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="garageStructure.effectiveYear"
            border={`1px solid ${lightGrey2}`}
            label="Effective Year"
            placeholder="Effective Year"
            value={values.garageStructure.effectiveYear}
            onChange={(value: string) =>
              setFieldValue("garageStructure.effectiveYear", value)
            }
            labelFamily="EnnVisionsMedium"
            className={
              errors.effectiveYear && touched.effectiveYear
                ? "is-invalid"
                : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
        <Col sm={6} md={4} className="mt-2">
          <FormControl
            control="year"
            type="text"
            padding="6.5px"
            name="garageStructure.renovationYear"
            border={`1px solid ${lightGrey2}`}
            label="Renovation Year"
            value={values.garageStructure.renovationYear}
            onChange={(value: string) =>
              setFieldValue("garageStructure.renovationYear", value)
            }
            placeholder="Renovation Year"
            labelFamily="EnnVisionsMedium"
            className={
              errors.renovationYear && touched.renovationYear
                ? "is-invalid"
                : "customInput"
            }
            showErrorMessage={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default MpacAdditionalDetails;
