import styled from "styled-components";
import {
  fontFamilyMedium,
  primaryColor2,
  pureDark,
  secondaryDark4,
  whiteColor,
} from "../../components/GlobalStyle";

const FindProfessionalStyle = styled.div`
  .ant-select-selector {
    height: 36px !important;
    width: 96% !important;
    font-family: ${fontFamilyMedium};
  }
  .multiple-marker-parent {
    height: 440px;
    z-index:1;
    .property-drawer {
      right: 0;
      top: 0;
      height: 400px;
      > div{
        // z-index: 100;
      }
    }
  }
`;

export default FindProfessionalStyle;

export const ProfesionalDrawerStyle = styled.div`
  .ant-drawer-body {
    padding: 0px !important;
    border-radius: 7px;
  }
  .ant-drawer-mask {
    background: transparent;
  }
  .ant-drawer-title {
    font-family: ${fontFamilyMedium};
  }
  .ant-drawer-content-wrapper {
    height: 400px;
    box-shadow: unset;
    margin-right: 20px;
  }

  .ant-drawer-content-wrapper {
    width: 280px !important;
  }
  .ant-drawer-content {
    background-color: transparent;
  }

  .ant-drawer-body {
    margin-top: 6px;
  }
`;

export const ProfessionalStyled = styled.div`
  .professionals {
    .professional-container {
      margin-top: 28px;
    }
    .detail-text {
      margin-top: -30px;
      background: ${whiteColor};
      box-shadow: 0px 0px 15px #0000001c;
      border-radius: 6px;
      width: 94%;
      text-align: center;
      padding: 9px 0;
      z-index: 10;
      .name {
        font-size: 16px;
        color: ${pureDark};
        font-family: "EnnVisionsMedium";
      }
      .profession {
        font-size: 14px;
        color: ${pureDark};
        margin: 0;
      }
    }
  }
`;

export const ProfessionalFiltersStyle = styled.div`
  width: 100%;

  .inner-container {
    .purchase-btns {
      max-width: 300px;
      margin-top: 45px;
      background-color: ${whiteColor};
      border-radius: 7px;
      height: 50px;
      padding: 3px;
      .active {
        background-color: ${primaryColor2};
        height: 100%;
        border-radius: 4px;
        color: ${whiteColor};
      }
      button {
        background: transparent;
        font-size: 18px;
      }
    }

    .location-select-section {
      max-width: 1000px;
      background-color: ${whiteColor};
      border-radius: 7px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      .title {
        color: ${pureDark};
        font-size: 16px;
        font-family: "EnnVisionsMedium";
      }
      .sub-title {
        color: ${secondaryDark4};
        font-size: 14px;
      }
      .property-type {
        .search-box {
          background-color: ${primaryColor2};
          border-radius: 4px;
          padding: 12px;
          height: 39px;
          margin-top: 28px;
          cursor: pointer;

          img {
            vertical-align: super;
          }
        }
        @media screen and (max-width: 575px) {
          border-left: none;
          .search-box {
            img {
              height: 20px;
            }
            height: 40px;
          }
        }
      }
    }
  }
`;

export const ProfessionalDrawerStyle = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px !important;
  .detail-text {
    background: ${whiteColor};
    width: 100%;
    text-align: center;
    padding: 9px 0;
    border-radius: 0px 5px 0px 5px;
    .name {
      font-size: 20px;
      color: ${pureDark};
      font-family: ${fontFamilyMedium};
    }
    .profession {
      font-size: 15px;
      color: ${pureDark};
      margin: 0;
    }
  }
`;
