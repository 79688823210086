import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey2,
  tertiaryDark4,
} from "../../components/GlobalStyle";

const SettingAndPrivacyStyle = styled.div`
  padding-top: 20px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
 

  .title {
    font-size: 24px;
  }
  .settings {
    &-option {
      border-bottom: 1px solid ${lightGrey2};
      padding-bottom: 8px;
      &-title {
        color: ${tertiaryDark4};
        font-size: 14px;
      }
    }
  }
  .terms,
  .privacy {
    font-family: ${fontFamilyMedium};
    font-size: 14px;
    margin-top: 19px;
  }
`;

export default SettingAndPrivacyStyle;
