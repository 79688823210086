import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthNavbarStyle } from "./style";
import logo from "../../assets/icons/ic_logo.svg";
import NavMenu from "./NavMenu";
import { useAppSelector } from "../../app/hooks";
import { useGlobalContext } from "../../context/context";
import Sidebar from "../Sidebars/Sidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import AuthSidebar from "../Sidebars/AuthSidebar";
import GuidlinesSidebar from "../Sidebars/GuildlineSidebar";
import useScreenTranslation from "../../hooks/useScreenTranslation";
function AuthNavbar() {
  const loginData = useAppSelector((state) => state.loginData.data);
  const {
    showSidebar,
    setShowSidebar,
    showAuthSidebar,
    setShowAuthSidebar,
    userTourGuideData,
    professionalTourGuideData,
  } = useGlobalContext();
  console.log({ loginData });
  const navigate = useNavigate();

  console.log({ userTourGuideData, professionalTourGuideData });
  const { getLabelByKey } = useScreenTranslation('navBar')

  return (
    <AuthNavbarStyle>
      <Navbar expand="lg">
        <Container className="position-relative">
          {/* {!loginData && <Navbar.Toggle aria-controls="navbarScroll" />} */}
          <div>
            <div className="d-flex align-items-center">
              <RxHamburgerMenu
                onClick={() =>
                  loginData
                    ? setShowAuthSidebar(!showAuthSidebar)
                    : setShowSidebar(!showSidebar)
                }
                className={`fs-1 mt-n1 text-dark me-4 cursor-pointer ${
                  loginData ? "d-block" : "d-lg-none"
                }`}
                style={{
                  ...(window.innerWidth <= 768 && { width: "25px" }),
                }}
              />

              <img
                className="company-logo"
                onClick={() => navigate(loginData ? "/find-home" : "/")}
                src={logo}
                alt="logo"
              />
            </div>
            {/* {loginData ? (
            ) : (
              <img className="company-logo" src={logo} alt="logo" />
            )} */}
          </div>
          <div className="d-lg-none">
            <NavMenu changeNavColor={true} />
          </div>
          {/* <div className="d-lg-none">
            <NavMenu changeNavColor={true} />
          </div> */}
          {!loginData && (
            <Navbar.Collapse id="navbarScroll">
              <Nav className="mx-auto my-2 my-lg-0" navbarScroll>
                {/* <NavLink className="nav-link" to="/">
                  Home
                </NavLink> */}
                <NavLink className="nav-link" to="/">
               { getLabelByKey('home')} Home
                </NavLink>
                <NavLink className="nav-link" to="/find-home">
                { getLabelByKey('search')}  Search Properties
                </NavLink>

                {/* <NavLink className="nav-link" to="/resources">
                  Resources
                </NavLink> */}
                <NavLink className="nav-link" to="/contact-us">
                { getLabelByKey('contact')} Contact
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          )}
          <div className="d-none d-lg-block pe-auto">
            <NavMenu changeNavColor={true} />
          </div>
        </Container>
      </Navbar>
      {/* <div className="d-none d-md-block">
        <GuidlinesSidebar />
      </div> */}
      {loginData ? <AuthSidebar /> : <Sidebar />}
    </AuthNavbarStyle>
  );
}

export default AuthNavbar;
