import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  mediaDeviceMax,
  primaryColor2,
  whiteColor,
} from "../GlobalStyle";

export const AuthSidebarStyle = styled.div`
  width: 320px;
  background-color: white;
  height: 100vh;
  position: fixed;
  padding: 0px 20px;
  overflow-y: auto;
  z-index: 20;
  .side-menu-icon {
    font-size: 34px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .expand-menu {
    transition: all 1s ease-out;
    transform-origin: top;
  }
  p {
    color: black;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    font-family: ${fontFamilyRegular};
    margin-top: 20px;
  }
  .inner-container {
    img {
      cursor: pointer;
    }
    .logout-btn {
      margin-bottom: 12px;
    }
  }
`;

export const SidebarStyle = styled.div`
  width: 320px;
  background-color: white;
  height: 100vh;
  position: fixed;
  padding: 0px 20px;
  overflow-y: auto;
  z-index: 20;
  .side-menu-icon {
    font-size: 34px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .expand-menu {
    transition: all 1s ease-out;
    transform-origin: top;
  }
  p {
    color: black;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    font-family: ${fontFamilyRegular};
    margin-top: 20px;
  }
  .inner-container {
    img {
      cursor: pointer;
    }
    .logout-btn {
      margin-bottom: 12px;
    }
    .login-btn {
      margin-bottom: 12px;
    }
  }
`;

type guidelineSideProps = {
  height: number;
};
export const GuidlinesSidebarStyle = styled.div<guidelineSideProps>`
  width: 280px;
  background-color: ${primaryColor2};
  padding: 0px 20px;
  height: ${(props) => `calc(${props.height}px - 160px)`};

  @media ${mediaDeviceMax.tabletB} {
    width: 240px;
  }
  .guide-title {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
    color: ${whiteColor};
  }
  .side-menu-icon {
    font-size: 34px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .expand-menu {
    transition: all 1s ease-out;
    transform-origin: top;
  }
  p {
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    font-family: ${fontFamilyRegular};
    margin-top: 20px;
    color: ${whiteColor};
  }
  .inner-container {
    img {
      cursor: pointer;
    }
    .guide-btns {
      margin-bottom: 12px;
    }
  }
`;

type guidelineProps = {
  height: number;
};
export const GuidelinesStyle = styled.div<guidelineProps>`
  background-color: ${whiteColor};
  border-radius: 10px;
  height: ${(props) => `calc(${props.height}px - 160px)`};
  width: 80%;
  video {
    object-fit: cover;
  }
  .video-container {
    height: ${(props) => `calc(${props.height}px - 160px)`};
  }
`;
