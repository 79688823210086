import { CreateStoryTextStyle } from "./style";
import Head from "../../components/Head/Head";
import { useNavigate } from "react-router-dom";
import { useStoryContext } from "../../context/StoryContext";
import { Input } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor2 } from "../../components/GlobalStyle";
import { useEffect, useRef, useState } from "react";

const { TextArea } = Input;

const CreateStoryText = () => {
  const { storyText, setStoryText } = useStoryContext();
  const navigate = useNavigate();

  return (
    <CreateStoryTextStyle>
      <Head title="create-story-text" />
      <div className="inner-section">
        <div className="caption-section">
          <TextArea
            rows={6}
            placeholder="Enter Caption"
            className="h-75"
            value={storyText}
            onChange={(e) => setStoryText(e.target.value)}
          />
          <div className="my-2 d-flex justify-content-center">
            <CustomButton
              bgcolor={primaryColor2}
              color="white"
              padding="8px"
              width="98%"
              type="button"
              title="Preview"
              margin="auto"
              disabled={!storyText}
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              clicked={() => {
                navigate("/create-story-text-preview");
              }}
            />
          </div>
        </div>
      </div>
    </CreateStoryTextStyle>
  );
};

export default CreateStoryText;
