import styled from "styled-components";
import {
  fontFamilyMedium,
  lightColor1,
  lightGrey2,
  mediaDeviceMax,
  primaryColor2,
  pureDark,
  secondaryDark4,
  tertiaryGrey16,
  whiteColor,
} from "../../../components/GlobalStyle";

interface createPropertyPlanProps {
  height: number;
}
const CreatePropertyPlansStyle = styled.div<createPropertyPlanProps>`
  background-color: white;
  height: calc(100vh - 86px);
  padding-top: 40px;

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  .title {
    color: ${pureDark};
  }

  .property-intro {
    background: ${tertiaryGrey16};
    border: 1px solid ${lightGrey2};
    border-radius: 4px 4px 0px 0px;
    font-family: ${fontFamilyMedium};
    padding: 12px;
    .heading {
      font-size: 14px;
      color: ${pureDark};
      margin-bottom: 0;
    }
    .amount-fee {
      font-size: 16px;
      color: ${primaryColor2};
      margin-bottom: 0;
    }

    .price {
      font-size: 16px;
      color: ${lightGrey2};
    }
  }
  .row {
    .property-card {
      &-inner-section {
        border: 1px solid ${lightColor1};
        padding: 0 12px;
        &-items {
          background: ${whiteColor};
          border-radius: 6px;
          height: ${(props) => `calc(${props.height}px - 300px)`};
          @media ${mediaDeviceMax.mobileBS} {
            height: 380px;
          }
          position: relative;
          overflow-y: auto;

          .feature {
            margin-top: 14px;

            &-text {
              color: ${secondaryDark4};
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

export default CreatePropertyPlansStyle;
