import { InputNumber } from "antd";
import { CustomPhoneInputStyle } from "./style";
import { BaseImgContainer, fontFamilyRegular } from "../GlobalStyle";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useState } from "react";
type CustomPhoneInputProps = {
  countryNumber: string;
  phoneValueHandler: (value: string | number) => void;
  placeholder: string;
  phoneLength: number;
  countryFlag: string;
  countryName: string;
  disabled?: boolean;
  name: string;
  value: string | number;
  label?: string;
  labelFamily?: string;
  labelFontSize?: string;
};

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  countryNumber,
  phoneValueHandler,
  countryFlag,
  phoneLength,
  placeholder,
  countryName,
  disabled = false,
  name,
  value,
  label,
  labelFamily = fontFamilyRegular,
  labelFontSize = "16px",
}) => {
  return (
    <>
      <CustomPhoneInputStyle
        labelFamily={labelFamily}
        labelFontSize={labelFontSize}
      >
        {label && <label htmlFor={name}>{label}</label>}
        <div className="mt-2">
          {/* <InputNumber
            style={{
              border: "none",
              height: "40px",
              marginBottom: "4px",
            }}
            className="w-100 border-0"
            controls={false}
            name={name}
            type="tel"
            maxLength={phoneLength}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(value) => phoneValueHandler(value || "")}
            addonBefore={
              <div style={{ width: "40px", height: "40px" }} className="">
                <BaseImgContainer
                  img_url={countryFlag}
                  alt={countryName}
                  className="me-2 flag"
                  width="26px"
                  height="26px"
                />
                <p className="m-0">{countryNumber}</p>
              </div>
            }
          /> */}
          <PhoneInput
            international
            name="phoneNumber"
            type="text"
            enableAreaCodes={true}
            defaultCountry="GB"
            // disabled={disabled}
            inputProps={{
              name: "phone",
              country: "es",
              required: true,
              autoFocus: true,
            }}
            value={value as any}
            onChange={(value) => phoneValueHandler(value || "")}
            style={{
              border: "1px solid #cdcdcf",
              borderRadius: "2px",
              padding: "10px",
              pointerEvents: disabled ? "none" : "auto",
              opacity: disabled ? 0.7 : 1,
              backgroundColor: disabled && "#f5f5f5",
              color: disabled && "#bfc3c8",
              fontWeight: disabled && "bold",
            }}
            inputStyle={{
              background: "lightblue",
            }}
            required
          />
        </div>
      </CustomPhoneInputStyle>
    </>
  );
};

export default CustomPhoneInput;
